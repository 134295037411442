import React, { useEffect } from 'react'
import { $ } from 'react-jquery-plugin'
import CreateRoom from './partials/CreateRoom'
import Header from './partials/Header'
import JoinRoom from './partials/JoinRoom'
import Profile from './partials/Profile'
import { useHome } from './useHome'
import AttendeeRow from './partials/AttendeeRow'
import { getUserIdFromToken } from '../../auth'
import { useSubscriptionClient } from '../common/hooks/useSubscriptionClient'
import { useProfile } from '../common/hooks/useProfile'
import { useTranslation } from 'react-i18next'
import { gaPageView, isActivitiesAfterUserConnection } from '../common/utilis'
import { AppLogger } from '../../AppLogger'
import moment from 'moment'
const logger = AppLogger.getInstance()
const Home: React.FC = () => {
    const { t } = useTranslation(['Home', 'Notification'])

    const userId = getUserIdFromToken()!
    const { clientConnectionError, isMounted } = useSubscriptionClient()
    useProfile(userId)
    const { data, loading, updateProfile, deleteRoom, leaveRoom, createRoom } =
        useHome(userId)

    const sortedAttendeeList =
        data && data.user && data.user.joinedRooms
            ? [...data.user.joinedRooms].sort((a, b) => {
                  return moment(b.lastSeenAt).diff(a.lastSeenAt)
              })
            : []

    logger.debug(`Home clientConnectionError is:${clientConnectionError} `)
    logger.debug(`Home isMounted is:${isMounted.current} `)

    useEffect(() => {
        $('body').addClass('flag-loading')
        if (!loading) {
            setTimeout(() => $('body').removeClass('flag-loading'), 0)
        }

        gaPageView('Lobby')
        return () => {
            $('body').removeClass('flag-loading')
        }
    }, [loading])

    /** 
    useEffect(() => {
        if (isMobile) {
            Store.addNotification({
                title: t('Notification:Title'),
                message: <NotificationMessage />,
                type: 'success',
                insert: 'top',
                width: isMobile ? window.innerWidth : window.innerWidth / 2,
                container: 'top-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 15000,
                    onScreen: true,
                },
            })
        }
    }, [])

    **/

    //if (loading || !data) return <div>{t('loading')} ...</div>
    if (loading || !data) return <div></div>
    return (
        <div className="container-main template-lobby">
            <Header />
            <Profile user={data.user} updateProfile={updateProfile} />
            <div className="container-content">
                <div className="entry-lobby">
                    <JoinRoom />
                    <CreateRoom createRoom={createRoom} />

                    <div className="container-rooms">
                        <div className="entry-rooms">
                            {data.user?.joinedRooms.length ? (
                                <h4>{t('Rooms')}</h4>
                            ) : (
                                ''
                            )}
                            {sortedAttendeeList.map((row) => (
                                <AttendeeRow
                                    key={`${data.user?.id}_${row.room.id}_${row.role}`}
                                    room={row}
                                    exportRoom={() => {}}
                                    deleteRoom={deleteRoom}
                                    leaveRoom={leaveRoom}
                                    showActivitiesNotification={isActivitiesAfterUserConnection(
                                        row
                                    )}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/*<pre>{JSON.stringify(data, null, 3)}</pre> */}
        </div>
    )
}

export default Home
