import { MutationResult } from '@apollo/client'
import React, { useRef, useState } from 'react'
import { $ } from 'react-jquery-plugin'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../../../../AppLogger'
import { CreateOneComment } from '../../../../../../generated/CreateOneComment'
import { AttendeeRole } from '../../../../../../generated/globalTypes'
import {
    EventAction,
    EventCategory,
    gaEvent,
} from '../../../../../common/utilis'

/**
 *
 */

interface Props {
    createComment: Function
    createCommentStatus: MutationResult<CreateOneComment>
    isCommentingAllowed: boolean
    isCommentsAllowed: boolean
    postId: string
    openCloseComments: Function
    isPublished: boolean
    role: AttendeeRole
}
const logger = AppLogger.getInstance()
const CommentInput: React.FC<Props> = ({
    createComment,
    createCommentStatus,
    openCloseComments,
    isCommentsAllowed,
    isCommentingAllowed,
    isPublished,
    role,
}) => {
    const { t } = useTranslation(['Room'])
    const [content, setContent] = useState<string>('')

    const refTextarea = useRef<HTMLTextAreaElement>(null)

    const handleCreateComment = async () => {
        if (createCommentStatus.error) {
            //TODO handle error
            logger.error(createCommentStatus.error)
        }
        if (!content || content.trim().length === 0) {
            if (refTextarea.current) {
                refTextarea.current.focus()
            }
            return
        }
        const isPublishedFlag = role === AttendeeRole.Admin ? true : isPublished
        const comment = await createComment(content, isPublishedFlag)

        // when isPublishedFlag is false, inform use that message will be moderated
        if (!isPublishedFlag) {
            $('body').addClass('flag-notifcation')
            // await animateCSS('.flash-notification', 'fadeInUp', 0.5)
            setTimeout(() => $('body').removeClass('flag-notifcation'), 5000)
        }
        gaEvent(EventCategory.Comment, EventAction.Create, content)
        logger.debug(`handleCreateComment:`, comment)
        setContent('')
    }
    const getPlaceholderText = (role: AttendeeRole) => {
        switch (role) {
            case AttendeeRole.Admin:
                return isCommentingAllowed
                    ? t('MessagePlaceholder')
                    : isCommentsAllowed
                    ? t('PostingTemporaryClosedPollAdmin')
                    : t('PostingTemporaryClosedAdmin')
            default:
                return t('MessagePlaceholder')
        }
    }

    return (
        <div className="comment-input">
            <div className="close-comment" onClick={() => openCloseComments()}>
                <div className="button-close-comment comments-link">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                    >
                        <line
                            x1="0"
                            y1="0"
                            x2="100"
                            y2="100"
                            vectorEffect="non-scaling-stroke"
                        />
                        <line
                            x1="0"
                            y1="100"
                            x2="100"
                            y2="0"
                            vectorEffect="non-scaling-stroke"
                        />
                    </svg>
                </div>
            </div>
            <div
                className={`form new-comment ${
                    !isCommentingAllowed &&
                    role === AttendeeRole.Guest &&
                    'notice-temporary-close'
                }`}
            >
                <textarea
                    placeholder={getPlaceholderText(role)}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    ref={refTextarea}
                ></textarea>

                <div
                    className="input-button submit"
                    onClick={handleCreateComment}
                >
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                    >
                        <polyline
                            points="100 50 50 0 0 50"
                            vectorEffect="non-scaling-stroke"
                        />
                        <path
                            d="M0,100c37.60125,0,50-12.64574,50-49.98789L50,0"
                            vectorEffect="non-scaling-stroke"
                        />
                    </svg>
                </div>
            </div>

            <div
                className="notice-temporary-close"
                style={{
                    display:
                        isCommentingAllowed || role === AttendeeRole.Admin
                            ? 'none'
                            : 'block',
                }}
            >
                <p>{t('PostingTemporaryClosed')}</p>
            </div>
        </div>
    )
}

export default CommentInput
