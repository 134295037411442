import ExcelExport from 'export-xlsx'
import moment from 'moment'
import { exportRoom } from '../../export'
import { AttendeeRole, ReactionType } from '../../generated/globalTypes'
import {
    JoinRoom_roomAttendee_room_polls_options_results,
    JoinRoom_roomAttendee_room_posts,
    JoinRoom_roomAttendee_room_posts_comments,
} from '../../generated/JoinRoom'
import {
    getOptionProgress,
    getParticipants,
} from '../Room/partials/content/poll/helper'
import { getSettingsForExport } from './excel-settings'

const getOptionResults = (pollOptions: any) => {
    let ret: Record<string, string> = {}

    pollOptions.forEach((op: any) => {
        ret[`option${op.label.toUpperCase()}`] = op.results
            .map((r: any) => r.userId)
            .join('\r\n')
    })
    return ret
}

const getUserActionData = (roomData: any) => {
    return roomData.users.map((u: any) => {
        const userPolls = roomData.polls.filter((poll: any) => {
            if (u.role === AttendeeRole.Admin) {
                return poll.userId === u.userId
            } else {
                const results: JoinRoom_roomAttendee_room_polls_options_results[] =
                    []
                poll.options.forEach((option: any) => {
                    results.push(...option.results)
                })
                const re = results.find((r: any) => r.userId === u.userId)
                if (re) {
                    return true
                }
                return false
            }
        })
        const userPostsData = roomData.posts.filter((p: any) => {
            return p.userId === u.userId
        }) as JoinRoom_roomAttendee_room_posts[]

        const likedPostsData = roomData.posts.filter((p: any) => {
            const re = p.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Like &&
                    r.userId === u.userId
            )
            if (re) {
                return true
            }
            return false
        }) as JoinRoom_roomAttendee_room_posts[]

        const likedPostsDataTimeStamps = likedPostsData.map((p) => {
            const re = p.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Like &&
                    r.userId === u.userId
            )
            if (re) {
                return {
                    postId: p.id,
                    likeTimeStamp: moment(re.createdAt).format('llll'),
                }
            }
            return {
                postId: p.id,
                likeTimeStamp: '',
            }
        })

        const dislikedPostsData = roomData.posts.filter((p: any) => {
            const re = p.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Dislike &&
                    r.userId === u.userId
            )
            if (re) {
                return true
            }
            return false
        }) as JoinRoom_roomAttendee_room_posts[]

        const dislikedPostsDataTimeStamps = dislikedPostsData.map((p) => {
            const re = p.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Dislike &&
                    r.userId === u.userId
            )
            if (re) {
                return {
                    postId: p.id,
                    dislikeTimeStamp: moment(re.createdAt).format('llll'),
                }
            }
            return {
                postId: p.id,
                dislikeTimeStamp: '',
            }
        })

        const userCommentsData: JoinRoom_roomAttendee_room_posts_comments[] = []
        const likedCommentsData: JoinRoom_roomAttendee_room_posts_comments[] =
            []

        const dislikedCommentsData: JoinRoom_roomAttendee_room_posts_comments[] =
            []

        roomData.posts.forEach((p: any) => {
            const comments = p.comments.filter((p: any) => {
                return p.userId === u.userId
            })
            userCommentsData.push(...comments)
        })

        userCommentsData.filter((c: any) => {
            const re = c.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Like &&
                    r.userId === u.userId
            )
            if (re) {
                return true
            }
            return false
        })

        likedCommentsData.filter((c: any) => {
            const re = c.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Like &&
                    r.userId === u.userId
            )
            if (re) {
                return true
            }
            return false
        })

        const likedCommentsDataTimeStamps = likedCommentsData.map((p) => {
            const re = p.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Like &&
                    r.userId === u.userId
            )
            if (re) {
                return {
                    commentId: p.id,
                    likeTimeStamp: moment(re.createdAt).format('llll'),
                }
            }
            return {
                commentId: p.id,
                likeTimeStamp: '',
            }
        })

        dislikedCommentsData.filter((c: any) => {
            const re = c.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Dislike &&
                    r.userId === u.userId
            )
            if (re) {
                return true
            }
            return false
        })

        const dislikedCommentsDataTimeStamps = dislikedCommentsData.map((p) => {
            const re = p.reactions.find(
                (r: any) =>
                    r.reactionType === ReactionType.Dislike &&
                    r.userId === u.userId
            )
            if (re) {
                return {
                    commentId: p.id,
                    dislikeTimeStamp: moment(re.createdAt).format('llll'),
                }
            }
            return {
                commentId: p.id,
                dislikeTimeStamp: '',
            }
        })

        return {
            ...u,
            createdAnsweredPolls: userPolls.length,
            nbCreatedPosts: userPostsData.length,
            createdPosts: userPostsData.map((p) => p.id).join('\r\n'),
            nbLikedPosts: likedPostsData.length,
            likedPosts: likedPostsData.map((p) => p.id).join('\r\n'),
            likedPostsTimestamp: likedPostsDataTimeStamps
                .map((p) => p.likeTimeStamp)
                .join('\r\n'),

            nbDislikedPosts: dislikedPostsData.length,
            dislikedPosts: dislikedPostsData.map((p) => p.id).join('\r\n'),
            dislikedPostsTimestamp: dislikedPostsDataTimeStamps
                .map((p) => p.dislikeTimeStamp)
                .join('\r\n'),

            nbCreatedComments: userCommentsData.length,
            createdComments: userCommentsData.map((p) => p.id).join('\r\n'),
            nbLikedComments: likedCommentsData.length,
            likedComments: likedCommentsData.map((p) => p.id).join('\r\n'),
            likedCommentsTimestamp: likedCommentsDataTimeStamps
                .map((p) => p.likeTimeStamp)
                .join('\r\n'),
            nbDislikedComments: dislikedCommentsData.length,
            dislikedComments: dislikedCommentsData
                .map((p) => p.id)
                .join('\r\n'),

            dislikedCommentsTimestamp: dislikedCommentsDataTimeStamps
                .map((p) => p.dislikeTimeStamp)
                .join('\r\n'),
        }
    })
}

const getCommentData = (
    comments: JoinRoom_roomAttendee_room_posts_comments[],
    postId: string
) => {
    return comments.map((c: any) => ({
        ...c,
        parentId: postId,
        level: 1,
        createdBy: c.userId,
        comments: '--',
        isFavorite: '--',
        createdAt: moment(c.createdAt).format('llll'),
        likes: c.reactions.filter(
            (r: any) => r.reactionType === ReactionType.Like
        ).length,
        dislikes: c.reactions.filter(
            (r: any) => r.reactionType === ReactionType.Dislike
        ).length,
    }))
}

const getPostData = (posts: JoinRoom_roomAttendee_room_posts[]) => {
    const comments = []
    for (const p of posts) {
        comments.push({
            ...p,
            level: 0,
            createdBy: (p as any).userId,
            createdAt: moment(p.createdAt).format('llll'),
            comments: p.comments.length,
            likes: p.reactions.filter(
                (r: any) => r.reactionType === ReactionType.Like
            ).length,
            dislikes: p.reactions.filter(
                (r: any) => r.reactionType === ReactionType.Dislike
            ).length,
        })
        comments.push(...getCommentData(p.comments, p.id))
    }

    return [...comments]
}
export const roomExport = async (roomId: string) => {
    // fetch the data from the backend
    const roomData = await exportRoom(roomId)

    const data = [
        {
            roomData: [
                {
                    ...roomData,
                    createdBy: roomData.userId,
                    createdAt: moment(roomData.createdAt).format('llll'),
                    users: roomData.users.length,
                    posts: roomData.posts.length,
                    polls: roomData.polls.length,
                },
            ],
        },
        {
            postData: [...getPostData(roomData.posts)],
        },

        {
            pollData: [
                ...roomData.polls.map((p: any) => ({
                    ...p,
                    createdBy: p.userId,
                    createdAt: moment(p.createdAt).format('llll'),
                    participants: getParticipants(p),

                    pollSolutions: [...p.options]
                        .sort((a, b) => (a.label > b.label && 1) || -1)
                        .filter((o: any) => o.isChecked === true)
                        .map((o: any) => `Option ${o.label.toUpperCase()}`)
                        .join('\r\n'),

                    pollResults: [...p.options]
                        .sort((a, b) => (a.label > b.label && 1) || -1)
                        .map(
                            (o: any) =>
                                `Option ${o.label.toUpperCase()}  - ${getOptionProgress(
                                    o,
                                    p
                                )} %  - ${getParticipants(p, o.id)} PCPs`
                        )
                        .join('\r\n'),
                    ...getOptionResults(p.options),
                })),
            ],
        },
        {
            userActionsData: [...getUserActionData(roomData)],
        },
    ]

    // consolidate data to build your excel sheets
    const excelExport = new ExcelExport()

    excelExport.downloadExcel(
        getSettingsForExport(
            `export-${roomData.name}-${moment().format('YYYY_MM_DD')}`
        ),
        data
    )
}
