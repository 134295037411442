import moment from 'moment'
import { debounce } from 'ts-debounce'
import { AppLogger } from '../../AppLogger'
import { AttendeeRole, ReactionType } from '../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room,
    JoinRoom_roomAttendee_room_polls,
    JoinRoom_roomAttendee_room_posts,
} from '../../generated/JoinRoom'
import {
    UserData_user_joinedRooms,
    UserData_user_joinedRooms_room_posts_comments,
} from '../../generated/UserData'
import { SortMenu } from '../Room/Room'
import {
    BASE_URL,
    DEBOUNCED_WAIT,
    FIRST_POSTS,
    SESSION_KEY,
    TOKEN_KEY,
} from './constant'

const logger = AppLogger.getInstance()

/**
 *
 * Best Order:
 * Admin view
 *  Favorite Posts in descending order
 *  Pools in descending order
 *  Normal Posts by their score ( aggregate "Liked - Disliked" reactions count) in descending order
 *
 * Guest view
 *  Pools in descending order
 *  All Posts by their score ( aggregate "Liked - Disliked" reactions count) in descending order
 *
 *  NB: when 2 posts have the same count maybe we can revert to createdAt
 *
 * Recent Order:
 *  Merge  posts + polls then display them in descending order  ref. column "createdAt"
 *
 * Moderated Order:
 *  Posts & Comments will be displayed based in ascending order ref. column "createdAt"
 *
 * NB : Recent and Best orders are not applicable at the comments level
 *    comments are displayed in ascending order ref. column 'createdAt'
 *
 * @param data
 * @param activeMenu
 * @returns
 */
export const getRoomContentByActiveMenu = (
    data: JoinRoom_roomAttendee_room,
    activeMenu: SortMenu,
    role: AttendeeRole
): (JoinRoom_roomAttendee_room_polls | JoinRoom_roomAttendee_room_posts)[] => {
    let result: (
        | JoinRoom_roomAttendee_room_polls
        | JoinRoom_roomAttendee_room_posts
    )[] = []
    switch (activeMenu) {
        case SortMenu.BEST:
            const newPosts = [...data.posts]
                .sort((a, b) => {
                    return moment(b.createdAt).diff(a.createdAt)
                })
                .sort((a, b) => {
                    const likesA = a.reactions.filter(
                        (r) => r.reactionType === ReactionType.Like
                    ).length
                    const dislikesA = a.reactions.filter(
                        (r) => r.reactionType === ReactionType.Dislike
                    ).length
                    const reactionsA = likesA - dislikesA

                    const likesB = b.reactions.filter(
                        (r) => r.reactionType === ReactionType.Like
                    ).length
                    const dislikesB = b.reactions.filter(
                        (r) => r.reactionType === ReactionType.Dislike
                    ).length
                    const reactionsB = likesB - dislikesB

                    return reactionsB - reactionsA
                })
            const favoritePosts = newPosts.filter((p) => p.isFavorite)
            const normalPosts = newPosts.filter((p) => !p.isFavorite)
            result =
                role === AttendeeRole.Guest
                    ? [...data.polls, ...newPosts]
                    : [...favoritePosts, ...data.polls, ...normalPosts]
            break
        case SortMenu.RECENT:
            result = [...data.polls, ...data.posts].sort((a, b) => {
                return moment(b.createdAt).diff(a.createdAt)
            })
            break

        case SortMenu.MODERATE:
            result = [...data.posts].sort((a, b) => {
                return moment(a.createdAt).diff(b.createdAt)
            })
            break

        default:
            break
    }

    // make sure there is no duplicate polls or posts
    const ids = result.map((o) => o.id)
    const filtered = result.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
    )

    return filtered
}

export const isVisiblePoll = (
    poll: JoinRoom_roomAttendee_room_polls,
    role: AttendeeRole
) => {
    switch (role) {
        case AttendeeRole.Admin:
            return true
        case AttendeeRole.Guest:
            return poll.isImmediateResults || poll.isOpened || poll.isResults

        default:
            return false
    }
}
export const isActivitiesAfterUserConnection = (
    row: UserData_user_joinedRooms
): boolean => {
    const role = row.role
    const polls = row.room.polls.filter(
        (p) => moment(p.createdAt).diff(row.lastSeenAt) > 0
    )
    let posts = row.room.posts.filter(
        (p) => moment(p.createdAt).diff(row.lastSeenAt) > 0
    )

    let comments: UserData_user_joinedRooms_room_posts_comments[] = []
    row.room.posts.forEach((post) => {
        const _comments = post.comments.filter(
            (c) => moment(c.createdAt).diff(row.lastSeenAt) > 0
        )
        comments.push(..._comments)
    })

    if (role === AttendeeRole.Guest) {
        posts = posts.filter((p) => p.isPublished)
        comments = comments.filter((c) => c.isPublished)
    }

    return polls.length > 0 || posts.length > 0 || comments.length > 0
}

export enum OptimisticAction {
    CreateReaction = 'createReaction',
    UpdateReaction = 'updateReaction',
    DeleteReaction = 'deleteReaction',
}

export enum OptimisticPollAction {
    CreateAnswer = 'createAnswer',
    UpdateAnswer = 'updateAnswer',
    DeleteAnswer = 'deleteAnswer',
}

export const getNicknameByUserId = (
    joinRoom: JoinRoom_roomAttendee_room,
    userId: string
) => {}

export enum EventCategory {
    Room = 'room',
    Post = 'post',
    Comment = 'comment',
    Poll = 'poll',
    Profile = 'profile',
}
export enum EventAction {
    Like = 'like',
    Dislike = 'dislike',
    Create = 'create',
    Publish = 'publish',
    UnPublish = 'un publish',
    Favorite = 'favorite',
    Delete = 'delete',
    Join = 'join',
    Rejoin = 're-join',
    Leave = 'leave',
    Export = 'export',
    Language = 'change language',
    Theme = 'change theme',
    ChangeUser = 'change user',
    Reset = 'poll reset',
}

export const gaPageView = (pageName: string) => {
    if (window.ga) {
        window.ga('send', 'pageview', pageName)
    } else {
        console.log('gaPageView - Google analytics is disabled')
    }
}

export const gaEvent = (
    eventCategory: EventCategory,
    eventAction: EventAction,
    eventLabel: string
) => {
    if (window.ga) {
        ga('send', 'event', {
            eventCategory: eventCategory.toString(),
            eventAction: eventAction.toString(),
            eventLabel,
        })
    } else {
        console.log('gaEvent - Google analytics is disabled')
    }
}

/**
 * Resource : https://animate.style/#javascript
 * A helper function added to enable animation on a given element
 * usage samples
 *  animateCSS('.my-element', 'bounce',0.5);
 *  or
 *  animateCSS('.my-element', 'bounce',2).then((message) => {
 *  // Do something after the animation
 *  });
 * @param element
 * @param animation
 * @param duration
 * @param prefix
 * @returns
 */
export const animateCSS = (
    element: string,
    animation: string,
    duration: number,
    prefix = 'animate__'
) =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`
        const node = document.querySelector(element)
        if (node) {
            if (duration) {
                const node2 = node as any
                node2.style.setProperty('--animate-duration', `${duration}s`)
            }

            node.classList.add(`${prefix}animated`, animationName)

            node.addEventListener(
                'animationend',
                (event) => {
                    // When the animation ends, we clean the classes and resolve the Promise
                    event.stopPropagation()
                    node.classList.remove(`${prefix}animated`, animationName)
                    resolve('Animation ended')
                },
                { once: true }
            )
        }
    })

export const roundNumberV1 = (num: number, decimalPlaces = 0) => {
    const p = Math.pow(10, decimalPlaces)
    const n = num * p * (1 + Number.EPSILON)
    const finalValue = Math.round(n) / p

    return finalValue > 100 ? 100 : finalValue
}

/**
 * return true when action is performed by the same userSession
 */
export const isSameUserSession = (modifiedBy: string | null): boolean => {
    let isSame = false
    const userSession = sessionStorage.getItem(SESSION_KEY)
    if (modifiedBy && modifiedBy === userSession) {
        isSame = true
    }
    logger.debug(`isSameUserSession:${isSame}`)
    return isSame
}

export const fetchRoomInfo = async (
    role: AttendeeRole,
    roomId: string,
    joinKey: string
) => {
    const response = await fetch(
        `${BASE_URL}/room/${
            role === AttendeeRole.Admin ? 'adminInfos' : 'guestInfos'
        }`,
        {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                authorization: localStorage.getItem(TOKEN_KEY) || '',
            },
            body: JSON.stringify({ roomId, joinKey }),
        }
    )
    if (!response.ok) {
        return false
    }
    return await response.json()
}

const debouncedFetchRoomInfo = debounce(fetchRoomInfo, DEBOUNCED_WAIT * 2, {
    isImmediate: false,
})

export const getCommentsCount = (
    posts: JoinRoom_roomAttendee_room_posts[]
): number => {
    return posts.reduce((prevValue, post) => {
        return post.comments.length + prevValue
    }, 0)
}

export const shouldLoadMorePosts = async (
    data: JoinRoom,
    posts: JoinRoom_roomAttendee_room_posts[],
    deletedPostId: string,
    fetchMore: Function
) => {
    if (data.roomAttendee && posts) {
        try {
            // logic to load more
            const roomInfo = await debouncedFetchRoomInfo(
                data.roomAttendee.role!,
                data.roomAttendee.room.id!,
                data.roomAttendee.joinKey!
            )

            if (roomInfo.posts >= FIRST_POSTS && posts.length < FIRST_POSTS) {
                logger.info(`POST_DELETE_SUBSCRIPTION load more posts`)
                fetchMore(deletedPostId)
            }
        } catch (e) {
            logger.error(`shouldLoadMorePosts error: `, e)
        }
    }
}

export const updateRoomInfo = async (data: JoinRoom, setRoomInfo: Function) => {
    if (data.roomAttendee) {
        try {
            // logic to load more
            const roomInfo = await debouncedFetchRoomInfo(
                data.roomAttendee.role!,
                data.roomAttendee.room.id!,
                data.roomAttendee.joinKey!
            )
            setRoomInfo(roomInfo.posts, roomInfo.postsNotPub, roomInfo.comments)
        } catch (e) {
            logger.error(`updateRoomInfo error: `, e)
        }
    }
}
