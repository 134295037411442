import { useQuery, useMutation, useSubscription } from '@apollo/client'

import { AppLogger } from '../../AppLogger'

import {
    CommentCreateDataChange,
    CommentCreateDataChangeVariables,
} from '../../generated/CommentCreateDataChange'
import {
    CommentDeleteDataChange,
    CommentDeleteDataChangeVariables,
} from '../../generated/CommentDeleteDataChange'
import {
    CommentUpdateDataChange,
    CommentUpdateDataChangeVariables,
} from '../../generated/CommentUpdateDataChange'
import {
    CreateOneRoom,
    CreateOneRoomVariables,
} from '../../generated/CreateOneRoom'

import {
    DeleteOneRoom,
    DeleteOneRoomVariables,
} from '../../generated/DeleteOneRoom'
import {
    DeleteOneRoomAttendee,
    DeleteOneRoomAttendeeVariables,
} from '../../generated/DeleteOneRoomAttendee'
import { AttendeeRole, Language, Theme } from '../../generated/globalTypes'
import {
    PollCreateDataChange,
    PollCreateDataChangeVariables,
} from '../../generated/PollCreateDataChange'
import {
    PollDeleteDataChange,
    PollDeleteDataChangeVariables,
} from '../../generated/PollDeleteDataChange'
import {
    PostCreateDataChange,
    PostCreateDataChangeVariables,
} from '../../generated/PostCreateDataChange'
import {
    PostDeleteDataChange,
    PostDeleteDataChangeVariables,
} from '../../generated/PostDeleteDataChange'
import {
    PostUpdateDataChange,
    PostUpdateDataChangeVariables,
} from '../../generated/PostUpdateDataChange'
import {
    RoomCreateDataChange,
    RoomCreateDataChangeVariables,
} from '../../generated/RoomCreateDataChange'
import {
    RoomDeleteContentDataChange,
    RoomDeleteContentDataChangeVariables,
} from '../../generated/RoomDeleteContentDataChange'
import {
    RoomDeleteDataChange,
    RoomDeleteDataChangeVariables,
} from '../../generated/RoomDeleteDataChange'
import {
    RoomJoinDataChange,
    RoomJoinDataChangeVariables,
} from '../../generated/RoomJoinDataChange'
import {
    RoomLeaveDataChange,
    RoomLeaveDataChangeVariables,
} from '../../generated/RoomLeaveDataChange'

import {
    UpdateOneUser,
    UpdateOneUserVariables,
} from '../../generated/UpdateOneUser'
import {
    UserData,
    UserDataVariables,
    UserData_user_joinedRooms,
    UserData_user_joinedRooms_room_polls,
    UserData_user_joinedRooms_room_posts,
} from '../../generated/UserData'

import {
    COMMENT_CREATE_SUBSCRIPTION,
    COMMENT_UPDATE_SUBSCRIPTION,
    COMMENT_DELETE_SUBSCRIPTION,
} from '../../queries/comment'
import {
    POLL_CREATE_SUBSCRIPTION,
    POLL_DELETE_SUBSCRIPTION,
} from '../../queries/poll'
import {
    POST_CREATE_SUBSCRIPTION,
    POST_DELETE_SUBSCRIPTION,
    POST_UPDATE_SUBSCRIPTION,
} from '../../queries/post'
import {
    ROOM_CREATE,
    ROOM_CREATE_SUBSCRIPTION,
    ROOM_DELETE,
    ROOM_DELETE_CONTENT_SUBSCRIPTION,
    ROOM_DELETE_SUBSCRIPTION,
    ROOM_JOIN_SUBSCRIPTION,
    ROOM_LEAVE,
    ROOM_LEAVE_SUBSCRIPTION,
} from '../../queries/room'
import { USER_QUERY, USER_UPDATE } from '../../queries/user'
import { RoomForm } from './partials/CreateRoom'
const logger = AppLogger.getInstance()
export function useHome(userId: string) {
    /**
     * Queries
     */
    const { data, loading } = useQuery<UserData, UserDataVariables>(
        USER_QUERY,
        {
            variables: {
                id: userId,
            },
        }
    )

    /**
     * Mutations
     */

    const [createRoom] =
        useMutation<CreateOneRoom, CreateOneRoomVariables>(ROOM_CREATE)

    const [updateProfile] =
        useMutation<UpdateOneUser, UpdateOneUserVariables>(USER_UPDATE)

    const [deleteRoom] =
        useMutation<DeleteOneRoom, DeleteOneRoomVariables>(ROOM_DELETE)

    const [leaveRoom] =
        useMutation<DeleteOneRoomAttendee, DeleteOneRoomAttendeeVariables>(
            ROOM_LEAVE
        )

    /**
     * Subscriptions
     */

    /**
    |---------------------------------------------------------
    | Triggered on roomAttendee creation mainly after creating a new room 
    | onSubscriptionData: update the joinedRooms block by adding the newly added roomAttendee record
    |---------------------------------------------------------
    */
    useSubscription<RoomCreateDataChange, RoomCreateDataChangeVariables>(
        ROOM_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`ROOM_CREATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `ROOM_CREATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const joinedRooms = [
                    ...data?.user?.joinedRooms!,
                    subscriptionData.data?.onRoomCreate,
                ]
                client.writeQuery({
                    query: USER_QUERY,
                    data: {
                        user: {
                            ...data?.user,
                            joinedRooms,
                        },
                    },
                    variables: {
                        id: userId,
                    },
                })
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room join or re-join
    | onSubscriptionData: update the joinedRooms block 
    | for join case : add the new roomAttendee record 
    | for re-join case : update the existing roomAttendee mainly the lastSeenAt 
    |---------------------------------------------------------
    */
    useSubscription<RoomJoinDataChange, RoomJoinDataChangeVariables>(
        ROOM_JOIN_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(
                    `ROOM_JOIN_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const _roomAttendee = subscriptionData.data
                    ?.onRoomJoin as UserData_user_joinedRooms

                logger.debug(
                    `ROOM_JOIN_SUBSCRIPTION subscriptionData _roomAttendee:`,
                    _roomAttendee
                )

                const existingRooms = data?.user?.joinedRooms!

                let updatedRooms = [...existingRooms]
                const { id: userIdRoomIdRole, role } = _roomAttendee

                const roomAttendeeArgs = userIdRoomIdRole.split('_')
                const roomAttendeeUserId = roomAttendeeArgs
                    ? (roomAttendeeArgs[0] as string)
                    : ''
                const roomAttendeeRoomId = roomAttendeeArgs
                    ? (roomAttendeeArgs[1] as string)
                    : ''

                const attendeeExist = existingRooms.find(
                    (r) => r.id === userIdRoomIdRole
                )

                if (roomAttendeeUserId === userId) {
                    // attendee record exist
                    if (attendeeExist) {
                        // triggered by admin
                        if (role === AttendeeRole.Admin) {
                            // update admin and guest joined record in case of, user has joined as an admin and as a guest
                            updatedRooms = [
                                ...existingRooms.map((r) => {
                                    if (r.room.id === roomAttendeeRoomId) {
                                        return {
                                            ...r,
                                            lastSeenAt:
                                                _roomAttendee.lastSeenAt,
                                        }
                                    }
                                    return r
                                }),
                            ]
                        } else {
                            // triggered by guest  update the guest attendee
                            updatedRooms = [
                                ...existingRooms.map((r) => {
                                    if (
                                        r.room.id === roomAttendeeRoomId &&
                                        r.role === role
                                    ) {
                                        return {
                                            ...r,
                                            lastSeenAt:
                                                _roomAttendee.lastSeenAt,
                                        }
                                    }
                                    return r
                                }),
                            ]
                        }
                    } else {
                        // attendee doesn't exist  - add it
                        logger.debug(
                            `Same user case Add new roomAttendee record `
                        )
                        updatedRooms = [...updatedRooms, _roomAttendee]
                    }
                }

                client.writeQuery({
                    query: USER_QUERY,
                    data: {
                        user: {
                            ...data?.user,
                            joinedRooms: updatedRooms,
                        },
                    },

                    variables: {
                        id: userId,
                    },
                })
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room leave
    | onSubscriptionData: update the joinedRooms block by removing the room attendee record
    |---------------------------------------------------------
    */
    useSubscription<RoomLeaveDataChange, RoomLeaveDataChangeVariables>(
        ROOM_LEAVE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`ROOM_LEAVE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `ROOM_LEAVE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )

                const userIdRoomIdRole = subscriptionData.data?.onRoomLeave?.id
                const args = userIdRoomIdRole?.split('_')
                const roomId = args ? (args[1] as string) : ''
                const role = args ? (args[2] as AttendeeRole) : ''
                if (roomId && role) {
                    logger.debug(`roomId:${roomId} - role:${role}`)
                    const joinedRooms = data?.user?.joinedRooms!.filter(
                        (r) => r.room.id !== roomId || r.role !== role
                    )
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room delete
    | onSubscriptionData: update the joinedRooms block by removing the room attendee record
    |---------------------------------------------------------
    */
    useSubscription<RoomDeleteDataChange, RoomDeleteDataChangeVariables>(
        ROOM_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`ROOM_DELETE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `ROOM_DELETE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const joinedRooms = data?.user?.joinedRooms!.filter(
                    (r) => r.room.id !== subscriptionData.data?.onRoomDelete?.id
                )
                client.writeQuery({
                    query: USER_QUERY,
                    data: {
                        user: {
                            ...data?.user,
                            joinedRooms,
                        },
                    },
                    variables: {
                        id: userId,
                    },
                })
            },
        }
    )

    useSubscription<PostCreateDataChange, PostCreateDataChangeVariables>(
        POST_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POST_CREATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POST_CREATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const newPost = subscriptionData.data?.onPostCreate
                if (newPost) {
                    const joinedRooms = data?.user?.joinedRooms!.map((j) => {
                        if (j.room.id === newPost.room.id) {
                            const posts = [
                                ...j.room.posts,
                                { ...newPost, comments: [], reactions: [] },
                            ]
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    posts,
                                },
                            }
                        }
                        return j
                    })
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    useSubscription<PostUpdateDataChange, PostUpdateDataChangeVariables>(
        POST_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POST_UPDATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POST_UPDATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const updatedPost = subscriptionData.data?.onPostUpdate
                if (updatedPost) {
                    const joinedRooms = data?.user?.joinedRooms!.map((j) => {
                        const targetPost = j.room.posts.find(
                            (p) => p.id === updatedPost.id
                        )
                        if (targetPost) {
                            const posts = j.room.posts.map((p) => {
                                if (p.id === targetPost.id) {
                                    return updatedPost
                                }
                                return p
                            })
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    posts,
                                },
                            }
                        }
                        return j
                    })
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    useSubscription<PostDeleteDataChange, PostDeleteDataChangeVariables>(
        POST_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POST_DELETE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POST_DELETE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const deletedPost = subscriptionData.data?.onPostDelete
                if (deletedPost) {
                    const joinedRooms = data?.user?.joinedRooms!.map((j) => {
                        const targetPost = j.room.posts.find(
                            (p) => p.id === deletedPost.id
                        )
                        if (targetPost) {
                            const posts = j.room.posts.filter(
                                (p) => p.id !== targetPost.id
                            )
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    posts,
                                },
                            }
                        }
                        return j
                    })
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    useSubscription<PollCreateDataChange, PollCreateDataChangeVariables>(
        POLL_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POLL_CREATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POLL_CREATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const newPoll = subscriptionData.data?.onPollCreate
                if (newPoll) {
                    const joinedRooms = data?.user?.joinedRooms!.map((j) => {
                        if (j.room.id === newPoll.room.id) {
                            const polls = [
                                ...j.room.polls,
                                {
                                    ...newPoll,
                                    options: newPoll.options.map((o) => ({
                                        ...o,
                                        results: [],
                                    })),
                                },
                            ]
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    polls,
                                },
                            }
                        }
                        return j
                    })
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    useSubscription<PollDeleteDataChange, PollDeleteDataChangeVariables>(
        POLL_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POLL_DELETE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POLL_DELETE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const deletedPoll = subscriptionData.data?.onPollDelete
                if (deletedPoll) {
                    const joinedRooms = data?.user?.joinedRooms!.map((j) => {
                        const targetPoll = j.room.polls.find(
                            (p) => p.id === deletedPoll.id
                        )
                        if (targetPoll) {
                            const polls = j.room.polls.filter(
                                (p) => p.id !== targetPoll.id
                            )
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    polls,
                                },
                            }
                        }
                        return j
                    })
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room content delete 
    | onSubscriptionData: 
    | 1 - update the current joined room by removing all the posts and polls then update apollo cache
    |---------------------------------------------------------
    */
    useSubscription<
        RoomDeleteContentDataChange,
        RoomDeleteContentDataChangeVariables
    >(ROOM_DELETE_CONTENT_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(
                ` Home ROOM_DELETE_CONTENT_SUBSCRIPTION client:`,
                client
            )
            logger.debug(
                `Home ROOM_DELETE_CONTENT_SUBSCRIPTION subscriptionData:`,
                subscriptionData
            )

            if (subscriptionData.data?.onRoomDeleteContent) {
                const joinedRooms = data?.user?.joinedRooms!.map((j) => {
                    if (
                        subscriptionData.data?.onRoomDeleteContent?.roomId ===
                        j.room.id
                    ) {
                        const posts: UserData_user_joinedRooms_room_posts[] = []
                        const polls: UserData_user_joinedRooms_room_polls[] = []
                        return {
                            ...j,
                            room: {
                                ...j.room,
                                polls,
                                posts,
                            },
                        }
                    }
                    return j
                })
                client.writeQuery({
                    query: USER_QUERY,
                    data: {
                        user: {
                            ...data?.user,
                            joinedRooms,
                        },
                    },
                    variables: {
                        id: userId,
                    },
                })
            }
        },
    })

    useSubscription<CommentCreateDataChange, CommentCreateDataChangeVariables>(
        COMMENT_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`COMMENT_CREATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `COMMENT_CREATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )

                const newComment = subscriptionData.data?.onCommentCreate
                if (newComment) {
                    const targetAttendees = data?.user?.joinedRooms.filter(
                        (joinedRoom) => {
                            const _post = joinedRoom.room.posts.find(
                                (post) => post.id === newComment.post.id
                            )
                            return !!_post
                        }
                    )

                    const updatedTargetAttendees = targetAttendees?.map((j) => {
                        const _targetPost = j.room.posts.find(
                            (p) => p.id === newComment.post.id
                        )

                        if (_targetPost) {
                            const _posts = j.room.posts.map((p) => {
                                if (p.id === _targetPost.id) {
                                    const updatedComments = [
                                        ...p.comments,
                                        { ...newComment, reactions: [] },
                                    ]

                                    return { ...p, comments: updatedComments }
                                }
                                return p
                            })
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    posts: _posts,
                                },
                            }
                        }
                        return j
                    })
                    const otherAttendees = data?.user?.joinedRooms!.filter(
                        (j) => {
                            const isFound = updatedTargetAttendees?.find(
                                (u) => u.id === j.id
                            )
                            return !isFound
                        }
                    )
                    let joinedRooms: UserData_user_joinedRooms[] = []

                    if (updatedTargetAttendees) {
                        logger.debug(
                            `updatedTargetAttendees:`,
                            updatedTargetAttendees
                        )
                        joinedRooms.push(...updatedTargetAttendees)
                    }
                    if (otherAttendees) {
                        joinedRooms.push(...otherAttendees)
                    }
                    logger.debug('joinedRooms after sub', joinedRooms)
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    useSubscription<CommentUpdateDataChange, CommentUpdateDataChangeVariables>(
        COMMENT_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`COMMENT_UPDATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `COMMENT_UPDATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const updatedComment = subscriptionData.data?.onCommentUpdate
                if (updatedComment) {
                    const targetAttendees = data?.user?.joinedRooms.filter(
                        (joinedRoom) => {
                            const _post = joinedRoom.room.posts.find(
                                (post) => post.id === updatedComment.post.id
                            )
                            return !!_post
                        }
                    )

                    const updatedTargetAttendees = targetAttendees?.map((j) => {
                        const _targetPost = j.room.posts.find(
                            (p) => p.id === updatedComment.post.id
                        )

                        if (_targetPost) {
                            const _posts = j.room.posts.map((p) => {
                                if (p.id === _targetPost.id) {
                                    const updatedComments = p.comments.map(
                                        (c) => {
                                            if (c.id === updatedComment.id) {
                                                return updatedComment
                                            }
                                            return c
                                        }
                                    )
                                    return { ...p, comments: updatedComments }
                                }
                                return p
                            })
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    posts: _posts,
                                },
                            }
                        }
                        return j
                    })
                    const otherAttendees = data?.user?.joinedRooms!.filter(
                        (j) => {
                            const isFound = updatedTargetAttendees?.find(
                                (u) => u.id === j.id
                            )
                            return !isFound
                        }
                    )
                    let joinedRooms: UserData_user_joinedRooms[] = []

                    if (updatedTargetAttendees) {
                        joinedRooms.push(...updatedTargetAttendees)
                    }
                    if (otherAttendees) {
                        joinedRooms.push(...otherAttendees)
                    }
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    useSubscription<CommentDeleteDataChange, CommentDeleteDataChangeVariables>(
        COMMENT_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`COMMENT_DELETE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `COMMENT_DELETE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const deletedComment = subscriptionData.data?.onCommentDelete
                if (deletedComment) {
                    const targetAttendees = data?.user?.joinedRooms.filter(
                        (joinedRoom) => {
                            const _post = joinedRoom.room.posts.find((post) => {
                                const _comment = post.comments.find(
                                    (c) => c.id === deletedComment.id
                                )
                                return !!_comment
                            })
                            return !!_post
                        }
                    )

                    const updatedTargetAttendees = targetAttendees?.map((j) => {
                        const _targetPost = j.room.posts.find((p) => {
                            const _targetComment = p.comments.find(
                                (c) => c.id === deletedComment.id
                            )
                            return !!_targetComment
                        })

                        if (_targetPost) {
                            const _posts = j.room.posts.map((p) => {
                                if (p.id === _targetPost.id) {
                                    const updatedComments = p.comments.filter(
                                        (c) => c.id !== deletedComment.id
                                    )
                                    return { ...p, comments: updatedComments }
                                }
                                return p
                            })
                            return {
                                ...j,
                                room: {
                                    ...j.room,
                                    posts: _posts,
                                },
                            }
                        }
                        return j
                    })
                    const otherAttendees = data?.user?.joinedRooms!.filter(
                        (j) => {
                            const isFound = updatedTargetAttendees?.find(
                                (u) => u.id === j.id
                            )
                            return !isFound
                        }
                    )
                    let joinedRooms: UserData_user_joinedRooms[] = []

                    if (updatedTargetAttendees) {
                        joinedRooms.push(...updatedTargetAttendees)
                    }
                    if (otherAttendees) {
                        joinedRooms.push(...otherAttendees)
                    }
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...data?.user,
                                joinedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    /**
     * useEffect hooks
     */

    return {
        data,
        loading,
        updateProfile: (language: Language, theme: Theme) =>
            updateProfile({
                variables: {
                    data: {
                        language: {
                            set: language,
                        },
                        theme: {
                            set: theme,
                        },
                    },
                    where: {
                        id: userId,
                    },
                },
            }),
        deleteRoom: (roomId: string) =>
            deleteRoom({
                variables: {
                    where: {
                        id: roomId,
                    },
                },
                refetchQueries: [
                    {
                        query: USER_QUERY,
                        variables: {
                            id: userId,
                        },
                    },
                ],
            }),
        leaveRoom: async (roomId: string, role: AttendeeRole) =>
            await leaveRoom({
                variables: {
                    where: {
                        userId_roomId_role: {
                            userId,
                            role,
                            roomId,
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: USER_QUERY,
                        variables: {
                            id: userId,
                        },
                    },
                ],
            }),
        createRoom: async (roomForm: RoomForm) =>
            await createRoom({
                variables: {
                    data: {
                        ...roomForm,
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                    },
                },

                refetchQueries: [
                    {
                        query: USER_QUERY,
                        variables: {
                            id: userId,
                        },
                    },
                ],
            }),
    }
}
