import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import {
    InMemoryCache,
    ApolloClient,
    ApolloLink,
    split,
    HttpLink,
} from '@apollo/client'

import { SubscriptionClient } from 'subscriptions-transport-ws'

import { isMobile, isIOS, isAndroid, browserName } from 'react-device-detect'

import { THEME_KEY, TOKEN_KEY } from './components/common/constant'
import { AppLogger } from './AppLogger'

const accessTokenKey = TOKEN_KEY

const logger = AppLogger.getInstance()

/**
 * return the profile of the end client regardless if it's mobile or web
 * e.g: speakup,unine,unil,unige and epfl
 * @returns
 */
const getProfile = () => {
    const preferredTheme = localStorage.getItem(THEME_KEY)
    let profile = 'SpeakUp'
    if (preferredTheme) {
        profile = preferredTheme
    }
    return profile
}

/**
 * get the device used
 * e.g : ios, android, and browsername
 */
const getOS = () => {
    let device = browserName
    if (isIOS) {
        device = 'iOS'
    }
    if (isAndroid) {
        device = 'Android'
    }
    return device
}

/**
 * WM = Web Mobile
 * W = Web
 * @returns
 */
const getOrigin = () => {
    return isMobile ? 'WM' : 'W'
}

/**
 * When serving app in release mode
 * Determine socket protocol ws or wss based on the window protocol
 */
const getWS_LinkUri = (): string => {
    if (window.location.protocol === 'http:') {
        return `ws://${window.location.host}/speakup/wss`
    }
    return `wss://${window.location.host}/speakup/wss`
}

export const getAccessToken = () => {
    const _token = window.localStorage.getItem(accessTokenKey)
    const _accessToken = _token ? _token : ''
    logger.debug(`getAccessToken:${_accessToken}`)
    return _accessToken
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, path }) => {
            logger.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
        })
    }
    if (networkError) {
        logger.error(
            `[Network error ${operation.operationName}]: ${networkError.message}`
        )
    }
})

// for release build REACT_APP_WEBSOCKETLINK_URI should be null
const wsLinkUri = process.env['REACT_APP_WEBSOCKETLINK_URI']

const subscriptionClient = new SubscriptionClient(
    wsLinkUri ? wsLinkUri : getWS_LinkUri(),
    {
        reconnect: true,
        connectionParams: {
            authToken: getAccessToken(),
            or: getOrigin(),
            th: getProfile(),
            os: getOS(),
        },
        connectionCallback: (error) => {
            if (error) {
                logger.error(`>>>>>>>>>> subscriptionClient error initializing the socket:
                    error:${error} >>>>>>>>>>`)
            } else {
                logger.info(
                    `>>>>>>>> subscriptionClient has been initialized >>>>>>>>`
                )
            }
        },
    }
)

const createClient = () => {
    const cache = new InMemoryCache({
        typePolicies: {
            Room: {
                fields: {
                    posts: {
                        // Don't cache separate results based on
                        // any of this field's arguments.
                        keyArgs: ['id'],
                        // Concatenate the incoming list items with
                        // the existing list items.
                        merge(existing = [], incoming, m) {
                            if (m.variables && m.variables.operation) {
                                logger.debug(
                                    `posts  m.variables.operation:${m.variables.operation}`
                                )
                            }

                            const all = [...existing, ...incoming]
                            const ids = all.map((o) => o.__ref)
                            let filtered = all.filter(
                                ({ __ref }, index) =>
                                    !ids.includes(__ref, index + 1)
                            )

                            if (
                                m.variables &&
                                m.variables.operation &&
                                m.variables.operation.startsWith('DELETE_POST')
                            ) {
                                const refPostId = m.variables.operation.replace(
                                    'DELETE_POST_',
                                    'Post:'
                                )
                                filtered = filtered.filter(
                                    (p) => p.__ref !== refPostId
                                )
                            }

                            return filtered
                        },
                    },

                    polls: {
                        keyArgs: ['id'],
                        merge(existing = [], incoming, m) {
                            if (m.variables && m.variables.operation) {
                                logger.debug(
                                    `polls  m.variables.operation:${m.variables.operation}`
                                )
                            }

                            const all = [...existing, ...incoming]
                            const ids = all.map((o) => o.__ref)
                            let filtered = all.filter(
                                ({ __ref }, index) =>
                                    !ids.includes(__ref, index + 1)
                            )

                            if (
                                m.variables &&
                                m.variables.operation &&
                                m.variables.operation.startsWith('DELETE_POLL')
                            ) {
                                const refPollId = m.variables.operation.replace(
                                    'DELETE_POLL_',
                                    'Poll:'
                                )
                                filtered = filtered.filter(
                                    (p) => p.__ref !== refPollId
                                )
                            }

                            return filtered
                        },
                    },
                },
            },
            Post: {
                fields: {
                    reactions: {
                        keyArgs: ['id'],
                        merge(existing = [], incoming, m) {
                            if (m.variables && m.variables.operation) {
                                logger.debug(
                                    `post reactions  m.variables.operation:${m.variables.operation}`
                                )
                            }
                            const all = [...existing, ...incoming]
                            const ids = all.map((o) => o.__ref)
                            let filtered = all.filter(
                                ({ __ref }, index) =>
                                    !ids.includes(__ref, index + 1)
                            )

                            if (
                                m.variables &&
                                m.variables.operation &&
                                m.variables.operation.startsWith(
                                    'DELETE_POST_REACTION'
                                )
                            ) {
                                const refPostReactionId =
                                    m.variables.operation.replace(
                                        'DELETE_POST_REACTION_',
                                        'PostReaction:'
                                    )
                                filtered = filtered.filter(
                                    (p) => p.__ref !== refPostReactionId
                                )
                            }

                            return filtered
                        },
                    },
                    comments: {
                        keyArgs: ['id'],

                        merge(existing = [], incoming, m) {
                            if (m.variables && m.variables.operation) {
                                logger.debug(
                                    `post comments  m.variables.operation:${m.variables.operation}`
                                )
                            }
                            const all = [...existing, ...incoming]
                            const ids = all.map((o) => o.__ref)
                            let filtered = all.filter(
                                ({ __ref }, index) =>
                                    !ids.includes(__ref, index + 1)
                            )

                            if (
                                m.variables &&
                                m.variables.operation &&
                                m.variables.operation.startsWith(
                                    'DELETE_COMMENT'
                                )
                            ) {
                                const refCommentId =
                                    m.variables.operation.replace(
                                        'DELETE_COMMENT_',
                                        'Comment:'
                                    )
                                filtered = filtered.filter(
                                    (p) => p.__ref !== refCommentId
                                )
                            }

                            return filtered
                        },
                    },
                },
            },
            Comment: {
                fields: {
                    reactions: {
                        keyArgs: ['id'],
                        merge(existing = [], incoming, m) {
                            if (m.variables && m.variables.operation) {
                                logger.debug(
                                    `comment reactions  m.variables.operation:${m.variables.operation}`
                                )
                            }
                            const all = [...existing, ...incoming]
                            const ids = all.map((o) => o.__ref)
                            let filtered = all.filter(
                                ({ __ref }, index) =>
                                    !ids.includes(__ref, index + 1)
                            )

                            if (
                                m.variables &&
                                m.variables.operation &&
                                m.variables.operation.startsWith(
                                    'DELETE_COMMENT_REACTION'
                                )
                            ) {
                                const refPostReactionId =
                                    m.variables.operation.replace(
                                        'DELETE_COMMENT_REACTION_',
                                        'CommentReaction:'
                                    )
                                filtered = filtered.filter(
                                    (p) => p.__ref !== refPostReactionId
                                )
                            }

                            return filtered
                        },
                    },
                },
            },
        },
    })
    /*{
        typePolicies: {
            RoomAttendee: {
                keyFields: ['userIdRoomIdRole'],
            },
        },
    }*/
    const httpLink = new HttpLink({
        uri: '/speakup/graphql',
        credentials: 'include',
    })

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = window.localStorage.getItem(accessTokenKey)

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        }
    })

    // Create a WebSocket link:
    const wsLink = new WebSocketLink(subscriptionClient)

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query)
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            )
        },
        wsLink,
        authLink.concat(httpLink)
    )
    const client = new ApolloClient({
        cache,
        link: ApolloLink.from([errorLink, link]),
    })
    // A client can have defaultOptions key doc at below link
    // https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object

    return client
}

export { createClient as default, subscriptionClient }
