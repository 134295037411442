import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import * as QueryString from 'query-string'
import { $ } from 'react-jquery-plugin'
import Header from './partials/Header'
import SettingsGuest from './partials/SettingsGuest'
import SettingsAdmin from './partials/SettingsAdmin'
import { useLocation, useParams } from 'react-router-dom'
import { useRoom } from './useRoom'
import { useProfile } from '../common/hooks/useProfile'
import { useSubscriptionClient } from '../common/hooks/useSubscriptionClient'
import { getUserIdFromToken } from '../../auth'
import Input from './partials/content/Input'
import MenuContent from './partials/content/MenuContent'
import SwitchMenu from './partials/content/SwitchMenu'
import { AppLogger } from '../../AppLogger'
import NicknameModal from './partials/NicknameModal'
import { fetchRoomInfo, gaPageView, getCommentsCount } from '../common/utilis'
import { MAX_POLLS_BY_ROOM } from '../common/constant'
import { JoinRoom_roomAttendee_room_polls } from '../../generated/JoinRoom'
import { AttendeeRole } from '../../generated/globalTypes'

/**
 *
 */
export enum SortMenu {
    RECENT = 'Recent',
    BEST = 'Best',
    MODERATE = 'Moderate',
}
const logger = AppLogger.getInstance()

interface Props {}

const Room: React.FC<Props> = (props) => {
    const locationRoom = useLocation()
    const params = QueryString.parse(locationRoom.search)
    const roomParams = useParams<{ joinKey?: string }>()

    const userId = getUserIdFromToken()!
    const joinKey = locationRoom.state
        ? locationRoom.state.joinKey
        : roomParams.joinKey
        ? roomParams.joinKey
        : null

    const nickName = (params.nickName ? params.nickName : null) as string | null

    const { clientConnectionError, isMounted } = useSubscriptionClient()
    useProfile(userId)

    const setRoomInfoFn = useCallback(
        (posts: number, postsNotPub: number, comments: number) => {
            setRoomInfo({
                posts: posts,
                postsNotPub: postsNotPub,
                comments: comments,
            })
        },
        []
    )

    const {
        fetchMore,
        data,
        loading,
        deleteRoom,
        roomDeleteContent,
        leaveRoom,
        updateRoom,
        createPost,
        createPoll,
        updateRoomAttendee,
        updateRoomAttendeeStatus,
        updateRoomAttendeeLastSeen,
    } = useRoom(joinKey, userId, nickName, setRoomInfoFn)

    logger.info(`Room clientConnectionError is:${clientConnectionError} `)
    logger.info(`Room isMounted is:${isMounted.current} `)

    const roomId = data ? data.roomAttendee!.room!.id : null
    const postsLength = data ? data.roomAttendee!.room!.posts.length : null
    const commentLength =
        data && data.roomAttendee!.room!.posts.length > 0
            ? getCommentsCount(data.roomAttendee!.room!.posts)
            : 0
    const role = data ? data.roomAttendee?.role! : null

    const updateLastSeen = useCallback(
        (roomId: string, role: AttendeeRole) => {
            updateRoomAttendeeLastSeen(roomId, role.toString())
        },
        [updateRoomAttendeeLastSeen]
    )

    const [roomInfo, setRoomInfo] = useState<{
        posts: number
        postsNotPub?: number
        comments?: number
    }>({
        posts: 0,
    })

    const [activeMenu, setActiveMenu] = useState<SortMenu>(SortMenu.RECENT)
    const handleSortMenu = (sortMenu: SortMenu) => {
        $('body').removeClass('switch-moderate-select')
        if (isMounted.current) setActiveMenu(sortMenu)
        switch (sortMenu) {
            case SortMenu.BEST:
                break
            case SortMenu.RECENT:
                break
            case SortMenu.MODERATE:
                $('body').addClass('switch-moderate-select')
                break
            default:
        }
    }

    useEffect(() => {
        if (!loading && role && roomId && isMounted.current) {
            fetchRoomInfo(role, roomId, joinKey).then((_data) => {
                setRoomInfoFn(_data.posts, _data.postsNotPub, _data.comments)
            })
        }
    }, [
        loading,
        role,
        roomId,
        joinKey,
        postsLength,
        commentLength,
        setRoomInfoFn,
        isMounted,
    ])

    useEffect(() => {
        if (role && roomId) {
            const fn = function (e: BeforeUnloadEvent) {
                e.preventDefault()
                updateLastSeen(roomId, role)
            }
            window.addEventListener('beforeunload', fn)
            return () => {
                window.removeEventListener('beforeunload', fn)
            }
        }
    }, [role, roomId, updateLastSeen])

    useEffect(() => {
        $('body').addClass('flag-loading')
        if (!loading) {
            setTimeout(() => $('body').removeClass('flag-loading'), 0)
        }

        gaPageView('Room')
        return () => {
            $('body').removeClass('flag-loading')
        }
    }, [loading])

    // remove class switch-moderate-select
    useEffect(() => {
        return () => {
            $('body').removeClass('switch-moderate-select')
        }
    }, [])

    //if (loading || !data) return <div>{t('loading')} ...</div>
    if (loading || !data) return <div></div>

    // nickname logic
    if (data && data.roomAttendee && data.roomAttendee.room.isNicknames) {
        const { nickName } = data.roomAttendee

        if (!nickName) {
            $('body').addClass('switch-modal')
        } else {
            $('body').removeClass('switch-modal')
        }
    }

    const isPostingAllowed = (): boolean => {
        const {
            isCommentsAllowed,
            polls = [],
            isPollOnly,
        } = data.roomAttendee?.room!

        const _isPostingAllowed =
            polls
                .filter((p) => !p.isImmediateResults)
                .filter((p) => p.isOpened === true).length === 0 &&
            isCommentsAllowed

        return _isPostingAllowed && !isPollOnly
    }

    const getCurrentPollsNumber = (
        polls: JoinRoom_roomAttendee_room_polls[] | undefined
    ): number => {
        if (!polls) return 0

        return polls.length
    }

    const fetchMorePostsAndPolls = (filter?: string) => {
        if (data) {
            let currentPosts = data.roomAttendee?.room.posts || []
            if (filter) {
                logger.debug(`Room fetchMorePostsAndPolls filter: ${filter} `)
                currentPosts = currentPosts.filter((p) => p.id !== filter)
            }
            const sortedPosts = [...currentPosts].sort((a, b) => {
                return moment(b.createdAt).diff(a.createdAt)
            })
            const lastPost = sortedPosts.length > 0 ? sortedPosts.pop() : null
            return fetchMore({
                variables: {
                    joinKey,
                    userId,
                    afterPost: { id: lastPost ? lastPost.id : null },
                },
            })
        }
    }
    return (
        <div className="container-main template-messages">
            <Header
                data={data}
                joinKey={joinKey}
                updateRoomAttendeeLastSeen={updateRoomAttendeeLastSeen}
            />
            {data.roomAttendee?.role! === AttendeeRole.Admin ? (
                <SettingsAdmin
                    data={data}
                    joinKey={joinKey}
                    exportRoom={() => {}}
                    deleteRoom={deleteRoom}
                    deleteRoomContent={roomDeleteContent}
                    leaveRoom={leaveRoom}
                    updateRoom={updateRoom}
                    roomInfo={roomInfo}
                />
            ) : (
                <SettingsGuest data={data} leaveRoom={leaveRoom} />
            )}

            <div className="container-content">
                <SwitchMenu
                    data={data}
                    activeMenu={activeMenu}
                    handleSortMenu={handleSortMenu}
                    isModerated={!!data.roomAttendee?.room.isModerated}
                />
                <MenuContent
                    data={data}
                    activeMenu={activeMenu}
                    joinKey={joinKey}
                    fetchMore={fetchMorePostsAndPolls}
                    roomInfo={roomInfo}
                    setRoomInfo={setRoomInfoFn}
                />

                {activeMenu !== SortMenu.MODERATE && (
                    <Input
                        createPost={createPost}
                        createPoll={createPoll}
                        isAddingPollAllowed={
                            getCurrentPollsNumber(
                                data.roomAttendee?.room!.polls!
                            ) < MAX_POLLS_BY_ROOM
                                ? true
                                : false
                        }
                        isPostingAllowed={isPostingAllowed()}
                        isCommentsAllowed={
                            data.roomAttendee?.room!.isCommentsAllowed!
                        }
                        role={data.roomAttendee?.role!}
                        isPublished={!data.roomAttendee?.room.isModerated}
                        nickName={data.roomAttendee?.nickName}
                    />
                )}
            </div>
            <div className="iphone-extra-layout"></div>
            <NicknameModal
                data={data}
                updateRoomAttendee={updateRoomAttendee}
                updateRoomAttendeeStatus={updateRoomAttendeeStatus}
            />
        </div>
    )
}

export default Room
