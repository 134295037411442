import moment from 'moment'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { $ } from 'react-jquery-plugin'
import { useHistory } from 'react-router-dom'
import { subscriptionClient } from '../../../apolloClient'
import { AppLogger } from '../../../AppLogger'
import { validateProfile } from '../../../auth'
import { Language, Theme } from '../../../generated/globalTypes'
import { UserData_user } from '../../../generated/UserData'
import {
    IMPORT_PROFILE_DURATION,
    LANDING_SHAKE_FREQ,
    LANGUAGES,
    LANGUAGE_KEY,
    SWITCH_ON,
    SWITCH_SELECTED,
    THEME_KEY,
} from '../../common/constant'
import { EventAction, EventCategory, gaEvent } from '../../common/utilis'

interface Props {
    user: UserData_user | null
    updateProfile: Function
}
const logger = AppLogger.getInstance()
const Profile: React.FC<Props> = ({ user, updateProfile }) => {
    const refInput = useRef<HTMLInputElement>(null)
    const refDiv = useRef<HTMLInputElement>(null)

    const refBtnCode = useRef<HTMLLIElement>(null)
    const themes = Object.keys(Theme)
    const languages = Object.keys(Language)

    const { i18n, t } = useTranslation(['Home', 'Themes', 'Languages'])
    const currentLanguage = i18n.language
    moment.locale(currentLanguage)

    let history = useHistory()
    const [profile, setProfile] = useState<string>('')

    const [preference, setPreference] = useState<{
        theme: Theme
        language: Language
    }>(() => {
        const theme = localStorage.getItem(THEME_KEY)
            ? (localStorage.getItem(THEME_KEY) as Theme)
            : user?.theme!
        const language = localStorage.getItem(LANGUAGE_KEY)
            ? (localStorage.getItem(LANGUAGE_KEY) as Language)
            : user?.language!

        return {
            theme,
            language,
        }
    })

    /**
     * make sure when user changes language in T&C to change it here as well
     */
    useEffect(() => {
        if (user?.language) {
            setPreference((val) => ({
                ...val,
                language: user?.language,
            }))
        }
    }, [user?.language])

    const handleChange = (language: Language, theme: Theme) => {
        setPreference({ theme, language })
        updateProfile(language, theme)
    }
    const handleSubmit = async (e: FormEvent<HTMLParagraphElement>) => {
        e.preventDefault()
        logger.debug(`profile is:${profile}`)
        if (!profile) {
            if (refInput.current) {
                refInput.current.focus()
            }
            return
        }

        try {
            const isValid = await validateProfile(profile)
            if (isValid) {
                //subscriptionClient.unsubscribeAll()
                subscriptionClient.close(true, true)
                gaEvent(EventCategory.Profile, EventAction.ChangeUser, '****')
                history.push(
                    {
                        pathname: '/',
                    },
                    {
                        screen: 'home',
                    }
                )
            } else {
                logger.warn(`Oops!!!  provided profile is not valid `)
                setTimeout(() => {
                    $('#profileInput').removeClass('animate__headShake')
                }, LANDING_SHAKE_FREQ)
                $('#profileInput').addClass('animate__headShake')
            }
        } catch (e) {
            logger.error(`Load profile handleSubmit`, e)
        }
    }

    const expandProfile = () => {
        if (refDiv && refDiv.current) {
            if (refDiv.current.classList.contains(SWITCH_SELECTED)) {
                refDiv.current.classList.remove(SWITCH_SELECTED)
                $('#importProfileForm').slideUp(IMPORT_PROFILE_DURATION)
            } else {
                refDiv.current.classList.add(SWITCH_SELECTED)
                $('#importProfileForm').slideDown(IMPORT_PROFILE_DURATION)
            }
        }
    }

    const showCurrentId = () => {
        if (refBtnCode.current) {
            if (!refBtnCode.current.classList.contains(SWITCH_ON.trim())) {
                refBtnCode.current.classList.add(SWITCH_ON.trim())
            }
        }
    }

    const hideCurrentId = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (refBtnCode.current) {
            if (refBtnCode.current.classList.contains(SWITCH_ON.trim())) {
                refBtnCode.current.classList.remove(SWITCH_ON)
            }
        }
    }

    return (
        <div className="container-information">
            <div className="entry-information">
                <h2>{t('Profile')}</h2>
                <div className="custom-select">
                    <label>{t('Language')}</label>
                    <select
                        className="select-dropdown language"
                        title={t('Profile')}
                        name="language"
                        value={preference.language}
                        onChange={(event) => {
                            // handle language change

                            const _language =
                                LANGUAGES[String(event.target.value)]

                            i18n.changeLanguage(_language)
                            gaEvent(
                                EventCategory.Profile,
                                EventAction.Language,
                                _language
                            )
                            return handleChange(
                                event.target.value as Language,
                                preference.theme
                            )
                        }}
                    >
                        {languages.map((lan) => (
                            <option
                                key={lan}
                                className="select-option"
                                value={lan}
                            >
                                {t(`Languages:${lan}`)}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="custom-select">
                    <label> {t('ThemeColor')} </label>
                    <select
                        className="select-dropdown theme"
                        title={t('ThemeColor')}
                        name="theme-color"
                        value={preference.theme}
                        onChange={(event) => {
                            gaEvent(
                                EventCategory.Profile,
                                EventAction.Theme,
                                event.target.value
                            )
                            // handle theme change
                            return handleChange(
                                preference.language,
                                event.target.value as Theme
                            )
                        }}
                    >
                        {themes.map((theme) => (
                            <option
                                key={theme}
                                className="select-option"
                                value={theme}
                            >
                                {t(`Themes:${theme}`)}
                            </option>
                        ))}
                    </select>
                </div>

                {/** New profile */}
                <div className="lobby-choices id" ref={refDiv}>
                    <div className="entry-title">
                        <h3 onClick={expandProfile}>{t('ImportID')}</h3>
                    </div>

                    <form
                        id="importProfileForm"
                        className="form entry-switch lobby-form"
                    >
                        <input
                            ref={refInput}
                            id="profileInput"
                            className="input-text animate__animated"
                            type="text"
                            placeholder={t('ImportIDPlaceholder')}
                            value={profile}
                            onChange={(e) => setProfile(e.target.value)}
                        />
                        <ul className="profile-buttons">
                            <li className="button button-submit">
                                <p onClick={handleSubmit}>{t('Load')}</p>
                            </li>
                        </ul>
                        <p className="caption">{t('ImportIDCaption')}</p>
                    </form>
                </div>

                <ul className="profile-buttons">
                    <li
                        className="button button-code button-id"
                        id="buttonCodeId"
                        onClick={showCurrentId}
                        ref={refBtnCode}
                    >
                        <p className="admin-key">{t('ProfileIdCopied')}</p>
                        <CopyToClipboard text={user?.profileId!}>
                            <p className="button-text">{t('ShowCurrentID')}</p>
                        </CopyToClipboard>
                        <div
                            className="admin-key-close"
                            onClick={hideCurrentId}
                        >
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                            >
                                <line
                                    x1="0"
                                    y1="0"
                                    x2="100"
                                    y2="100"
                                    vectorEffect="non-scaling-stroke"
                                />
                                <line
                                    x1="0"
                                    y1="100"
                                    x2="100"
                                    y2="0"
                                    vectorEffect="non-scaling-stroke"
                                />
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Profile
