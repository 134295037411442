import React from 'react'
import { useTranslation } from 'react-i18next'
import { JoinRoom } from '../../../../generated/JoinRoom'
import { SortMenu } from '../../Room'

interface Props {
    data: JoinRoom
    handleSortMenu: Function
    activeMenu: SortMenu
    isModerated: boolean
}

const SwitchMenu: React.FC<Props> = ({
    data,
    activeMenu,
    handleSortMenu,
    isModerated,
}) => {
    const { t } = useTranslation(['Room'])

    const isModerateNotification = (): boolean => {
        let isModerateNotificationFlag = false
        if (
            data &&
            data.roomAttendee &&
            data.roomAttendee.room &&
            data.roomAttendee.room.isModerated
        ) {
            const { posts } = data.roomAttendee.room
            const isAnyUnpublishedPost = posts.some((p) => !p.isPublished)
            if (isAnyUnpublishedPost) {
                isModerateNotificationFlag = true
            } else {
                posts.forEach((post) => {
                    const isAnyUnpublishedComment = post.comments.some(
                        (p) => !p.isPublished
                    )
                    if (isAnyUnpublishedComment) {
                        isModerateNotificationFlag = true
                    }
                })
            }
        }
        return isModerateNotificationFlag
    }
    return (
        <ul className="sorting">
            <li
                className={`${
                    activeMenu === SortMenu.RECENT && 'switch-selected'
                }`}
                onClick={() => handleSortMenu(SortMenu.RECENT)}
            >
                {t('Recent')}
            </li>
            <li
                className={`${
                    activeMenu === SortMenu.BEST && 'switch-selected'
                }`}
                onClick={() => handleSortMenu(SortMenu.BEST)}
            >
                {t('Best')}
            </li>
            {isModerated && (
                <li
                    className={`moderate ${
                        activeMenu === SortMenu.MODERATE && 'switch-selected'
                    }`}
                    onClick={() => handleSortMenu(SortMenu.MODERATE)}
                >
                    {t('Moderate')}
                    {isModerateNotification() && (
                        <span className="notification"></span>
                    )}
                </li>
            )}
        </ul>
    )
}

export default SwitchMenu
