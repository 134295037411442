import { useMutation } from '@apollo/client'

import { Language } from '../generated/globalTypes'
import {
    UpdateOneUser,
    UpdateOneUserVariables,
} from '../generated/UpdateOneUser'

import { USER_UPDATE } from '../queries/user'

export function useGdprNotice(userId: string) {
    /**
     * Mutations
     */
    const [updateProfile] = useMutation<UpdateOneUser, UpdateOneUserVariables>(
        USER_UPDATE
    )

    /**
     * useEffect hooks
     */
    return {
        updateProfileLanguage: (language: Language) =>
            updateProfile({
                variables: {
                    data: {
                        language: {
                            set: language,
                        },
                    },
                    where: {
                        id: userId,
                    },
                },
            }),
    }
}
