import { useMutation, useSubscription } from '@apollo/client'
import { AppLogger } from '../../../../../AppLogger'

import {
    CreatePollOptionResults,
    CreatePollOptionResultsVariables,
} from '../../../../../generated/CreatePollOptionResults'

import {
    DeleteOnePoll,
    DeleteOnePollVariables,
} from '../../../../../generated/DeleteOnePoll'

import {
    JoinRoom,
    JoinRoom_roomAttendee_room_polls,
    JoinRoom_roomAttendee_room_polls_options,
} from '../../../../../generated/JoinRoom'
import {
    PollDeleteDataChange,
    PollDeleteDataChangeVariables,
} from '../../../../../generated/PollDeleteDataChange'

import {
    PollResultsBroadcastDataChange,
    PollResultsBroadcastDataChangeVariables,
} from '../../../../../generated/PollResultsBroadcastDataChange'

import {
    PollOptionUpdateDataChange,
    PollOptionUpdateDataChangeVariables,
} from '../../../../../generated/PollOptionUpdateDataChange'

import {
    PollUpdateDataChange,
    PollUpdateDataChangeVariables,
} from '../../../../../generated/PollUpdateDataChange'
import {
    UpdateOnePoll,
    UpdateOnePollVariables,
} from '../../../../../generated/UpdateOnePoll'

import {
    UpdateOnePollOption,
    UpdateOnePollOptionVariables,
} from '../../../../../generated/UpdateOnePollOption'

import {
    POLL_UPDATE,
    POLL_UPDATE_SUBSCRIPTION,
    POLL_DELETE,
    POLL_DELETE_SUBSCRIPTION,
    POLL_OPTION_UPDATE,
    POLL_OPTION_UPDATE_SUBSCRIPTION,
    POLL_OPTION_RESULTS_CREATE,
    POLL_RESULTS_BROADCAST_SUBSCRIPTION,
    POLL_OPTIONS_UPDATE_SUBSCRIPTION,
    POLL_RESET_OPTIONS_RESULTS,
    POLL_RESET_OPTIONS_RESULTS_SUBSCRIPTION,
} from '../../../../../queries/poll'
import { ROOM_JOIN } from '../../../../../queries/room'
import { addClassByOptionId, removeClassByOptionId } from './helper'
import {
    AFTER_POST,
    FIRST_POSTS,
    ORDER_BY_POST,
    ORDER_BY_PUBLISHED_POST,
    SWITCH_SELECTED,
    SWITCH_SOLUTION,
    WHERE_POST,
} from '../../../../common/constant'
import {
    PollOptionsUpdateDataChange,
    PollOptionsUpdateDataChangeVariables,
} from '../../../../../generated/PollOptionsUpdateDataChange'
import { isSameUserSession } from '../../../../common/utilis'
import {
    PollResetOptionsResults,
    PollResetOptionsResultsVariables,
} from '../../../../../generated/PollResetOptionsResults'
import {
    PollResetOptionsResultsDataChange,
    PollResetOptionsResultsDataChangeVariables,
} from '../../../../../generated/PollResetOptionsResultsDataChange'
import { AttendeeRole } from '../../../../../generated/globalTypes'

const logger = AppLogger.getInstance()
export function usePoll(
    joinKey: string,
    userId: string,
    data: JoinRoom,
    pollId: string,
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >,
    nickName: string | null | undefined
) {
    const roleString = data.roomAttendee?.role.toString()!

    // all polls
    const polls = data?.roomAttendee?.room.polls || []
    const targetPoll = polls.find((p) => p.id === pollId)!

    /**
     * Mutations
     */

    // Poll CRUD
    const [updatePoll, updatePollStatus] =
        useMutation<UpdateOnePoll, UpdateOnePollVariables>(POLL_UPDATE)

    const [deletePoll, deletePollStatus] =
        useMutation<DeleteOnePoll, DeleteOnePollVariables>(POLL_DELETE)

    const [updatePollOption, updatePollOptionStatus] =
        useMutation<UpdateOnePollOption, UpdateOnePollOptionVariables>(
            POLL_OPTION_UPDATE
        )

    const [createPollOptionResults, createPollOptionResultsStatus] =
        useMutation<CreatePollOptionResults, CreatePollOptionResultsVariables>(
            POLL_OPTION_RESULTS_CREATE
        )

    const [pollResetOptionsResults, pollResetOptionsResultsStatus] =
        useMutation<PollResetOptionsResults, PollResetOptionsResultsVariables>(
            POLL_RESET_OPTIONS_RESULTS
        )

    const updateOptionClass = (
        poll?: JoinRoom_roomAttendee_room_polls,
        pollOptions?: JoinRoom_roomAttendee_room_polls_options[]
    ) => {
        const _poll = poll ? poll : polls.find((p) => p.id === pollId)
        const _pollOptions = pollOptions ? pollOptions : _poll?.options

        if (_poll && _pollOptions) {
            const { isSolutions } = _poll
            for (const option of _pollOptions) {
                if (isSolutions) {
                    if (option.isChecked) {
                        addClassByOptionId(
                            option.id,
                            classListMap,
                            SWITCH_SOLUTION
                        )
                    } else {
                        removeClassByOptionId(
                            option.id,
                            classListMap,
                            SWITCH_SOLUTION
                        )
                    }
                }
            }
        }
    }

    /**
     * Subscriptions
     */

    /**
    |---------------------------------------------------------
    | Triggered on poll update
    | onSubscriptionData: 
    | 1 - update the current joined room by modifying the existing poll 
    | 2 - editable fields are: isOpened,isResults 
    |---------------------------------------------------------
    */

    useSubscription<PollUpdateDataChange, PollUpdateDataChangeVariables>(
        POLL_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POLL_UPDATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POLL_UPDATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on poll delete
    | onSubscriptionData: 
    | 1 - update the current joined room by removing from apollo cache the deleted poll
    |---------------------------------------------------------
    */
    useSubscription<PollDeleteDataChange, PollDeleteDataChangeVariables>(
        POLL_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POLL_DELETE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POLL_DELETE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const deletedPoll = subscriptionData.data?.onPollDelete
                if (
                    deletedPoll &&
                    deletedPoll.id === targetPoll.id &&
                    polls.find((p) => p.id === deletedPoll.id)
                ) {
                    const updatedPolls = polls.filter(
                        (p) => p.id !== deletedPoll.id
                    )
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    polls: updatedPolls,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            operation: `DELETE_POLL_${deletedPoll.id}`,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on Poll Results Broadcast
    | onSubscriptionData: 
    | 1 - query the poll and refresh the cache 
    | 
    |---------------------------------------------------------
    */
    useSubscription<
        PollResultsBroadcastDataChange,
        PollResultsBroadcastDataChangeVariables
    >(POLL_RESULTS_BROADCAST_SUBSCRIPTION, {
        variables: {
            userId,
            role: roleString,
            filter: {
                id: {
                    equals: userId,
                },
            },
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on poll option update
    | onSubscriptionData: 
    | 1 - update the current joined room by modifying the existing poll option
    | 2 - editable field: isChecked 
    |---------------------------------------------------------
    */
    useSubscription<
        PollOptionUpdateDataChange,
        PollOptionUpdateDataChangeVariables
    >(POLL_OPTION_UPDATE_SUBSCRIPTION, {
        variables: {
            userId,
        },

        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(`POLL_OPTION_UPDATE_SUBSCRIPTION client:`, client)
            logger.debug(
                `POLL_OPTION_UPDATE_SUBSCRIPTION subscriptionData:`,
                subscriptionData
            )
            const updatedPollOption = subscriptionData.data?.onPollOptionUpdate

            if (
                updatedPollOption &&
                targetPoll.options.find((o) => o.id === updatedPollOption.id) &&
                !isSameUserSession(updatedPollOption.modifiedBy)
            ) {
                const updatedPollOptions = targetPoll.options.map((o) => {
                    if (o.id === updatedPollOption.id) {
                        return { ...o, ...updatedPollOption }
                    } else {
                        if (targetPoll.isMultipleResponses) {
                            return o
                        } else {
                            return {
                                ...o,
                                isChecked: false,
                            }
                        }
                    }
                }) as JoinRoom_roomAttendee_room_polls_options[]

                updateOptionClass(targetPoll, updatedPollOptions)

                const updatedPolls = polls.map((p) => {
                    if (p.id === targetPoll.id) {
                        return {
                            ...targetPoll,
                            options: updatedPollOptions,
                        }
                    } else {
                        return p
                    }
                })

                client.writeQuery({
                    query: ROOM_JOIN,
                    data: {
                        roomAttendee: {
                            ...data?.roomAttendee,
                            room: {
                                ...data?.roomAttendee?.room,
                                polls: updatedPolls,
                            },
                        },
                    },
                    variables: {
                        joinKey,
                        nickName,
                        firstPost: FIRST_POSTS,
                        orderByPost: ORDER_BY_POST,
                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                        afterPost: AFTER_POST,
                        wherePost: WHERE_POST,

                        filterPollRes: {
                            userId: {
                                equals: userId,
                            },
                        },
                    },
                })
            }
        },
    })

    /**
     * when admin updates answer in mono solution polls (from option (A) to option (B) )
     */
    useSubscription<
        PollOptionsUpdateDataChange,
        PollOptionsUpdateDataChangeVariables
    >(POLL_OPTIONS_UPDATE_SUBSCRIPTION, {
        variables: {
            userId,
        },

        onSubscriptionData: ({ client, subscriptionData }) => {
            const updatedPollOption = subscriptionData.data?.onPollOptionsUpdate

            if (
                updatedPollOption &&
                targetPoll.options.find(
                    (o) =>
                        o.id === updatedPollOption.id &&
                        !isSameUserSession(updatedPollOption.modifiedBy)
                )
            ) {
                logger.debug(
                    `POLL_OPTIONS_UPDATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const updatedPollOptions = targetPoll.options.map((o) => {
                    if (o.id === updatedPollOption.id) {
                        return { ...o, ...updatedPollOption }
                    } else {
                        if (targetPoll.isMultipleResponses) {
                            return o
                        } else {
                            return {
                                ...o,
                                isChecked: false,
                            }
                        }
                    }
                }) as JoinRoom_roomAttendee_room_polls_options[]

                const updatedPolls = polls.map((p) => {
                    if (p.id === targetPoll.id) {
                        return {
                            ...targetPoll,
                            options: updatedPollOptions,
                        }
                    } else {
                        return p
                    }
                })

                updateOptionClass(targetPoll, updatedPollOptions)

                client.writeQuery({
                    query: ROOM_JOIN,
                    data: {
                        roomAttendee: {
                            ...data?.roomAttendee,
                            room: {
                                ...data?.roomAttendee?.room,
                                polls: updatedPolls,
                            },
                        },
                    },
                    variables: {
                        joinKey,
                        nickName,
                        firstPost: FIRST_POSTS,
                        orderByPost: ORDER_BY_POST,
                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                        afterPost: AFTER_POST,
                        wherePost: WHERE_POST,

                        filterPollRes: {
                            userId: {
                                equals: userId,
                            },
                        },
                    },
                })
            } else {
                logger.debug(
                    `POLL_OPTIONS_UPDATE_SUBSCRIPTION don't do anything , not the target poll or the same user session`
                )
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on poll reset options results 
    | onSubscriptionData: 
    | 1 - update the current joined room by modifying the existing poll options results
    |---------------------------------------------------------
    */
    useSubscription<
        PollResetOptionsResultsDataChange,
        PollResetOptionsResultsDataChangeVariables
    >(POLL_RESET_OPTIONS_RESULTS_SUBSCRIPTION, {
        variables: {
            userId,
            role: roleString,
            filter: {
                id: {
                    equals: userId,
                },
            },
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(
                `POLL_RESET_OPTIONS_RESULTS_SUBSCRIPTION role:${data.roomAttendee?.role}`
            )
            if (
                subscriptionData.data &&
                subscriptionData.data.onPollResetOptionsResults &&
                data.roomAttendee?.role === AttendeeRole.Guest
            ) {
                const pollOptions =
                    subscriptionData.data.onPollResetOptionsResults.options
                pollOptions.forEach((pollOption) => {
                    removeClassByOptionId(
                        pollOption.id,
                        classListMap,
                        SWITCH_SELECTED
                    )

                    removeClassByOptionId(
                        pollOption.id,
                        classListMap,
                        SWITCH_SOLUTION
                    )
                })

                const updatedPolls = polls.map((p) => {
                    if (p.id === pollId) {
                        return {
                            ...subscriptionData.data?.onPollResetOptionsResults,
                        }
                    }
                    return p
                })

                client.writeQuery({
                    query: ROOM_JOIN,
                    data: {
                        roomAttendee: {
                            ...data?.roomAttendee,
                            room: {
                                ...data?.roomAttendee?.room,
                                polls: [...updatedPolls],
                            },
                        },
                    },
                    variables: {
                        joinKey,
                        nickName,
                        firstPost: FIRST_POSTS,
                        orderByPost: ORDER_BY_POST,
                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                        afterPost: null,
                        wherePost: WHERE_POST,
                        filterPollRes: {
                            userId: {
                                equals: userId,
                            },
                        },
                    },
                })
            }
        },
    })

    /**
     * useEffect hooks
     */

    return {
        deletePollStatus,
        deletePoll: async () =>
            await deletePoll({
                variables: {
                    where: {
                        id: pollId,
                    },
                },
                update(cache, { data: deletedPollRes }) {
                    if (deletedPollRes) {
                        const deletedPoll = deletedPollRes.poll
                        if (data && deletedPoll) {
                            const myPolls = data.roomAttendee?.room.polls || []
                            const polls = [
                                ...myPolls.filter((p) => p.id !== pollId),
                            ]
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls,
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    operation: `DELETE_POLL_${deletedPoll.id}`,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),

        pollResetOptionsResultsStatus,

        pollResetOptionsResults: async () =>
            await pollResetOptionsResults({
                variables: {
                    pollId,
                },
            }),

        updatePollStatus,
        updatePoll: async (isOpened: boolean, isResults: boolean) =>
            await updatePoll({
                variables: {
                    where: {
                        id: pollId,
                    },
                    data: {
                        isOpened: {
                            set: isOpened,
                        },
                        isResults: {
                            set: isResults,
                        },
                    },
                },
                update(cache, { data: updatedPollRes }) {
                    if (updatedPollRes) {
                        const updatedPoll = updatedPollRes.poll
                        if (data && updatedPoll) {
                            const myPolls = data.roomAttendee?.room.polls || []
                            const targetPoll = myPolls.find(
                                (p) => p.id === updatedPoll.id
                            )
                            const _updatedPoll = {
                                ...targetPoll,
                                ...updatedPoll,
                            }
                            const polls = [
                                ...myPolls.filter((p) => p.id !== pollId),
                                {
                                    ..._updatedPoll,
                                    options: _updatedPoll.options.map((o) => ({
                                        ...o,
                                        results: [],
                                    })),
                                },
                            ]
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls,
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),
        updatePollOptionStatus,
        updatePollOption: async (pollOptionId: string, isChecked: boolean) =>
            await updatePollOption({
                variables: {
                    where: {
                        id: pollOptionId,
                    },
                    data: {
                        isChecked: {
                            set: isChecked,
                        },
                    },
                },
            }),

        createPollOptionResultsStatus,
        createPollOptionResults: async (
            pollId: string,
            isImmediateResultsBroadcast: boolean,
            optionResults: string[]
        ) =>
            await createPollOptionResults({
                variables: {
                    pollId,
                    isImmediateResultsBroadcast,
                    optionResults,
                },
            }),
    }
}
