import { gql } from '@apollo/client'
import {
    pollDetailFragment,
    pollOptionDetailFragment,
    pollOptionResultDetailFragment,
    pollResultDetailFragment,
} from './fragments'

/**
 * QUERIES
 */

/**
 * MUTATIONS
 */

export const POLL_CREATE = gql`
    mutation CreateOnePoll($data: PollCreateInput!) {
        poll: createOnePoll(data: $data) {
            ...PollDetail
        }
    }
    ${pollDetailFragment}
`

export const POLL_DELETE = gql`
    mutation DeleteOnePoll($where: PollWhereUniqueInput!) {
        poll: deleteOnePoll(where: $where) {
            id
        }
    }
`

export const POLL_UPDATE = gql`
    mutation UpdateOnePoll(
        $data: PollUpdateInput!
        $where: PollWhereUniqueInput!
    ) {
        poll: updateOnePoll(data: $data, where: $where) {
            ...PollDetail
        }
    }
    ${pollDetailFragment}
`

export const POLL_OPTION_UPDATE = gql`
    mutation UpdateOnePollOption(
        $data: PollOptionUpdateInput!
        $where: PollOptionWhereUniqueInput!
    ) {
        pollOption: updateOnePollOption(data: $data, where: $where) {
            ...PollOptionDetail
        }
    }
    ${pollOptionDetailFragment}
`

export const POLL_OPTIONS_UPDATE = gql`
    mutation UpdatePollOptions(
        $pollId: ID!
        $checkedOpId: ID!
        $modifiedBy: String
    ) {
        pollOption: updatePollOptions(
            pollId: $pollId
            checkedOpId: $checkedOpId
            modifiedBy: $modifiedBy
        ) {
            ...PollOptionDetail
        }
    }
    ${pollOptionDetailFragment}
`

export const POLL_OPTION_RESULTS_CREATE = gql`
    mutation CreatePollOptionResults(
        $pollId: ID!
        $isImmediateResultsBroadcast: Boolean!
        $optionResults: [ID]
    ) {
        pollOptionResults: createPollOptionResults(
            pollId: $pollId
            isImmediateResultsBroadcast: $isImmediateResultsBroadcast
            optionResults: $optionResults
        ) {
            count
        }
    }
`

export const POLL_OPTION_RESULT_DELETE = gql`
    mutation DeleteOnePollOptionResult(
        $where: PollOptionResultWhereUniqueInput!
    ) {
        pollOptionResult: deleteOnePollOptionResult(where: $where) {
            id
        }
    }
`

export const POLL_OPTION_RESULT_UPDATE = gql`
    mutation UpdateOnePollOptionResult(
        $data: PollOptionResultUpdateInput!
        $where: PollOptionResultWhereUniqueInput!
    ) {
        pollOptionResult: updateOnePollOptionResult(
            data: $data
            where: $where
        ) {
            ...PollOptionResultDetail
        }
    }
    ${pollOptionResultDetailFragment}
`

export const POLL_OPTIONS_RESULT_UPDATE = gql`
    mutation UpdatePollOptionResults(
        $pollId: ID!
        $isImmediateResultsBroadcast: Boolean!
        $checkedOpId: ID!
    ) {
        pollOptionResult: updatePollOptionsResult(
            pollId: $pollId
            isImmediateResultsBroadcast: $isImmediateResultsBroadcast
            checkedOpId: $checkedOpId
        ) {
            ...PollOptionResultDetail
        }
    }
    ${pollOptionResultDetailFragment}
`

/** reset poll results only admin  */
export const POLL_RESET_OPTIONS_RESULTS = gql`
    mutation PollResetOptionsResults($pollId: ID!) {
        poll: resetPollOptionsResults(pollId: $pollId) {
            count
        }
    }
`
/**
 * SUBSCRIPTIONS
 */
export const POLL_CREATE_SUBSCRIPTION = gql`
    subscription PollCreateDataChange($userId: ID!) {
        onPollCreate(userId: $userId) {
            ...PollDetail
        }
    }
    ${pollDetailFragment}
`

export const POLL_UPDATE_SUBSCRIPTION = gql`
    subscription PollUpdateDataChange($userId: ID!) {
        onPollUpdate(userId: $userId) {
            ...PollDetail
        }
    }
    ${pollDetailFragment}
`

export const POLL_DELETE_SUBSCRIPTION = gql`
    subscription PollDeleteDataChange($userId: ID!) {
        onPollDelete(userId: $userId) {
            id
        }
    }
`

export const POLL_RESULTS_BROADCAST_SUBSCRIPTION = gql`
    subscription PollResultsBroadcastDataChange(
        $userId: ID!
        $role: String!
        $filter: PollOptionResultWhereInput!
    ) {
        onPollResultsBroadcast(userId: $userId, role: $role, filter: $filter) {
            ...PollResultsDetail
        }
    }
    ${pollResultDetailFragment}
`

export const POLL_OPTION_UPDATE_SUBSCRIPTION = gql`
    subscription PollOptionUpdateDataChange($userId: ID!) {
        onPollOptionUpdate(userId: $userId) {
            ...PollOptionDetail
        }
    }
    ${pollOptionDetailFragment}
`

export const POLL_OPTIONS_UPDATE_SUBSCRIPTION = gql`
    subscription PollOptionsUpdateDataChange($userId: ID!) {
        onPollOptionsUpdate(userId: $userId) {
            ...PollOptionDetail
        }
    }
    ${pollOptionDetailFragment}
`

export const POLL_OPTION_RESULT_CREATE_SUBSCRIPTION = gql`
    subscription PollOptionResultCreateDataChange($userId: ID!) {
        onPollOptionResultCreate(userId: $userId) {
            ...PollOptionResultDetail
        }
    }
    ${pollOptionResultDetailFragment}
`

export const POLL_OPTION_RESULT_UPDATE_SUBSCRIPTION = gql`
    subscription PollOptionResultUpdateDataChange($userId: ID!) {
        onPollOptionResultUpdate(userId: $userId) {
            ...PollOptionResultDetail
        }
    }
    ${pollOptionResultDetailFragment}
`

export const POLL_OPTIONS_RESULT_UPDATE_SUBSCRIPTION = gql`
    subscription PollOptionsResultUpdateDataChange($userId: ID!) {
        onPollOptionsResultUpdate(userId: $userId) {
            ...PollOptionResultDetail
        }
    }
    ${pollOptionResultDetailFragment}
`

export const POLL_OPTION_RESULT_DELETE_SUBSCRIPTION = gql`
    subscription PollOptionResultDeleteDataChange($userId: ID!) {
        onPollOptionResultDelete(userId: $userId) {
            id
        }
    }
`

export const POLL_RESET_OPTIONS_RESULTS_SUBSCRIPTION = gql`
    subscription PollResetOptionsResultsDataChange(
        $userId: ID!
        $role: String!
        $filter: PollOptionResultWhereInput!
    ) {
        onPollResetOptionsResults(
            userId: $userId
            role: $role
            filter: $filter
        ) {
            ...PollResultsDetail
        }
    }
    ${pollResultDetailFragment}
`
