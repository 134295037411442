import { gql } from '@apollo/client'
import {
    roomAttendeeDetailLobbyFragment,
    roomFullDetailFragment,
} from './fragments'

/**
 * QUERIES
 */

export const ROOM_JOIN = gql`
    query JoinRoom(
        $joinKey: String!
        $nickName: String
        $firstPost: Int!
        $orderByPost: PostOrderByWithRelationInput!
        $orderByPublishedPost: PostOrderByWithRelationInput!
        $afterPost: PostWhereUniqueInput
        $wherePost: PostWhereInput
        $operation: String
        $filterPollRes: PollOptionResultWhereInput!
    ) {
        roomAttendee: joinRoom(
            joinKey: $joinKey
            nickName: $nickName
            firstPost: $firstPost
            orderByPost: $orderByPost
            orderByPublishedPost: $orderByPublishedPost
            afterPost: $afterPost
            wherePost: $wherePost
            operation: $operation
            filterPollRes: $filterPollRes
        ) {
            id
            role
            createdAt
            lastSeenAt
            joinKey
            nickName
            room {
                ...RoomDetail
            }
        }
    }
    ${roomFullDetailFragment}
`

export const ROOM_ATTENDEE_UPDATE = gql`
    mutation UpdateOneRoomAttendee(
        $data: RoomAttendeeUpdateInput!
        $where: RoomAttendeeWhereUniqueInput!
    ) {
        roomAttendee: updateOneRoomAttendee(data: $data, where: $where) {
            id
            role
            joinKey
            createdAt
            lastSeenAt
            nickName
        }
    }
`

export const ROOM_ATTENDEE_UPDATE_LAST_SEEN = gql`
    mutation UpdateRoomAttendeeLastSeen(
        $userId: ID!
        $roomId: ID!
        $role: String!
    ) {
        roomAttendee: updateRoomAttendeeLastSeen(
            userId: $userId
            roomId: $roomId
            role: $role
        ) {
            id
            role
            joinKey
            createdAt
            lastSeenAt
            nickName
        }
    }
`

/**
 * MUTATIONS
 */

export const ROOM_CREATE = gql`
    mutation CreateOneRoom($data: RoomCreateInput!) {
        room: createOneRoom(data: $data) {
            id
            name
            num
            adminKey
            isTemporary
            isNicknames
            isModerated
            isPollOnly
            isCommentsAllowed
            isAutomaticFlow
            createdAt
            updatedAt
            createdBy {
                id
            }
        }
    }
`
export const ROOM_DELETE = gql`
    mutation DeleteOneRoom($where: RoomWhereUniqueInput!) {
        room: deleteOneRoom(where: $where) {
            id
        }
    }
`

export const ROOM_LEAVE = gql`
    mutation DeleteOneRoomAttendee($where: RoomAttendeeWhereUniqueInput!) {
        roomAttendee: deleteOneRoomAttendee(where: $where) {
            role
        }
    }
`

export const ROOM_UPDATE = gql`
    mutation UpdateOneRoom(
        $data: RoomUpdateInput!
        $where: RoomWhereUniqueInput!
    ) {
        joinRoom: updateOneRoom(data: $data, where: $where) {
            id
            name
            num
            adminKey
            isTemporary
            isNicknames
            isModerated
            isPollOnly
            isCommentsAllowed
            isAutomaticFlow
            createdAt
            updatedAt
            createdBy {
                id
            }
        }
    }
`

export const ROOM_DELETE_CONTENT = gql`
    mutation RoomDeleteContent($roomId: ID!) {
        room: roomDeleteContent(roomId: $roomId) {
            polls
            posts
        }
    }
`

export const ROOM_DELETE_POLLS = gql`
    mutation RoomDeletePolls($roomId: String!) {
        room: deleteManyPoll(where: { roomId: { equals: $roomId } }) {
            count
        }
    }
`

export const ROOM_DELETE_POSTS = gql`
    mutation RoomDeletePosts($roomId: String!) {
        room: deleteManyPost(where: { roomId: { equals: $roomId } }) {
            count
        }
    }
`

/**
 * SUBSCRIPTIONS
 */

export const ROOM_CREATE_SUBSCRIPTION = gql`
    subscription RoomCreateDataChange($userId: ID!) {
        onRoomCreate(userId: $userId) {
            ...RoomAttendeeDetail
        }
    }
    ${roomAttendeeDetailLobbyFragment}
`

export const ROOM_UPDATE_SUBSCRIPTION = gql`
    subscription RoomUpdateDataChange($userId: ID!) {
        onRoomUpdate(userId: $userId) {
            id
            name
            num
            adminKey
            isTemporary
            isNicknames
            isModerated
            isPollOnly
            isCommentsAllowed
            isAutomaticFlow
            createdAt
            updatedAt
            createdBy {
                id
            }
        }
    }
`

export const ROOM_JOIN_SUBSCRIPTION = gql`
    subscription RoomJoinDataChange($userId: ID!) {
        onRoomJoin(userId: $userId) {
            ...RoomAttendeeDetail
        }
    }
    ${roomAttendeeDetailLobbyFragment}
`

export const ROOM_LEAVE_SUBSCRIPTION = gql`
    subscription RoomLeaveDataChange($userId: ID!) {
        onRoomLeave(userId: $userId) {
            id
        }
    }
`

export const ROOM_DELETE_SUBSCRIPTION = gql`
    subscription RoomDeleteDataChange($userId: ID!) {
        onRoomDelete(userId: $userId) {
            id
        }
    }
`

export const ROOM_DELETE_CONTENT_SUBSCRIPTION = gql`
    subscription RoomDeleteContentDataChange($userId: ID!) {
        onRoomDeleteContent(userId: $userId) {
            roomId
        }
    }
`

export const ROOM_DELETE_POLLS_SUBSCRIPTION = gql`
    subscription RoomDeletePollsDataChange($userId: ID!) {
        onRoomDeletePolls(userId: $userId) {
            roomId
        }
    }
`

export const ROOM_DELETE_POSTS_SUBSCRIPTION = gql`
    subscription RoomDeletePostsDataChange($userId: ID!) {
        onRoomDeletePosts(userId: $userId) {
            roomId
        }
    }
`
