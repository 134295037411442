import { useMutation, useSubscription } from '@apollo/client'
import { AppLogger } from '../../../../../AppLogger'
import {
    CommentCreateDataChange,
    CommentCreateDataChangeVariables,
} from '../../../../../generated/CommentCreateDataChange'
import {
    CreateOneComment,
    CreateOneCommentVariables,
} from '../../../../../generated/CreateOneComment'

import {
    DeleteOnePost,
    DeleteOnePostVariables,
} from '../../../../../generated/DeleteOnePost'
import { AttendeeRole } from '../../../../../generated/globalTypes'

import { JoinRoom } from '../../../../../generated/JoinRoom'

import {
    PostDeleteDataChange,
    PostDeleteDataChangeVariables,
} from '../../../../../generated/PostDeleteDataChange'

import {
    PostUpdateDataChange,
    PostUpdateDataChangeVariables,
} from '../../../../../generated/PostUpdateDataChange'
import {
    UpdateOnePost,
    UpdateOnePostVariables,
} from '../../../../../generated/UpdateOnePost'

import {
    COMMENT_CREATE,
    COMMENT_CREATE_SUBSCRIPTION,
} from '../../../../../queries/comment'
import {
    POST_UPDATE,
    POST_DELETE,
    POST_DELETE_SUBSCRIPTION,
    POST_UPDATE_SUBSCRIPTION,
} from '../../../../../queries/post'
import { ROOM_JOIN } from '../../../../../queries/room'
import {
    AFTER_POST,
    FIRST_POSTS,
    ORDER_BY_POST,
    ORDER_BY_PUBLISHED_POST,
    SESSION_KEY,
    WHERE_POST,
} from '../../../../common/constant'
import {
    isSameUserSession,
    shouldLoadMorePosts,
    updateRoomInfo,
} from '../../../../common/utilis'

const logger = AppLogger.getInstance()
export function usePost(
    joinKey: string,
    userId: string,
    data: JoinRoom,
    postId: string,
    nickName: string | null | undefined,
    allVotesRef: React.RefObject<HTMLDivElement>,
    computedVotesRef: React.RefObject<HTMLDivElement>,
    getVotesText: (count: number) => string,
    fetchMore: (filter?: string) => void,
    setRoomInfo: Function,
    role: AttendeeRole
) {
    /**
     * Mutations
     */

    // Post CRUD
    const [updatePost, updatePostStatus] = useMutation<
        UpdateOnePost,
        UpdateOnePostVariables
    >(POST_UPDATE)

    const [deletePost, deletePostStatus] = useMutation<
        DeleteOnePost,
        DeleteOnePostVariables
    >(POST_DELETE)

    const [createComment, createCommentStatus] = useMutation<
        CreateOneComment,
        CreateOneCommentVariables
    >(COMMENT_CREATE)

    /**
     * Subscriptions
     */

    /**
    |---------------------------------------------------------
    | Triggered on post update
    | onSubscriptionData: 
    | 1 - update the current joined room by modifying the existing post 
    | 2 - editable fields are: isPublished,isFavorite 
    |---------------------------------------------------------
    */
    useSubscription<PostUpdateDataChange, PostUpdateDataChangeVariables>(
        POST_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                const updatedPost = subscriptionData.data?.onPostUpdate
                if (
                    updatedPost &&
                    updatedPost.id === postId &&
                    !isSameUserSession(updatedPost.modifiedBy)
                ) {
                    logger.debug(
                        `POST_UPDATE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const targetPost = data?.roomAttendee?.room.posts!.find(
                        (p) => p.id === updatedPost.id
                    )!
                    const posts = data?.roomAttendee?.room.posts!.filter(
                        (p) => p.id !== updatedPost.id
                    )
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    posts: [
                                        ...posts!,
                                        { ...targetPost, ...updatedPost },
                                    ],
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `POST_UPDATE_SUBSCRIPTION don't update not the target post`
                    )
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on post delete
    | onSubscriptionData: 
    | 1 - update the current joined room by removing from apollo cache the deleted post
    |---------------------------------------------------------
    */
    useSubscription<PostDeleteDataChange, PostDeleteDataChangeVariables>(
        POST_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const deletedPost = subscriptionData.data?.onPostDelete!
                const myPosts = data?.roomAttendee?.room.posts || []
                if (
                    deletedPost &&
                    deletedPost.id === postId &&
                    myPosts.find((p) => p.id === deletedPost.id)
                ) {
                    logger.debug(
                        `POST_DELETE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const posts = data?.roomAttendee?.room.posts!.filter(
                        (p) => p.id !== deletedPost.id
                    )
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    posts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            operation: `DELETE_POST_${deletedPost.id}`,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                    await shouldLoadMorePosts(
                        data,
                        posts!,
                        deletedPost.id,
                        fetchMore
                    )
                    logger.debug(
                        `POST_DELETE_SUBSCRIPTION :writeQuery postId:${deletedPost.id}`
                    )
                } else {
                    logger.debug(
                        `POST_DELETE_SUBSCRIPTION post already deleted or not the target post `
                    )
                    if (role === AttendeeRole.Admin) {
                        await updateRoomInfo(data, setRoomInfo)
                    }
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on comment add
    | onSubscriptionData: 
    | 1 - update the current joined room by adding to apollo cache the added comment to post comments
    |---------------------------------------------------------
    */
    useSubscription<CommentCreateDataChange, CommentCreateDataChangeVariables>(
        COMMENT_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const addedComment = subscriptionData.data?.onCommentCreate!
                const myPostComments =
                    data?.roomAttendee?.room.posts.find((p) => p.id === postId)
                        ?.comments || []

                /**
                 * Add the newly created comment only if the comment is not exist and it belongs to the same post
                 */
                if (
                    addedComment.post.id === postId &&
                    !myPostComments.find((c) => c.id === addedComment.id)
                ) {
                    logger.debug(
                        `COMMENT_CREATE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const updatedPostComments = [
                        ...myPostComments,
                        {
                            ...addedComment,
                            reactions: [],
                        },
                    ]

                    const updatedPosts = data?.roomAttendee?.room.posts.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    comments: updatedPostComments,
                                }
                            }
                            return p
                        }
                    )

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `COMMENT_CREATE_SUBSCRIPTION  comment already exist or not belong to this post`
                    )
                    if (role === AttendeeRole.Admin) {
                        await updateRoomInfo(data, setRoomInfo)
                    }
                }
            },
        }
    )

    /**
     * useEffect hooks
     */

    return {
        updatePostStatus,
        favoritePost: async (isFavorite: boolean) =>
            await updatePost({
                variables: {
                    data: {
                        isFavorite: {
                            set: isFavorite,
                        },
                        modifiedBy: {
                            set: sessionStorage.getItem(SESSION_KEY),
                        },
                    },
                    where: {
                        id: postId,
                    },
                },
                update: (cache, { data: favoritePostRes, errors }) => {
                    if (favoritePostRes && !errors) {
                        const favPost = favoritePostRes.post
                        if (favPost) {
                            const targetPost = data?.roomAttendee?.room.posts!.find(
                                (p) => p.id === favPost.id
                            )!
                            const posts = data?.roomAttendee?.room.posts!.filter(
                                (p) => p.id !== favPost.id
                            )
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            posts: [
                                                ...posts!,
                                                {
                                                    ...targetPost,
                                                    ...favPost,
                                                },
                                            ],
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                    afterPost: AFTER_POST,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),

        publishPost: async () =>
            await updatePost({
                variables: {
                    data: {
                        isPublished: {
                            set: true,
                        },
                        modifiedBy: {
                            set: sessionStorage.getItem(SESSION_KEY),
                        },
                    },
                    where: {
                        id: postId,
                    },
                },
                update: (cache, { data: publishedPostRes, errors }) => {
                    if (publishedPostRes && !errors) {
                        const publishedPost = publishedPostRes.post
                        if (publishedPost) {
                            const targetPost = data?.roomAttendee?.room.posts!.find(
                                (p) => p.id === publishedPost.id
                            )!
                            const posts = data?.roomAttendee?.room.posts!.filter(
                                (p) => p.id !== publishedPost.id
                            )
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            posts: [
                                                ...posts!,
                                                {
                                                    ...targetPost,
                                                    ...publishedPost,
                                                },
                                            ],
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                    afterPost: AFTER_POST,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),
        deletePostStatus,
        deletePost: async () =>
            await deletePost({
                variables: {
                    where: {
                        id: postId,
                    },
                },
                update: async (cache, { data: deletePostRes }) => {
                    if (deletePostRes) {
                        const deletedPost = deletePostRes.post
                        const myPosts = data?.roomAttendee?.room.posts || []

                        if (data && deletedPost) {
                            const posts = myPosts.filter(
                                (p) => p.id !== deletedPost.id
                            )

                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            posts,
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                    afterPost: AFTER_POST,
                                    wherePost: WHERE_POST,
                                    operation: `DELETE_POST_${deletedPost.id}`,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                            // when user deletes a post make sure to check if we should load more posts
                            await shouldLoadMorePosts(
                                data,
                                posts!,
                                deletedPost.id,
                                fetchMore
                            )
                        }
                    }
                },
            }),

        createCommentStatus,
        createComment: async (content: string, isPublished: boolean) =>
            await createComment({
                variables: {
                    data: {
                        content: content,
                        nickName: nickName || '',
                        isPublished,
                        post: {
                            connect: {
                                id: postId,
                            },
                        },
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                    },
                },
                update: (cache, { data: addedCommentRes }) => {
                    if (addedCommentRes) {
                        const addedComment = addedCommentRes.comment
                        if (data && addedComment) {
                            logger.debug(
                                `createComment add newly created comment to the cache`
                            )
                            const postComments = [
                                ...data?.roomAttendee?.room.posts.find(
                                    (p) => p.id === postId
                                )?.comments!,
                                {
                                    ...addedComment,
                                    reactions: [],
                                },
                            ]
                            const updatedPosts = data?.roomAttendee?.room.posts.map(
                                (p) => {
                                    if (p.id === postId) {
                                        return {
                                            ...p,
                                            comments: postComments,
                                        }
                                    }
                                    return p
                                }
                            )
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            posts: updatedPosts,
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                    afterPost: AFTER_POST,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),
    }
}
