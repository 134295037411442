import { gql } from '@apollo/client'
import {
    userDetailFragment,
    roomAttendeeDetailLobbyFragment,
} from './fragments'

/**
 * QUERIES
 */

/**
 * Get the user
 */
export const USER_QUERY = gql`
    query UserData($id: String) {
        user(where: { id: $id }) {
            ...UserDetail
            joinedRooms {
                ...RoomAttendeeDetail
            }
        }
    }
    ${userDetailFragment}
    ${roomAttendeeDetailLobbyFragment}
`

/**
 * MUTATIONS
 */

export const USER_UPDATE = gql`
    mutation UpdateOneUser(
        $data: UserUpdateInput!
        $where: UserWhereUniqueInput!
    ) {
        user: updateOneUser(data: $data, where: $where) {
            ...UserDetail
            joinedRooms {
                ...RoomAttendeeDetail
            }
        }
    }
    ${userDetailFragment}
    ${roomAttendeeDetailLobbyFragment}
`

/**
 * SUBSCRIPTIONS
 */

export const USER_UPDATE_SUBSCRIPTION = gql`
    subscription UserUpdateDataChange($userId: ID!) {
        onUserUpdate(userId: $userId) {
            ...UserDetail
        }
    }
    ${userDetailFragment}
`
