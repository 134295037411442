import QRCodeGen from 'react-qr-code'

import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { $ } from 'react-jquery-plugin'
import { SWITCH_ON, THEME_KEY } from '../../common/constant'

interface Props {
    roomKey: string
}

const generateRoomUrl = (roomKey: string) => {
    const base_url = window.location.origin

    // const currentTheme = localStorage.getItem(THEME_KEY)
    // const url = `${base_url}/room/join/${roomKey}?theme=${currentTheme}`
    const url = `${base_url}/room/join/${roomKey}`
    return url
}

const QRCode: React.FC<Props> = ({ roomKey }) => {
    const { t } = useTranslation(['Room'])
    const url = generateRoomUrl(roomKey)
    const qrCodeRef = useRef<HTMLLIElement>(null)

    const toggleQRContainer = () => {
        if (qrCodeRef && qrCodeRef.current) {
            if (qrCodeRef.current.classList.contains(SWITCH_ON)) {
                qrCodeRef.current.classList.remove(SWITCH_ON)
                $('.qrcode').slideUp(800)
            } else {
                qrCodeRef.current.classList.add(SWITCH_ON)
                $('.qrcode').slideDown(800)
            }
        }
    }

    const downloadQrCode = () => {
        const svg = document.getElementById('QRCode')
        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg)
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            if (ctx) {
                const img = new Image()
                img.onload = () => {
                    canvas.width = img.width
                    canvas.height = img.height
                    ctx.drawImage(img, 0, 0)
                    const pngFile = canvas.toDataURL('image/png')
                    const downloadLink = document.createElement('a')
                    downloadLink.download = `QRCode_${roomKey}`
                    downloadLink.href = `${pngFile}`
                    downloadLink.click()
                }
                img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
            }
        }
    }

    return (
        <li
            className="button button-qrcode"
            ref={qrCodeRef}
            onClick={toggleQRContainer}
        >
            <div className="entry-title">
                <h3>{t('GetQRCode')}</h3>
            </div>
            <div className="qrcode" style={{ display: 'none', height: '100%' }}>
                <QRCodeGen
                    id="QRCode"
                    value={url}
                    style={{ position: 'absolute', zIndex: -100 }}
                />
                <QRCodeGen
                    onClick={downloadQrCode}
                    value={url}
                    size={256}
                    style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                        padding: 10,
                        zIndex: 2,
                    }}
                    viewBox={`0 0 256 256`}
                />
            </div>
        </li>
    )
}

export default QRCode
