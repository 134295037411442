import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { $ } from 'react-jquery-plugin'
import { useLocation, useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { v4 } from 'uuid'
import { AppLogger } from '../../AppLogger'

import {
    CreateOnePoll,
    CreateOnePollVariables,
} from '../../generated/CreateOnePoll'
import {
    CreateOnePost,
    CreateOnePostVariables,
} from '../../generated/CreateOnePost'

import {
    DeleteOneRoom,
    DeleteOneRoomVariables,
} from '../../generated/DeleteOneRoom'
import {
    DeleteOneRoomAttendee,
    DeleteOneRoomAttendeeVariables,
} from '../../generated/DeleteOneRoomAttendee'
import { AttendeeRole } from '../../generated/globalTypes'
import { JoinRoom, JoinRoomVariables } from '../../generated/JoinRoom'
import {
    PollCreateDataChange,
    PollCreateDataChangeVariables,
} from '../../generated/PollCreateDataChange'

import {
    PostCreateDataChange,
    PostCreateDataChangeVariables,
} from '../../generated/PostCreateDataChange'

import {
    RoomDeleteDataChange,
    RoomDeleteDataChangeVariables,
} from '../../generated/RoomDeleteDataChange'
import {
    RoomJoinDataChange,
    RoomJoinDataChangeVariables,
} from '../../generated/RoomJoinDataChange'
import {
    RoomLeaveDataChange,
    RoomLeaveDataChangeVariables,
} from '../../generated/RoomLeaveDataChange'
import {
    RoomUpdateDataChange,
    RoomUpdateDataChangeVariables,
} from '../../generated/RoomUpdateDataChange'

import {
    UpdateOneRoom,
    UpdateOneRoomVariables,
} from '../../generated/UpdateOneRoom'

import {
    UpdateOneRoomAttendee,
    UpdateOneRoomAttendeeVariables,
} from '../../generated/UpdateOneRoomAttendee'

import {
    UpdateRoomAttendeeLastSeen,
    UpdateRoomAttendeeLastSeenVariables,
} from '../../generated/UpdateRoomAttendeeLastSeen'

import { UserData } from '../../generated/UserData'

import { POLL_CREATE, POLL_CREATE_SUBSCRIPTION } from '../../queries/poll'
import { POST_CREATE, POST_CREATE_SUBSCRIPTION } from '../../queries/post'

import {
    ROOM_ATTENDEE_UPDATE,
    ROOM_ATTENDEE_UPDATE_LAST_SEEN,
    ROOM_DELETE,
    ROOM_DELETE_CONTENT,
    ROOM_DELETE_CONTENT_SUBSCRIPTION,
    ROOM_DELETE_SUBSCRIPTION,
    ROOM_JOIN,
    ROOM_JOIN_SUBSCRIPTION,
    ROOM_LEAVE,
    ROOM_LEAVE_SUBSCRIPTION,
    ROOM_UPDATE,
    ROOM_UPDATE_SUBSCRIPTION,
} from '../../queries/room'
import { USER_QUERY } from '../../queries/user'
import { MessageForm } from './partials/content/Input'
import { validateJoinKey } from '../../auth'
import {
    AFTER_POST,
    FIRST_POSTS,
    ORDER_BY_POST,
    ORDER_BY_PUBLISHED_POST,
    SESSION_KEY,
    WHERE_POST,
} from '../common/constant'
import {
    RoomDeleteContent,
    RoomDeleteContentVariables,
} from '../../generated/RoomDeleteContent'
import {
    RoomDeleteContentDataChange,
    RoomDeleteContentDataChangeVariables,
} from '../../generated/RoomDeleteContentDataChange'

const logger = AppLogger.getInstance()
export function useRoom(
    joinKey: string,
    userId: string,
    nickName: string | null,
    setRoomInfoFn: Function
) {
    const location = useLocation()
    const history = useHistory()

    const orderByPost = useRef(ORDER_BY_POST)
    const orderByPublishedPost = useRef(ORDER_BY_PUBLISHED_POST)
    const afterPost = useRef(AFTER_POST)

    /**
     * Queries
     */

    const { data, loading, error, fetchMore } = useQuery<
        JoinRoom,
        JoinRoomVariables
    >(ROOM_JOIN, {
        variables: {
            joinKey,
            nickName,
            firstPost: FIRST_POSTS,
            orderByPost: orderByPost.current,
            orderByPublishedPost: orderByPublishedPost.current,
            afterPost: afterPost.current,
            wherePost: WHERE_POST,
            filterPollRes: {
                userId: {
                    equals: userId,
                },
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        // fetchPolicy: 'cache-first', // this is the default
        //fetchPolicy: 'cache-and-network',
        //fetchPolicy: 'network-only',
        //fetchPolicy: 'cache-only',
        //fetchPolicy: 'no-cache',
        // pollInterval: ATTENDEE_POLL_INTERVAL,
    })

    /**
     * Mutations
     */

    const [updateRoomAttendee, updateRoomAttendeeStatus] =
        useMutation<UpdateOneRoomAttendee, UpdateOneRoomAttendeeVariables>(
            ROOM_ATTENDEE_UPDATE
        )

    const [updateRoomAttendeeLastSeen, updateRoomAttendeeLastSeenStatus] =
        useMutation<
            UpdateRoomAttendeeLastSeen,
            UpdateRoomAttendeeLastSeenVariables
        >(ROOM_ATTENDEE_UPDATE_LAST_SEEN)

    const [deleteRoom] =
        useMutation<DeleteOneRoom, DeleteOneRoomVariables>(ROOM_DELETE)

    const [leaveRoom] =
        useMutation<DeleteOneRoomAttendee, DeleteOneRoomAttendeeVariables>(
            ROOM_LEAVE
        )

    const [updateRoom] =
        useMutation<UpdateOneRoom, UpdateOneRoomVariables>(ROOM_UPDATE)

    const [createPost] =
        useMutation<CreateOnePost, CreateOnePostVariables>(POST_CREATE)

    const [createPoll] =
        useMutation<CreateOnePoll, CreateOnePollVariables>(POLL_CREATE)

    const [roomDeleteContent] =
        useMutation<RoomDeleteContent, RoomDeleteContentVariables>(
            ROOM_DELETE_CONTENT
        )

    /**
     * Subscriptions
     */

    /**
    |---------------------------------------------------------
    | Triggered on room update
    | onSubscriptionData: 
    | 1 - update the current joined room 
    | 2 - updated fields are : isAutomaticFlow, isCommentsAllowed
    |---------------------------------------------------------
    */
    useSubscription<RoomUpdateDataChange, RoomUpdateDataChangeVariables>(
        ROOM_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room join or re-join
    | onSubscriptionData: 
    | 1 - update the home page mainly the joined rooms block
    |  a - in case of a new join - we add the record
    |  b - in case of a re-join - we update the lastSeenAt 
    |  c - in case of nickname add
    |---------------------------------------------------------
    */

    useSubscription<RoomJoinDataChange, RoomJoinDataChangeVariables>(
        ROOM_JOIN_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const userData = await client.readQuery<UserData>({
                    query: USER_QUERY,
                    variables: {
                        id: userId,
                    },
                })
                const existingRooms = userData?.user?.joinedRooms!
                if (existingRooms) {
                    logger.debug(
                        `ROOM_JOIN_SUBSCRIPTION existingRooms:`,
                        existingRooms
                    )
                    const _roomAttendee = subscriptionData.data?.onRoomJoin!
                    let updatedRooms = [...existingRooms]

                    const { id: userIdRoomIdRole, role } = _roomAttendee

                    const roomAttendeeArgs = userIdRoomIdRole.split('_')
                    const roomAttendeeUserId = roomAttendeeArgs
                        ? (roomAttendeeArgs[0] as string)
                        : ''
                    const roomAttendeeRoomId = roomAttendeeArgs
                        ? (roomAttendeeArgs[1] as string)
                        : ''

                    const attendeeExist = existingRooms.find(
                        (r) => r.id === userIdRoomIdRole
                    )
                    if (roomAttendeeUserId === userId) {
                        // attendee record exist
                        if (attendeeExist) {
                            // triggered by admin
                            if (role === AttendeeRole.Admin) {
                                // update admin and guest joined record in case of user has joined as an admin and as a guest
                                updatedRooms = [
                                    ...existingRooms.map((r) => {
                                        if (r.room.id === roomAttendeeRoomId) {
                                            return {
                                                ...r,
                                                lastSeenAt:
                                                    _roomAttendee.lastSeenAt,
                                                nickName:
                                                    _roomAttendee.nickName,
                                            }
                                        }
                                        return r
                                    }),
                                ]
                            } else {
                                // triggered by guest  update the guest attendee
                                updatedRooms = [
                                    ...existingRooms.map((r) => {
                                        if (
                                            r.room.id === roomAttendeeRoomId &&
                                            r.role === role
                                        ) {
                                            return {
                                                ...r,
                                                lastSeenAt:
                                                    _roomAttendee.lastSeenAt,
                                                nickName:
                                                    _roomAttendee.nickName,
                                            }
                                        }
                                        return r
                                    }),
                                ]
                            }
                        } else {
                            // attendee doesn't exist  - add it
                            logger.debug(
                                `Same user case Add new roomAttendee record `
                            )
                            updatedRooms = [...updatedRooms, _roomAttendee]
                        }
                    }

                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...userData?.user,
                                joinedRooms: updatedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room leave
    | onSubscriptionData: 
    | 1 - update the joinedRooms block by removing the room attendee record
    | 2 - navigate the joined user to home page 
    |---------------------------------------------------------
    */
    useSubscription<RoomLeaveDataChange, RoomLeaveDataChangeVariables>(
        ROOM_LEAVE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const userData = await client.readQuery<UserData>({
                    query: USER_QUERY,
                    variables: {
                        id: userId,
                    },
                })

                if (userData?.user?.joinedRooms) {
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...userData?.user,
                                joinedRooms: userData?.user?.joinedRooms.filter(
                                    (r) =>
                                        r.id !==
                                        subscriptionData.data?.onRoomLeave?.id
                                ),
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
                // navigate the user back to home
                history.push(
                    {
                        pathname: '/',
                    },
                    {
                        screen: 'room',
                    }
                )
            },
        }
    )
    /**
    |---------------------------------------------------------
    | Triggered on room delete
    | onSubscriptionData: update the joinedRooms block by removing the room attendee record
    |---------------------------------------------------------
    */
    useSubscription<RoomDeleteDataChange, RoomDeleteDataChangeVariables>(
        ROOM_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const userData = await client.readQuery<UserData>({
                    query: USER_QUERY,
                    variables: {
                        id: userId,
                    },
                })

                client.writeQuery({
                    query: USER_QUERY,
                    data: {
                        user: {
                            ...userData?.user,
                            joinedRooms: userData?.user?.joinedRooms.filter(
                                (r) =>
                                    r.room.id !==
                                    subscriptionData.data?.onRoomDelete?.id
                            ),
                        },
                    },
                    variables: {
                        id: userId,
                    },
                })

                // navigate the user back to home
                history.push(
                    {
                        pathname: '/',
                    },
                    {
                        screen: 'room',
                    }
                )
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on post create
    | onSubscriptionData: 
    | 1 - update the current joined room by adding to apollo cache the newly added post
    |---------------------------------------------------------
    */

    useSubscription<PostCreateDataChange, PostCreateDataChangeVariables>(
        POST_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                logger.debug(`POST_CREATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POST_CREATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const addedPost = subscriptionData.data?.onPostCreate
                const posts = data?.roomAttendee?.room.posts || []
                if (
                    addedPost &&
                    data &&
                    !posts.find((p) => p.id === addedPost.id) &&
                    data.roomAttendee?.room.id === addedPost.room.id
                ) {
                    const posts = [
                        { ...addedPost, comments: [], reactions: [] },
                        ...data.roomAttendee?.room.posts!,
                    ]
                    client.cache.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data.roomAttendee?.room,
                                    posts: [...posts],
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.warn(
                        `POST_CREATE_SUBSCRIPTION Don't add this post already exist`
                    )
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on poll create
    | onSubscriptionData: 
    | 1 - update the current joined room by adding to apollo cache the newly added poll
    |---------------------------------------------------------
    */
    useSubscription<PollCreateDataChange, PollCreateDataChangeVariables>(
        POLL_CREATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`POLL_CREATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `POLL_CREATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )

                const polls = data?.roomAttendee?.room.polls || []
                const addedPoll = subscriptionData.data?.onPollCreate

                if (
                    addedPoll &&
                    !polls.find((p) => p.id === addedPoll.id) &&
                    data &&
                    data.roomAttendee?.room.id === addedPoll.room.id
                ) {
                    const polls = [
                        ...data?.roomAttendee?.room.polls!,
                        {
                            ...addedPoll,
                            options: addedPoll.options.map((o) => ({
                                ...o,
                                results: [],
                            })),
                        },
                    ]
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    polls,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: null,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room content delete 
    | onSubscriptionData: 
    | 1 - update the current joined room by removing all the posts and polls then update apollo cache
    |---------------------------------------------------------
    */
    useSubscription<
        RoomDeleteContentDataChange,
        RoomDeleteContentDataChangeVariables
    >(ROOM_DELETE_CONTENT_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(`ROOM_DELETE_CONTENT_SUBSCRIPTION client:`, client)
            logger.debug(
                `ROOM_DELETE_CONTENT_SUBSCRIPTION subscriptionData:`,
                subscriptionData
            )

            if (
                subscriptionData.data?.onRoomDeleteContent &&
                data?.roomAttendee?.room.id ===
                    subscriptionData.data?.onRoomDeleteContent.roomId
            ) {
                client.refetchQueries({
                    include: 'active',
                })
            }
        },
    })

    /**
     * useEffects
     */
    useEffect(() => {
        /**
         * setupRoomJoin will handle the nickname pop-up. mainly when the room page
         * is accessed directly from a shared link that contains the joinKey
         * NB: we may accept a nickname params (Moodle login ect..)
         */
        if (!joinKey) {
            history.push(
                {
                    pathname: '/',
                },
                {
                    screen: 'room',
                }
            )
        }
        const setupRoomJoin = async () => {
            try {
                logger.debug(`setupRoomJoin joinKey :${joinKey}`)

                const isInternalNavigation =
                    location.state && location.state.screen

                if (joinKey) {
                    if (isInternalNavigation) {
                        const isAfterRoomCreation =
                            location.state &&
                            location.state.action &&
                            location.state.action === 'roomCreation'

                        if (isAfterRoomCreation && !isMobile) {
                            logger.debug(
                                `setupRoomJoin internal navigation after room creation `
                            )
                            $('body').toggleClass(
                                'switch-information-container-on'
                            )
                        }
                    } else {
                        // not internal
                        logger.debug(`setupRoomJoin navigation direct link`)
                        // validate the provided joinKey
                        try {
                            const isValid = await validateJoinKey(joinKey)
                            if (!isValid) {
                                // TODO forward user to error page
                                // push to room page
                                const pathname = `/error/${joinKey}`

                                history.push(
                                    {
                                        pathname,
                                    },
                                    {
                                        screen: 'room',
                                    }
                                )
                            }
                        } catch (e) {
                            logger.error(
                                `setupRoomJoin error on validateJoinKey call `
                            )
                        }
                    }

                    // for modal to show up we should add to the root div the class="switch-modal"
                    // when room page is accessed directly from a shared link that contains the joinKey
                    // TODO check if this room requires a nickname
                    // if yes - show a popup so user can enters his nickname

                    // add session key to identify user
                    const sessionKey = v4()
                    sessionStorage.setItem(SESSION_KEY, sessionKey)
                }
            } catch (e) {}
        }
        setupRoomJoin()
    }, [joinKey, location, history])

    return {
        fetchMore,
        data,
        loading,
        error,
        roomDeleteContent: (roomId: string) =>
            roomDeleteContent({
                variables: {
                    roomId,
                },
                refetchQueries: [
                    {
                        query: USER_QUERY,
                        variables: {
                            id: userId,
                        },
                    },
                ],
            }),
        deleteRoom: (roomId: string) =>
            deleteRoom({
                variables: {
                    where: {
                        id: roomId,
                    },
                },
                refetchQueries: [
                    {
                        query: USER_QUERY,
                        variables: {
                            id: userId,
                        },
                    },
                ],
            }),
        leaveRoom: async (roomId: string, role: AttendeeRole) => {
            try {
                // update lastSeen
                return await leaveRoom({
                    variables: {
                        where: {
                            userId_roomId_role: {
                                userId,
                                role,
                                roomId,
                            },
                        },
                    },
                    refetchQueries: [
                        {
                            query: USER_QUERY,
                            variables: {
                                id: userId,
                            },
                        },
                    ],
                })
            } catch (e) {
                logger.error(`Error when calling leaveRoom`)
            }
        },
        updateRoomAttendeeStatus,
        updateRoomAttendee: async (
            nickName: string,
            role: AttendeeRole,
            roomId: string
        ) =>
            await updateRoomAttendee({
                variables: {
                    data: {
                        nickName: {
                            set: nickName,
                        },
                        lastSeenAt: {
                            set: new Date(),
                        },
                    },
                    where: {
                        userId_roomId_role: {
                            userId,
                            role,
                            roomId,
                        },
                    },
                },
            }),
        updateRoomAttendeeLastSeenStatus,
        updateRoomAttendeeLastSeen: async (roomId: string, role: string) =>
            await updateRoomAttendeeLastSeen({
                variables: {
                    userId,
                    roomId,
                    role,
                },
                refetchQueries: [
                    {
                        query: USER_QUERY,
                        variables: {
                            id: userId,
                        },
                    },
                ],
            }),
        updateRoom: async (
            roomId: string,
            isAutomaticFlow: boolean,
            isCommentsAllowed: boolean
        ) =>
            await updateRoom({
                variables: {
                    where: {
                        id: roomId,
                    },
                    data: {
                        isAutomaticFlow: {
                            set: isAutomaticFlow,
                        },
                        isCommentsAllowed: {
                            set: isCommentsAllowed,
                        },
                    },
                },
            }),

        createPost: async (messageForm: MessageForm) =>
            await createPost({
                variables: {
                    data: {
                        content: messageForm.content,
                        isPublished: messageForm.isPublished,
                        nickName: messageForm.nickName || '',
                        room: {
                            connect: {
                                id: data?.roomAttendee?.room.id!,
                            },
                        },
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                    },
                },

                update: (cache, { data: createPostRes }) => {
                    if (createPostRes) {
                        const createPost = createPostRes.post

                        if (
                            data &&
                            !data.roomAttendee?.room.posts.find(
                                (p) => p.id === createPost.id
                            )
                        ) {
                            logger.debug(
                                `createPost update cache :Add newly created post after the create`
                            )
                            const posts = [
                                ...data?.roomAttendee?.room.posts!,
                                { ...createPost, comments: [], reactions: [] },
                            ]

                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            posts,
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: AFTER_POST,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),

        createPoll: async (messageForm: MessageForm) =>
            await createPoll({
                variables: {
                    data: {
                        content: messageForm.content,
                        nickName: messageForm.nickName || '',
                        optionsLength: messageForm.optionsLength,
                        isMultipleResponses: messageForm.isMultipleResponses,
                        isSolutions: messageForm.isSolutions,
                        isImmediateResults: messageForm.isImmediateResults,
                        room: {
                            connect: {
                                id: data?.roomAttendee?.room.id!,
                            },
                        },
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                    },
                },
                update: (cache, { data: createPollRes }) => {
                    if (createPollRes) {
                        const createPoll = createPollRes.poll
                        if (
                            data &&
                            !data?.roomAttendee?.room.polls!.find(
                                (p) => p.id === createPoll.id
                            )
                        ) {
                            const polls = [
                                ...data?.roomAttendee?.room.polls!,
                                {
                                    ...createPoll,
                                    options: createPoll.options.map((o) => ({
                                        ...o,
                                        results: [],
                                    })),
                                },
                            ]
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls,
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),
    }
}
