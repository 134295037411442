import React, { useEffect, useRef, useState } from 'react'
import { $ } from 'react-jquery-plugin'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { ROOM_INPUT_OPTIONS_SLIDE_DURATION } from '../../../common/constant'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../../AppLogger'
import { AttendeeRole } from '../../../../generated/globalTypes'
import { EventAction, EventCategory, gaEvent } from '../../../common/utilis'

export enum ContentType {
    POST = 'Post',
    POLL = 'Poll',
}
export interface MessageForm {
    content: string
    nickName: string | null | undefined

    isPublished: boolean

    /** Options for Poll only  */
    optionsLength: number

    // to answer a Multiple Response poll you need 2 steps (select + submit)
    // for a normal pool (one response) you need one step
    isMultipleResponses: boolean

    // solutions are given by the poll creator when true, and added to the right of each option
    // when isSolutions is false , poll creator can't add his answer(s)
    isSolutions: boolean

    // when isSolutions is true -> the poll will be always visible
    // selection view :  it  appears with its options for attendee selection(s)
    // results view: when answer is given , the result will be visible (automatic switch from selection view to results view)

    // when isImmediateResults value is false-> below buttons will control the poll display
    isImmediateResults: boolean

    //NB isOpened and isResults can't be true at the same time
    // when true display the selection view so attendee can add or change his answer
    // when switched by the user to false the poll options will be hidden if isResults is false
    isOpened: boolean

    // when true switch to results view (results will be displayed)  and turn isOpened to false
    isResults: boolean
}

interface Props {
    createPoll: Function
    createPost: Function
    isPostingAllowed: boolean
    isCommentsAllowed: boolean
    isPublished: boolean
    role: AttendeeRole
    nickName: string | null | undefined
    isAddingPollAllowed: boolean
}

const logger = AppLogger.getInstance()

const Input: React.FC<Props> = ({
    createPoll,
    createPost,
    isPostingAllowed,
    isCommentsAllowed,
    isPublished,
    role,
    nickName,
    isAddingPollAllowed,
}) => {
    const { t } = useTranslation(['Room'])
    const contentType = useRef(ContentType.POST)
    const refTextarea = useRef<HTMLTextAreaElement>(null)

    const [canAddPoll, setCanAddPoll] = useState<boolean>(isAddingPollAllowed)

    const defaultsMessageValues = {
        content: '',
        nickName,
        isPublished: role === AttendeeRole.Admin ? true : isPublished,
        // readonly fields
        optionsLength: 1,
        isMultipleResponses: false,
        isSolutions: false,
        isImmediateResults: false,

        // editable fields not needed for creation just for documentation
        isOpened: true,
        isResults: false,
    }
    let validationSchema = Yup.object().shape({
        //content: Yup.string().required('Room name is required'),
    })

    const handleCreateMessage = async (message: MessageForm): Promise<void> => {
        logger.debug(`Message values :`, message)

        if (refTextarea.current) {
            // empty the text
            refTextarea.current.value = ''
        }

        let response
        if (contentType.current === ContentType.POST) {
            setTimeout(async () => {
                // when isPublished is false, inform use that message will be moderated
                if (!message.isPublished) {
                    $('body').addClass('flag-notifcation')
                    // await animateCSS('.flash-notification', 'fadeIn', 0.4)
                    setTimeout(
                        () => $('body').removeClass('flag-notifcation'),
                        5000
                    )
                }
                gaEvent(EventCategory.Post, EventAction.Create, message.content)
                response = await createPost(message)
            }, 10)
        } else {
            setTimeout(async () => {
                gaEvent(EventCategory.Poll, EventAction.Create, message.content)
                toggleInputOptions()
                response = await createPoll(message)
            }, 10)
        }
        logger.debug(response)

        //const createdMsg = await createMessage(message)
        // after the creation
    }

    const sliderBehavior = () => {
        let ISAamountOfChoice = 15
        let $ISSliderRange = $('.sliderrange')
        let $ISLabel = $ISSliderRange.siblings('label')
        let ISVal = 1
        let ISSliderRangeW = $ISSliderRange.outerWidth()
        let ISSliderRangeH = $ISSliderRange.outerHeight()

        $ISSliderRange.off().on('input', function () {
            ISSliderRangeW = $ISSliderRange.outerWidth()
            ISSliderRangeH = $ISSliderRange.outerHeight()
            ISVal = parseInt($('#optionsLength').val())
            inputSliderTextAndPosition()
        })

        function inputSliderTextAndPosition() {
            $ISLabel.find('span').empty().text(ISVal)
            const posX =
                (ISSliderRangeW - ISSliderRangeH) *
                ((ISVal - 1) / (ISAamountOfChoice - 1))
            $ISLabel.css('left', `${posX}px`)
        }
    }
    const toggleInputOptions = () => {
        sliderBehavior()
        if (contentType.current === ContentType.POST) {
            contentType.current = ContentType.POLL
        } else {
            contentType.current = ContentType.POST
        }
        $('body').toggleClass('switch-options-open')
        $('.entry-input-options').slideToggle(ROOM_INPUT_OPTIONS_SLIDE_DURATION)

        //NOTE: Close all comments when option are visible
        $('.entry-thread.switch-comments-on').removeClass('switch-comments-on')

        if ($('body').hasClass('switch-options-open')) {
            $('body').removeClass('flag-new-messages')
        } else {
            $('body').addClass('flag-new-messages')
        }

        logger.debug(`We are adding a ${contentType.current}`)
    }

    const getByPlaceholderText = (role: AttendeeRole) => {
        switch (role) {
            case AttendeeRole.Admin:
                return isPostingAllowed
                    ? t('MessagePlaceholder')
                    : isCommentsAllowed
                    ? t('PostingTemporaryClosedPollAdmin')
                    : t('PostingTemporaryClosedAdmin')
            default:
                return t('MessagePlaceholder')
        }
    }

    useEffect(() => {
        if (role === AttendeeRole.Admin && !isPostingAllowed) {
            $('body').addClass('flag-no-post')
        } else {
            $('body').removeClass('flag-no-post')
        }
        return () => {
            $('body').removeClass('flag-no-post')
        }
    }, [isPostingAllowed, role])

    useEffect(() => {
        setCanAddPoll((prev) => {
            if (prev !== isAddingPollAllowed) {
                if (!prev) {
                    setTimeout(() => {
                        $('.entry-input-options').slideUp(0)
                    }, 50)
                } else {
                }
            }
            return isAddingPollAllowed
        })
    }, [isAddingPollAllowed])

    useEffect(() => {
        $('.container-input-message').toggleClass('hide')
        $('.entry-input-options').slideUp(0)

        return () => {
            $('body').removeClass('switch-options-open')
        }
    }, [])

    useEffect(() => {
        let resizeTimeout: any = null
        const onResizeFn = () => {
            const resizeDelay = 125

            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout(function () {
                sliderCalcuatePosition()
            }, resizeDelay)

            function sliderCalcuatePosition() {
                let ISAamountOfChoice = 15
                let $ISSliderRange = $('.sliderrange')
                let $ISLabel = $ISSliderRange.siblings('label')
                let ISVal = parseInt($ISSliderRange[0].value)
                let ISSliderRangeW = $ISSliderRange.outerWidth()
                let ISSliderRangeH = $ISSliderRange.outerHeight()
                const posX =
                    (ISSliderRangeW - ISSliderRangeH) *
                    ((ISVal - 1) / (ISAamountOfChoice - 1))
                $ISLabel.css('left', `${posX}px`)
            }
        }
        // initial setup
        window.addEventListener('resize', onResizeFn)
        return () => {
            window.removeEventListener('resize', onResizeFn)
        }
    }, [])

    return (
        <>
            <Formik
                initialValues={{ ...defaultsMessageValues }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (
                    values,
                    actions: FormikHelpers<MessageForm>
                ) => {
                    actions.setSubmitting(false)

                    if (!values.content || values.content.trim().length === 0) {
                        if (refTextarea.current) {
                            refTextarea.current.focus()
                        }
                    } else {
                        try {
                            await handleCreateMessage(values)
                            $('#optionsLengthSpan').text(1)
                            $('#optionsLengthLabel').css('left', 0)
                            actions.resetForm({
                                values: {
                                    ...defaultsMessageValues,
                                    optionsLength: 1,
                                },
                            })
                        } catch (e) {
                            logger.error(e)
                        }
                    }
                }}
            >
                {({ values, handleChange, handleSubmit }) => (
                    <div className="container-input-message hide">
                        <div className="entry-input">
                            <div
                                className={`form new-message ${
                                    !isPostingAllowed &&
                                    role === AttendeeRole.Guest &&
                                    'notice-temporary-close'
                                }`}
                            >
                                {canAddPoll && (
                                    <div
                                        className="input-button plus"
                                        onClick={toggleInputOptions}
                                    >
                                        <div className="open">
                                            <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="none"
                                            >
                                                <line
                                                    x1="50"
                                                    y1="0.00004"
                                                    x2="50"
                                                    y2="100.00004"
                                                    vectorEffect="non-scaling-stroke"
                                                />
                                                <line
                                                    y1="50.00004"
                                                    x2="100"
                                                    y2="50.00004"
                                                    vectorEffect="non-scaling-stroke"
                                                />
                                            </svg>
                                        </div>
                                        <div className="close">
                                            <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="none"
                                            >
                                                <line
                                                    x1="0"
                                                    y1="0"
                                                    x2="100"
                                                    y2="100"
                                                    vectorEffect="non-scaling-stroke"
                                                />
                                                <line
                                                    x1="0"
                                                    y1="100"
                                                    x2="100"
                                                    y2="0"
                                                    vectorEffect="non-scaling-stroke"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                )}

                                <textarea
                                    placeholder={getByPlaceholderText(role)}
                                    name="content"
                                    id="content"
                                    onChange={handleChange}
                                    value={values.content}
                                    ref={refTextarea}
                                ></textarea>
                                <div
                                    className={`input-button submit`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleSubmit()
                                    }}
                                >
                                    <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 100 100"
                                        preserveAspectRatio="none"
                                    >
                                        <polyline
                                            points="100 50 50 0 0 50"
                                            vectorEffect="non-scaling-stroke"
                                        />
                                        <path
                                            d="M0,100c37.60125,0,50-12.64574,50-49.98789L50,0"
                                            vectorEffect="non-scaling-stroke"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div
                                className="notice-temporary-close"
                                style={{
                                    display:
                                        isPostingAllowed ||
                                        role === AttendeeRole.Admin
                                            ? 'none'
                                            : 'block',
                                }}
                            >
                                <p>{t('PostingTemporaryClosed')}</p>
                            </div>
                        </div>
                        {canAddPoll && (
                            <div className="entry-input-options">
                                <div className=" form entry-switch">
                                    <div className="label numbers-choices">
                                        <h4>
                                            {t('NumberOfChoices')}:{' '}
                                            {values.optionsLength}
                                        </h4>

                                        <div className="slidecontainer">
                                            <input
                                                className="sliderrange"
                                                type="range"
                                                min="1"
                                                max="15"
                                                name="optionsLength"
                                                id="optionsLength"
                                                onChange={handleChange}
                                                value={values.optionsLength}
                                            />

                                            <label id="optionsLengthLabel">
                                                <span id="optionsLengthSpan">
                                                    {values.optionsLength}{' '}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="label multiple-responses">
                                        <h4>{t('MultipleResponses')}</h4>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="isMultipleResponses"
                                                id="isMultipleResponses"
                                                onChange={handleChange}
                                                checked={
                                                    values.isMultipleResponses
                                                }
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="label solutions">
                                        <h4>{t('Solutions')}</h4>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="isSolutions"
                                                id="isSolutions"
                                                onChange={handleChange}
                                                checked={values.isSolutions}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="label immediate-result">
                                        <h4>{t('ImmediateResult')}</h4>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="isImmediateResults"
                                                id="isImmediateResults"
                                                onChange={handleChange}
                                                checked={
                                                    values.isImmediateResults
                                                }
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Formik>
        </>
    )
}

export default Input
