import {
    PostOrderByWithRelationInput,
    PostWhereInput,
    PostWhereUniqueInput,
    SortOrder,
} from '../../generated/globalTypes'

export const LANDING_SLIDE_DURATION = 400
export const LANDING_SHAKE_FREQ = 400
export const LANDING_LEAVE_DURATION = 400
export const LANDING_DEL_DURATION = 400
export const ROOM_INPUT_OPTIONS_SLIDE_DURATION = 800
export const ROOM_CREATE_BLOCK_SLIDE_DURATION = 800
export const POST_DELETE_DURATION = 400
export const POLL_DELETE_DURATION = 400
export const COMMENT_DELETE_DURATION = 400
export const IMPORT_PROFILE_DURATION = 800

export const DELETE_DURATION = 400
export const RESET_DURATION = 400

/**
 * Used to store the user preferences
 */
export const TOKEN_KEY = 'speakUpAccessToken'
export const LANGUAGE_KEY = 'speakUpLanguage'
export const THEME_KEY = 'speakUpTheme'

export const SESSION_KEY = 'speakUpSessionKey'

export const GDPR_NOTICE_KEY = 'gdprNotice'
export const GDPR_NOTICE_OK = 'gdprNoticeAgreed_v1'

// Used to update the attendee updated date
export const ATTENDEE_POLL_INTERVAL = 5000

export const LANGUAGES: Record<string, string> = {
    French: 'fr',
    English: 'en',
    German: 'de',
}
export const CLASS_ROW = 'row'
export const SWITCH_SOLUTION = 'switch-solution'
export const SWITCH_SELECTED = 'switch-selected'
export const SWITCH_ON = 'switch-on'

export const DEBOUNCED_WAIT = 500

export const BASE_URL = '/speakup/api'

// increase value from 20 to 30
export const MAX_POLLS_BY_ROOM = 30

// Pagination logic
export const FIRST_POSTS = process.env.NODE_ENV === 'production' ? 25 : 5

export const ORDER_BY_POST: PostOrderByWithRelationInput = {
    createdAt: SortOrder.desc,
}

export const ORDER_BY_PUBLISHED_POST: PostOrderByWithRelationInput = {
    isPublished: SortOrder.desc,
}
export const WHERE_POST: PostWhereInput = {}
export const AFTER_POST: PostWhereUniqueInput | null = null
