import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { $ } from 'react-jquery-plugin'
import { AttendeeRole } from '../../../generated/globalTypes'
import { UserData_user_joinedRooms } from '../../../generated/UserData'
import {
    LANDING_LEAVE_DURATION,
    LANDING_SLIDE_DURATION,
} from '../../common/constant'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { roomExport } from '../../common/excel-export'
import { isMobile } from 'react-device-detect'
import { EventAction, EventCategory, gaEvent } from '../../common/utilis'

interface Props {
    room: UserData_user_joinedRooms
    showActivitiesNotification: boolean
    exportRoom: Function
    leaveRoom: Function
    deleteRoom: Function
}
const AttendeeRow: React.FC<Props> = ({
    room,
    showActivitiesNotification,
    leaveRoom,
    deleteRoom,
}) => {
    const { t } = useTranslation(['Home'])
    const history = useHistory()
    const roomEntryRef = useRef<HTMLDivElement>(null)
    const roomDelRef = useRef<HTMLSpanElement>(null)
    const roomAdminLeaveRef = useRef<HTMLSpanElement>(null)

    const handlersShowOptions = useSwipeable({
        onSwipedLeft: (eventData) => {
            eventData.event.preventDefault()
            showOptions()
        },
        onSwipedRight: (eventData) => {
            eventData.event.preventDefault()
            showOptions()
        },
        delta: 5,
        preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
        trackTouch: true, // track touch input
        trackMouse: false, // track mouse input
        rotationAngle: 0,
    })

    const showOptions = () => {
        if (roomEntryRef && roomEntryRef.current) {
            roomEntryRef.current.classList.toggle('switch-options-on')
            handleCancelAction()
        }
    }

    const handleDeleteRoom = (roomId: string) => {
        if (
            roomEntryRef &&
            roomEntryRef.current &&
            roomEntryRef.current.classList.contains('switch-delete-1')
        ) {
            roomEntryRef.current.classList.remove('switch-delete-1')
            roomEntryRef.current.classList.add('switch-remove')
            setTimeout(() => {
                $(`${room.room.id}_${room.role}`).slideUp(0)
                deleteRoom(roomId)
                gaEvent(EventCategory.Room, EventAction.Delete, room.room.name)
            }, LANDING_SLIDE_DURATION)
        } else {
            if (roomEntryRef && roomEntryRef.current) {
                roomEntryRef.current.classList.add('switch-delete-1')
            }
            if (roomDelRef && roomDelRef.current) {
                roomDelRef.current.classList.add('switch-delete-on')
            }
        }
    }
    const handleCancelAction = () => {
        if (roomEntryRef && roomEntryRef.current) {
            roomEntryRef.current.classList.remove('switch-delete-1')
        }
        if (roomDelRef && roomDelRef.current) {
            roomDelRef.current.classList.remove('switch-delete-on')
        }

        if (roomEntryRef && roomEntryRef.current) {
            roomEntryRef.current.classList.remove('switch-leave-1')
        }

        if (roomAdminLeaveRef && roomAdminLeaveRef.current) {
            roomAdminLeaveRef.current.classList.remove('switch-leave-on')
        }
    }

    /**
     * Added by AK on 07.12.2021
     * @param roomId
     * @param role
     */
    const handleAdminLeaveRoom = async (roomId: string, role: AttendeeRole) => {
        if (
            roomEntryRef &&
            roomEntryRef.current &&
            roomEntryRef.current.classList.contains('switch-leave-1')
        ) {
            roomEntryRef.current.classList.remove('switch-leave-1')
            roomEntryRef.current.classList.add('switch-remove')
            setTimeout(() => {
                $(`${room.room.id}_${room.role}`).slideUp(0)
                leaveRoom(roomId, role)
                gaEvent(EventCategory.Room, EventAction.Leave, room.room.name)
            }, LANDING_LEAVE_DURATION)
        } else {
            if (roomEntryRef && roomEntryRef.current) {
                roomEntryRef.current.classList.add('switch-leave-1')
            }
            if (roomAdminLeaveRef && roomAdminLeaveRef.current) {
                roomAdminLeaveRef.current.classList.add('switch-leave-on')
            }
        }
    }

    const handleLeaveRoom = async (roomId: string, role: AttendeeRole) => {
        if (roomEntryRef && roomEntryRef.current) {
            roomEntryRef.current.classList.add('switch-remove')
            setTimeout(() => {
                $(`${room.room.id}_${room.role}`).slideUp(0)
                leaveRoom(roomId, role)
                gaEvent(EventCategory.Room, EventAction.Leave, room.room.name)
            }, LANDING_LEAVE_DURATION)
        }
    }

    const handleExportRoom = async (roomId: string) => {
        await roomExport(roomId)
        showOptions()
        gaEvent(EventCategory.Room, EventAction.Export, room.room.name)
    }
    return (
        <div {...handlersShowOptions}>
            <div
                ref={roomEntryRef}
                id={`${room.room.id}_${room.role}`}
                className={`entry-room ${
                    room.role === AttendeeRole.Admin ? 'admin' : 'guest'
                }`}
            >
                <div className="button-cancel" onClick={handleCancelAction}>
                    <span>{t('Cancel')}</span>
                </div>
                <ul className="room-buttons">
                    {room.role === AttendeeRole.Admin && !isMobile && (
                        <li
                            className="button button-export"
                            onClick={() => handleExportRoom(room.room.id)}
                        >
                            <p>{t('Export')}</p>
                        </li>
                    )}

                    {room.role === AttendeeRole.Admin ? (
                        <li
                            className="button button-leave"
                            data-delete="leave-room"
                            onClick={() =>
                                handleAdminLeaveRoom(room.room.id, room.role)
                            }
                        >
                            <p>
                                <span className="leave" ref={roomAdminLeaveRef}>
                                    {t('Leave')}
                                </span>
                                <span className="confirm">
                                    {t('ConfirmLeave')}
                                </span>
                            </p>
                        </li>
                    ) : (
                        <li
                            className="button button-leave"
                            onClick={() =>
                                handleLeaveRoom(room.room.id, room.role)
                            }
                            data-delete="leave-room"
                        >
                            <p>{t('Leave')}</p>
                        </li>
                    )}

                    {room.role === AttendeeRole.Admin && (
                        <li
                            className="button button-delete"
                            data-delete="delete-room"
                            onClick={() => handleDeleteRoom(room.room.id)}
                        >
                            <p>
                                <span className="delete" ref={roomDelRef}>
                                    {t('Delete')}
                                </span>
                                <span className="confirm">
                                    {t('ConfirmDeletion')}
                                </span>
                            </p>
                        </li>
                    )}
                </ul>
                <div className="room-title">
                    <a
                        href="/room"
                        onClick={(e) => {
                            e.preventDefault()
                            const pathname = '/room'
                            const joinKey = room.joinKey

                            gaEvent(
                                EventCategory.Room,
                                EventAction.Rejoin,
                                room.room.name
                            )

                            history.push(
                                {
                                    pathname,
                                },
                                {
                                    screen: 'home',
                                    joinKey,
                                }
                            )
                        }}
                    >
                        <p>
                            {room.room.name}
                            {room.role === AttendeeRole.Admin && (
                                <span className="admin-badge">admin</span>
                            )}
                            {showActivitiesNotification && (
                                <span className="room-new-message-counter"></span>
                            )}
                        </p>
                    </a>
                    <div className="button-more" onClick={showOptions}>
                        <p>
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                            >
                                <line
                                    x1="95"
                                    y1="0"
                                    x2="95"
                                    y2="20"
                                    vectorEffect="non-scaling-stroke"
                                />
                                <line
                                    x1="95"
                                    y1="80"
                                    x2="95"
                                    y2="100"
                                    vectorEffect="non-scaling-stroke"
                                />
                                <line
                                    x1="95"
                                    y1="40"
                                    x2="95"
                                    y2="60"
                                    vectorEffect="non-scaling-stroke"
                                />
                            </svg>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AttendeeRow
