import React, { FormEvent, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { $ } from 'react-jquery-plugin'
import { validateJoinKey } from '../../../auth'
import {
    LANDING_SHAKE_FREQ,
    LANDING_SLIDE_DURATION,
} from '../../common/constant'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../AppLogger'
import { EventAction, EventCategory, gaEvent } from '../../common/utilis'

const logger = AppLogger.getInstance()
const JoinRoom = () => {
    const { t } = useTranslation(['Home'])
    let history = useHistory()
    const lobbyJoinRef = useRef<HTMLDivElement>(null)
    const refInput = useRef<HTMLInputElement>(null)

    const [joinKey, setJoinKey] = useState<string>('')

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        logger.debug(`joinKey is:${joinKey}`)
        if (!joinKey) {
            if (refInput.current) {
                refInput.current.focus()
            }
            return
        }
        try {
            const isValid = await validateJoinKey(joinKey)
            if (isValid) {
                logger.debug(
                    `joinKey is isValid - forward user to room page with the joinKey`
                )
                // push to room page
                const pathname = `/room/${joinKey}`

                gaEvent(EventCategory.Room, EventAction.Join, joinKey)
                history.push(
                    {
                        pathname,
                    },
                    {
                        screen: 'home',
                    }
                )
            } else {
                logger.warn(`Oops!!!  provided joinKey is not valid `)
                //animate__headShake
                setTimeout(() => {
                    $('#joinInput').removeClass('animate__headShake')
                }, LANDING_SHAKE_FREQ)
                $('#joinInput').addClass('animate__headShake')
            }
        } catch (e) {
            logger.error(`Join room handleSubmit`, e.message)
        }
    }

    const toggleForm = () => {
        if (lobbyJoinRef && lobbyJoinRef.current) {
            if (lobbyJoinRef.current.classList.contains('switch-selected')) {
                lobbyJoinRef.current.classList.remove('switch-selected')
                $('#joinRoomForm').slideUp(LANDING_SLIDE_DURATION)
            } else {
                $('#lobbyRoomJoin').siblings().removeClass('switch-selected')
                $('#createRoomForm').slideUp(LANDING_SLIDE_DURATION)
                lobbyJoinRef.current.classList.add('switch-selected')
                $('#joinRoomForm').slideDown(LANDING_SLIDE_DURATION)
            }
        }
    }

    return (
        <div
            className="lobby-choices join  switch-selected"
            ref={lobbyJoinRef}
            id="lobbyRoomJoin"
        >
            <div className="entry-title" onClick={toggleForm}>
                <h3>{t('JoinExistingRoom')}</h3>
            </div>
            <form
                className="form entry-switch lobby-form"
                id="joinRoomForm"
                onSubmit={handleSubmit}
            >
                <input
                    id="joinInput"
                    ref={refInput}
                    type="text"
                    placeholder={t('RoomNumberPlaceholder')}
                    className="input-text animate__animated "
                    value={joinKey}
                    onChange={(e) => setJoinKey(e.target.value)}
                />
                <button className={`button-submit `} type="submit">
                    <span>{t('Join')}</span>
                </button>
            </form>
        </div>
    )
}

export default JoinRoom
