import {
    JoinRoom_roomAttendee_room_polls,
    JoinRoom_roomAttendee_room_polls_options,
} from '../../../../../generated/JoinRoom'
import { SWITCH_SELECTED } from '../../../../common/constant'
import { roundNumberV1 } from '../../../../common/utilis'

/*
export const getParticipants = (
    poll: JoinRoom_roomAttendee_room_polls,
    optionId?: string
) => {
    const users: string[] = []
    const options = optionId
        ? poll.options.filter((o) => o.id === optionId)
        : poll.options
    options.forEach((opt) => {
        opt.results.forEach((res) => {
            const args = res.id.split('_')
            const _id = args ? (args[0] as string) : ''
            if (optionId) {
                if (res.isChecked) {
                    if (!users.find((uid) => uid === _id)) {
                        users.push(_id)
                    }
                }
            } else {
                if (!users.find((uid) => uid === _id)) {
                    users.push(_id)
                }
            }
        })
    })

    return users
}
*/

/**
 * Option progress
 * @param option
 * @param poll
 * @returns
 */

/** 
export const getOptionProgress = (
    option: JoinRoom_roomAttendee_room_polls_options,
    poll: JoinRoom_roomAttendee_room_polls
) => {
    const participants = getParticipants(poll)
    return roundNumberV1(
        participants.length
            ? (getParticipants(poll, option.id).length / participants.length) *
                  100
            : 0,
        participants.length > 100 ? 2 : 0
    )
}
*/

interface PollResult {
    participants: number
    pollOptions: {
        id: string
        votes: number
    }[]
}

const praseExtendedResultData = (d: Record<string, string>[]): PollResult => {
    let res: PollResult = {
        participants: 0,
        pollOptions: [],
    }
    for (const o of d) {
        if (o.participants) {
            res.participants = (o.participants as unknown) as number
        } else if (o.votes && o.pollOptionId) {
            res.pollOptions.push({
                id: o.pollOptionId,
                votes: (o.votes as unknown) as number,
            })
        }
    }

    return res
}
export const getParticipants = (
    poll: JoinRoom_roomAttendee_room_polls,
    optionId?: string
) => {
    if (poll && poll.extendedResultData && poll.extendedResultData.set) {
        const d = praseExtendedResultData(poll.extendedResultData.set)

        if (optionId && d.pollOptions) {
            const opt = d.pollOptions.filter((po) => po.id === optionId)
            return opt.length > 0 ? opt[0].votes : 0
        } else return d.participants
    }
    return 0
}

export const getOptionProgress = (
    option: JoinRoom_roomAttendee_room_polls_options,
    poll: JoinRoom_roomAttendee_room_polls
) => {
    const participants = getParticipants(poll)
    return roundNumberV1(
        participants
            ? (getParticipants(poll, option.id) / participants) * 100
            : 0,
        participants > 100 ? 2 : 0
    )
}

/**
 * Used in multi response Poll
 * returns the list of checked options by the guest before submit his answer
 * @param classListMap
 * @returns
 */
export const getSelectedOptions = (
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >
) => {
    const optionResults: string[] = []
    if (classListMap.current) {
        for (let entry of Array.from(classListMap.current.entries())) {
            let key = entry[0]
            let value = entry[1]
            if (
                value.current &&
                value.current.classList.contains(SWITCH_SELECTED.trim())
            ) {
                optionResults.push(key)
            }
        }
    }
    return optionResults
}

/**
 * Not for multi responses poll
 * @param poll
 * @param userId
 * @returns
 */
export const getGuestAnsweredOption = (
    poll: JoinRoom_roomAttendee_room_polls,
    userId: string
): JoinRoom_roomAttendee_room_polls_options | null => {
    const guestVotes = poll.options.filter((option) => {
        return option.results.find((r) => {
            const args = r.id.split('_')
            const _id = args ? (args[0] as string) : ''
            return _id === userId
        })
    })
    return guestVotes.length === 1 ? guestVotes[0] : null
}

/**
 * Used to print the final guest answers based on the correct answers given by the admin
 * @param poll
 * @param userId
 * @returns
 */
export const getGuestCorrectAnsweredOptions = (
    poll: JoinRoom_roomAttendee_room_polls,
    userId: string
): JoinRoom_roomAttendee_room_polls_options[] => {
    const { options } = poll

    const guestVotes = poll.options.filter((option) => {
        return option.results.find((r) => {
            const args = r.id.split('_')
            const _id = args ? (args[0] as string) : ''
            return _id === userId
        })
    })

    return options.filter((opt) => {
        const vote = guestVotes.find((gv) => gv.id === opt.id)
        if (vote) {
            return !!vote.results.find((r) => {
                const args = r.id.split('_')
                const _id = args ? (args[0] as string) : ''
                return _id === userId && r.isChecked === vote.isChecked
            })
        }
        return false
    })
}

/**
 * Used in mon response poll ONLY
 * Returns the checked option id
 * @param classListMap
 * @param className
 * @returns
 */
export const getCheckedOptionId = (
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >,
    className: string
) => {
    let optionResultId: string = ''
    if (classListMap.current) {
        for (let entry of Array.from(classListMap.current.entries())) {
            let key = entry[0]
            let value = entry[1]
            if (
                value.current &&
                value.current.classList.contains(className.trim())
            ) {
                optionResultId = key
                break
            }
        }
    }
    return optionResultId
}

/**
 * Check if an option has a className
 * @param id
 * @param classListMap
 * @param className
 * @returns
 */
export const isOptionHasClassName = (
    id: string,
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >,
    className: string
): boolean => {
    const refClassListMap = classListMap.current.get(id)

    if (refClassListMap && refClassListMap.current) {
        return refClassListMap.current.classList.contains(className.trim())
    }
    return false
}

/**
 * when true, this means the guest has voted the poll
 * when false, this means the guest did not submit any vote on this poll
 * @returns
 */
export const isVotedPoll = (
    poll: JoinRoom_roomAttendee_room_polls,
    userId: string
) => {
    const votes = poll.options.filter((option) => {
        return option.results.find((r) => {
            const args = r.id.split('_')
            const _id = args ? (args[0] as string) : ''
            return _id === userId
        })
    })
    return votes.length > 0
}

/**
 * Add the provided className to the Option if  doesn't  exit
 * @param id
 * @param classListMap
 * @param className
 * @returns
 */
export const addClassByOptionId = (
    id: string,
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >,
    className: string
) => {
    const refClassListMap = classListMap.current.get(id)
    if (refClassListMap?.current) {
        if (refClassListMap?.current.classList.contains(className.trim())) {
            return
        }
        refClassListMap?.current?.classList.add(className.trim())
    }
}

/**
 * remove the provided className to the Option if  it does  exit
 * @param id
 * @param classListMap
 * @param className
 */
export const removeClassByOptionId = (
    id: string,
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >,
    className: string
) => {
    const refClassListMap = classListMap.current.get(id)

    if (refClassListMap?.current) {
        if (refClassListMap.current.classList.contains(className.trim())) {
            refClassListMap?.current.classList.toggle(className.trim())
        }
    }
}
