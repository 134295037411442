import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'ts-debounce'
import { getUserIdFromToken } from '../../../../../../../auth'
import { ReactionType } from '../../../../../../../generated/globalTypes'
import {
    DEBOUNCED_WAIT,
    SWITCH_SELECTED,
} from '../../../../../../common/constant'
import {
    addReaction,
    getCheckedReaction,
    removeReaction,
} from '../../reactionHelper'
import { useCommentReaction } from './useReaction'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_posts_comments,
} from '../../../../../../../generated/JoinRoom'
import { AppLogger } from '../../../../../../../AppLogger'
import { useTranslation } from 'react-i18next'

const logger = AppLogger.getInstance()

interface Props {
    comment: JoinRoom_roomAttendee_room_posts_comments
    classListMap: React.MutableRefObject<
        Map<ReactionType, React.RefObject<HTMLDivElement>>
    >
    baseClassName: string
    reactionType: ReactionType
    joinKey: string
    allVotesRef: React.RefObject<HTMLDivElement>
    computedVotesRef: React.RefObject<HTMLDivElement>
    nickName: string | null | undefined
    dataJoinRoom: JoinRoom
}

const CommentReaction: React.FC<Props> = ({
    comment,
    classListMap,
    baseClassName,
    reactionType,
    joinKey,
    allVotesRef,
    computedVotesRef,
    nickName,
    dataJoinRoom,
}) => {
    const { t } = useTranslation(['Room'])
    const userId = getUserIdFromToken()!
    const refClassList = useRef<HTMLDivElement>(null)
    const getVotesText = (count: number) => {
        return count < 2 ? t(`Votes${count}`) : t('Votes')
    }
    const {
        createCommentReaction,
        createCommentReactionStatus,
        updateCommentReaction,
        updateCommentReactionStatus,
        deleteCommentReaction,
        deleteCommentReactionStatus,
    } = useCommentReaction(
        userId,
        comment.id,
        comment.post.id,
        joinKey,
        allVotesRef,
        computedVotesRef,
        getVotesText,
        nickName,
        dataJoinRoom
    )

    const [classList] = useState<string>(() => {
        const isFound = comment.reactions.find((r) => {
            const args = r.id.split('_')
            const _userId = args ? (args[0] as string) : ''
            return _userId === userId && r.reactionType === reactionType
        })
        return isFound ? SWITCH_SELECTED : ''
    })

    const mutate = async () => {
        if (
            createCommentReactionStatus.error ||
            updateCommentReactionStatus.error ||
            deleteCommentReactionStatus.error
        ) {
            //TODO handle error
            return
        }

        const prevReactionType = getCheckedReaction(
            classListMap,
            SWITCH_SELECTED
        )
        if (prevReactionType) {
            if (prevReactionType !== reactionType) {
                // case 1: new clicked reaction is # than the previous one
                removeReaction(prevReactionType, classListMap, SWITCH_SELECTED)
                addReaction(reactionType, classListMap, SWITCH_SELECTED)
                await updateCommentReaction(reactionType)
            } else {
                //case 2: new checked reaction is the same as the previous
                removeReaction(reactionType, classListMap, SWITCH_SELECTED)
                // update allVotes = allVotes -1
                await deleteCommentReaction()
            }
        } else {
            // case 3: new checked reaction (there was no previous option checked)
            addReaction(reactionType, classListMap, SWITCH_SELECTED)

            await createCommentReaction(reactionType)
        }
    }

    const isImmediateFlag = true
    const debouncedMutateCommentReaction = debounce(
        mutate,
        DEBOUNCED_WAIT / 2,
        { isImmediate: isImmediateFlag }
    )
    useEffect(() => {
        // register post
        classListMap.current.set(reactionType, refClassList)
        return () => {
            logger.debug('--- useEffect cleanUp .........')
        }
    }, [comment.id, classListMap, reactionType])

    return (
        <div
            className={`${baseClassName} ${classList}`}
            ref={refClassList}
            onClick={debouncedMutateCommentReaction}
        >
            {reactionType === ReactionType.Dislike ? (
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M87.27206,53.39516l-7.39746.00969c-11.31138,0-24.82784,7.08-24.82784,13.706,0,6.61623,10.43564,15.50444,14.113,19.177,2.068,2.06357,4.27351,4.78964,4.4252,8.169a5.54029,5.54029,0,0,1-1.66618,3.928h0a5.57286,5.57286,0,0,1-7.90342-.0567L37.2563,71.60677S29.07809,61.57337,25.803,53.40485H0V9.80662l30.422,1.66569C38.58129,4.01378,55.8086,0,63.84482,0c6.65452,0,14.83709.45459,16.77272.92759,1.9362.4827,9.63163,1.05069,9.63163,5.97312,0,3.71958-1.22612,5.95374-4.31179,6.10495,3.08567-.15121,7.80892,2.745,7.92717,6.45582.1231,3.71957-2.72123,6.86243-5.80689,7.00442,3.08566-.15169,6.13353,2.745,6.25178,6.465.1231,3.71957-4.3966,6.85274-7.48227,7.00443,3.08567-.15169,5.68379,2.745,5.80253,6.45533.1231,3.72006-2.272,6.86243-5.35764,7.00443"
                        vectorEffect="non-scaling-stroke"
                    />
                </svg>
            ) : (
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M12.72794,46.60488l7.39746-.0097c11.31138,0,24.82784-7.08,24.82784-13.70595,0-6.61624-10.43564-15.50444-14.113-19.177-2.068-2.06358-4.27351-4.78965-4.4252-8.169a5.54028,5.54028,0,0,1,1.66618-3.928h0a5.57286,5.57286,0,0,1,7.90342.0567L62.7437,28.39327S70.92191,38.42666,74.197,46.59518H100V90.19341L69.578,88.52773C61.41871,95.98625,44.1914,100,36.15518,100c-6.65452,0-14.83709-.45459-16.77272-.92759-1.9362-.4827-9.63163-1.05069-9.63163-5.97312,0-3.71958,1.22612-5.95374,4.31179-6.105-3.08567.15121-7.80892-2.745-7.92717-6.45582-.1231-3.71957,2.72123-6.86243,5.80689-7.00443-3.08566.15169-6.13353-2.745-6.25178-6.465-.1231-3.71958,4.3966-6.85274,7.48227-7.00443-3.08567.15169-5.68379-2.745-5.80253-6.45534-.1231-3.72005,2.272-6.86242,5.35764-7.00442"
                        vectorEffect="non-scaling-stroke"
                    />
                </svg>
            )}
        </div>
    )
}

export default CommentReaction
