import { AppLogger } from './AppLogger'
import { BASE_URL, TOKEN_KEY } from './components/common/constant'

const logger = AppLogger.getInstance()

export async function exportRoom(roomId: string): Promise<any> {
    logger.debug(`roomId:${roomId}`)
    const response = await fetch(`${BASE_URL}/room/exportRoom`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ roomId }),
    })
    if (!response.ok) {
        return false
    }
    const { roomData } = await response.json()
    logger.info(`roomData:${JSON.stringify(roomData, null, 3)}`)
    return roomData
}
