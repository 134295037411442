import { useMutation, useSubscription } from '@apollo/client'
import { AppLogger } from '../../../../../../../AppLogger'
import {
    CommentReactionCreateDataChange,
    CommentReactionCreateDataChangeVariables,
} from '../../../../../../../generated/CommentReactionCreateDataChange'
import {
    CommentReactionDeleteDataChange,
    CommentReactionDeleteDataChangeVariables,
} from '../../../../../../../generated/CommentReactionDeleteDataChange'
import {
    CommentReactionUpdateDataChange,
    CommentReactionUpdateDataChangeVariables,
} from '../../../../../../../generated/CommentReactionUpdateDataChange'

import {
    CreateOneCommentReaction,
    CreateOneCommentReactionVariables,
} from '../../../../../../../generated/CreateOneCommentReaction'

import {
    DeleteOneCommentReaction,
    DeleteOneCommentReactionVariables,
} from '../../../../../../../generated/DeleteOneCommentReaction'
import { ReactionType } from '../../../../../../../generated/globalTypes'
import { JoinRoom } from '../../../../../../../generated/JoinRoom'

import {
    UpdateOneCommentReaction,
    UpdateOneCommentReactionVariables,
} from '../../../../../../../generated/UpdateOneCommentReaction'

import {
    COMMENT_REACTION_CREATE,
    COMMENT_REACTION_CREATE_SUBSCRIPTION,
    COMMENT_REACTION_DELETE,
    COMMENT_REACTION_DELETE_SUBSCRIPTION,
    COMMENT_REACTION_UPDATE,
    COMMENT_REACTION_UPDATE_SUBSCRIPTION,
} from '../../../../../../../queries/comment'
import { ROOM_JOIN } from '../../../../../../../queries/room'
import {
    FIRST_POSTS,
    ORDER_BY_POST,
    AFTER_POST,
    WHERE_POST,
    SESSION_KEY,
    ORDER_BY_PUBLISHED_POST,
} from '../../../../../../common/constant'
import { isSameUserSession } from '../../../../../../common/utilis'
import { getAllVotes, getComputedVotes } from '../../reactionHelper'

const logger = AppLogger.getInstance()
export function useCommentReaction(
    userId: string,
    commentId: string,
    postId: string,
    joinKey: string,
    allVotesRef: React.RefObject<HTMLDivElement>,
    computedVotesRef: React.RefObject<HTMLDivElement>,
    getVotesText: (count: number) => string,
    nickName: string | null | undefined,
    dataJoinRoom: JoinRoom
) {
    /**
     * Mutations
     */

    // Comment Reactions
    const [createCommentReaction, createCommentReactionStatus] = useMutation<
        CreateOneCommentReaction,
        CreateOneCommentReactionVariables
    >(COMMENT_REACTION_CREATE)

    const [updateCommentReaction, updateCommentReactionStatus] = useMutation<
        UpdateOneCommentReaction,
        UpdateOneCommentReactionVariables
    >(COMMENT_REACTION_UPDATE)

    const [deleteCommentReaction, deleteCommentReactionStatus] = useMutation<
        DeleteOneCommentReaction,
        DeleteOneCommentReactionVariables
    >(COMMENT_REACTION_DELETE)

    /**
     *
     * @param newReactions
     */
    const updateCommentVotes = (newReactions: any[]) => {
        const _computedVotes = getComputedVotes(newReactions)
        const _computedVotesVal =
            _computedVotes > 0 ? `+${_computedVotes}` : _computedVotes
        if (computedVotesRef.current) {
            computedVotesRef.current.innerText = String(_computedVotesVal)
        }
        const allVotes = getAllVotes(newReactions)
        const voteText = getVotesText(allVotes)
        if (allVotesRef.current) {
            allVotesRef.current.innerHTML = `
                    <span >
                 ${allVotes}
                </span> ${voteText}
                    `
        }
    }

    // Comment Reaction
    /**
    |---------------------------------------------------------
    | Triggered on comment reaction create
    | onSubscriptionData: 
    | 1 - update the current joined room by adding to apollo cache the newly added comment reaction
    |---------------------------------------------------------
    */
    useSubscription<
        CommentReactionCreateDataChange,
        CommentReactionCreateDataChangeVariables
    >(COMMENT_REACTION_CREATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const addedCommentReaction =
                subscriptionData.data?.onCommentReactionCreate

            if (dataJoinRoom && addedCommentReaction) {
                const args = addedCommentReaction.id.split('_')
                const _commId = args ? (args[1] as string) : ''

                const postComments = dataJoinRoom.roomAttendee?.room.posts.find(
                    (p) => p.id === postId
                )?.comments

                let myCommentReactions =
                    postComments!.find((c) => c.id === commentId)?.reactions ||
                    []

                if (
                    _commId === commentId &&
                    !myCommentReactions.find(
                        (pr) =>
                            pr.id === addedCommentReaction.id &&
                            pr.reactionType ===
                                addedCommentReaction.reactionType
                    )
                ) {
                    logger.debug(
                        `COMMENT_REACTION_CREATE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )

                    const commentReactions = [
                        ...myCommentReactions,
                        addedCommentReaction,
                    ]

                    updateCommentVotes(commentReactions)
                    const updatedComments = postComments!.map((c) => {
                        if (c.id === commentId) {
                            return {
                                ...c,
                                reactions: commentReactions,
                            }
                        }
                        return c
                    })

                    const updatedPosts = dataJoinRoom.roomAttendee?.room.posts!.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    comments: updatedComments,
                                }
                            }
                            return p
                        }
                    )

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...dataJoinRoom.roomAttendee,
                                room: {
                                    ...dataJoinRoom.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `COMMENT_REACTION_CREATE_SUBSCRIPTION don't do anything already added or not the target comment`
                    )
                }
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on comment reaction update
    | onSubscriptionData: 
    | 1 - update the current joined room by modifying the existing comment reaction
    | 2 - editable field is: ReactionType: Like | Dislike
    |---------------------------------------------------------
    */
    useSubscription<
        CommentReactionUpdateDataChange,
        CommentReactionUpdateDataChangeVariables
    >(COMMENT_REACTION_UPDATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const updatedCommentReaction =
                subscriptionData.data?.onCommentReactionUpdate
            if (dataJoinRoom && updatedCommentReaction) {
                const args = updatedCommentReaction.id.split('_')
                const _commId = args ? (args[1] as string) : ''

                if (
                    _commId === commentId &&
                    !isSameUserSession(updatedCommentReaction.modifiedBy)
                ) {
                    logger.debug(
                        `COMMENT_REACTION_UPDATE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const postComments = dataJoinRoom.roomAttendee?.room.posts.find(
                        (p) => p.id === postId
                    )?.comments

                    const myCommentReactions =
                        postComments!.find((c) => c.id === commentId)
                            ?.reactions || []

                    const commentReactions = myCommentReactions.map((cr) => {
                        if (cr.id === updatedCommentReaction.id) {
                            return {
                                ...cr,
                                reactionType:
                                    updatedCommentReaction.reactionType,
                            }
                        }
                        return cr
                    })
                    updateCommentVotes(commentReactions!)
                    const updatedComments = postComments!.map((c) => {
                        if (c.id === commentId) {
                            return {
                                ...c,
                                reactions: commentReactions,
                            }
                        }
                        return c
                    })

                    const updatedPosts = dataJoinRoom.roomAttendee?.room.posts!.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    comments: updatedComments,
                                }
                            }
                            return p
                        }
                    )

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...dataJoinRoom.roomAttendee,
                                room: {
                                    ...dataJoinRoom.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `COMMENT_REACTION_UPDATE_SUBSCRIPTION don't do anything sameUser session or not the target reaction`
                    )
                }
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on comment reaction delete
    | onSubscriptionData: 
    | 1 - update the current joined room by removing from apollo cache the deleted comment reaction
    |---------------------------------------------------------
    */
    useSubscription<
        CommentReactionDeleteDataChange,
        CommentReactionDeleteDataChangeVariables
    >(COMMENT_REACTION_DELETE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const deletedCommentReaction =
                subscriptionData.data?.onCommentReactionDelete
            if (dataJoinRoom && deletedCommentReaction) {
                const args = deletedCommentReaction.id.split('_')
                const _commId = args ? (args[1] as string) : ''

                const postComments = dataJoinRoom.roomAttendee?.room.posts.find(
                    (p) => p.id === postId
                )?.comments

                const myCommentReactions =
                    postComments!.find((c) => c.id === commentId)?.reactions ||
                    []

                if (
                    _commId === commentId &&
                    myCommentReactions.find(
                        (cr) => cr.id === deletedCommentReaction.id
                    )
                ) {
                    logger.debug(
                        `COMMENT_REACTION_DELETE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const commentReactions = myCommentReactions.filter(
                        (cr) => cr.id !== deletedCommentReaction.id
                    )
                    updateCommentVotes(commentReactions)

                    const updatedComments = postComments!.map((c) => {
                        if (c.id === commentId) {
                            return {
                                ...c,
                                reactions: commentReactions,
                            }
                        }
                        return c
                    })

                    const updatedPosts = dataJoinRoom.roomAttendee?.room.posts!.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    comments: updatedComments,
                                }
                            }
                            return p
                        }
                    )

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...dataJoinRoom.roomAttendee,
                                room: {
                                    ...dataJoinRoom.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            operation: `DELETE_COMMENT_REACTION_${deletedCommentReaction.id}`,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `COMMENT_REACTION_DELETE_SUBSCRIPTION already deleted or not the target post`
                    )
                }
            }
        },
    })
    /**
     * useEffect hooks
     */
    return {
        createCommentReactionStatus,
        createCommentReaction: async (reaction: ReactionType) =>
            await createCommentReaction({
                variables: {
                    data: {
                        reactionType: reaction,
                        id: `${userId}_${commentId}`,
                        comment: {
                            connect: {
                                id: commentId,
                            },
                        },
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                    },
                },
                update: (cache, { data: createReactionRes, errors }) => {
                    if (createReactionRes && !errors) {
                        const addedReaction = createReactionRes.commentReaction

                        if (addedReaction && dataJoinRoom) {
                            const postComments = dataJoinRoom.roomAttendee?.room.posts.find(
                                (p) => p.id === postId
                            )?.comments

                            const myCommentReactions =
                                postComments!.find((c) => c.id === commentId)
                                    ?.reactions || []

                            if (
                                !myCommentReactions.find(
                                    (pr) =>
                                        pr.id === addedReaction.id &&
                                        pr.reactionType ===
                                            addedReaction.reactionType
                                )
                            ) {
                                const commentReactions = [
                                    ...myCommentReactions,
                                    addedReaction,
                                ]
                                updateCommentVotes(commentReactions)

                                const updatedComments = postComments!.map(
                                    (c) => {
                                        if (c.id === commentId) {
                                            return {
                                                ...c,
                                                reactions: commentReactions,
                                            }
                                        }
                                        return c
                                    }
                                )
                                const updatedPosts = dataJoinRoom.roomAttendee?.room.posts!.map(
                                    (p) => {
                                        if (p.id === postId) {
                                            return {
                                                ...p,
                                                comments: updatedComments,
                                            }
                                        }
                                        return p
                                    }
                                )

                                cache.writeQuery({
                                    query: ROOM_JOIN,
                                    data: {
                                        roomAttendee: {
                                            ...dataJoinRoom.roomAttendee,
                                            room: {
                                                ...dataJoinRoom.roomAttendee
                                                    ?.room,
                                                posts: updatedPosts,
                                            },
                                        },
                                    },
                                    variables: {
                                        joinKey,
                                        nickName,
                                        firstPost: FIRST_POSTS,
                                        orderByPost: ORDER_BY_POST,
                                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                        afterPost: AFTER_POST,
                                        wherePost: WHERE_POST,
                                        filterPollRes: {
                                            userId: {
                                                equals: userId,
                                            },
                                        },
                                    },
                                })
                            }
                        }
                    }
                },
            }),

        updateCommentReactionStatus,
        updateCommentReaction: async (reaction: ReactionType) =>
            await updateCommentReaction({
                variables: {
                    data: {
                        reactionType: {
                            set: reaction,
                        },
                        modifiedBy: {
                            set: sessionStorage.getItem(SESSION_KEY),
                        },
                    },
                    where: {
                        userId_commentId: {
                            commentId,
                            userId,
                        },
                    },
                },
                update: (cache, { data: updateReactionRes, errors }) => {
                    if (updateReactionRes && !errors) {
                        const updatedReaction =
                            updateReactionRes.commentReaction

                        if (dataJoinRoom && updatedReaction) {
                            const postComments = dataJoinRoom.roomAttendee?.room.posts.find(
                                (p) => p.id === postId
                            )?.comments

                            const myCommentReactions =
                                postComments!.find((c) => c.id === commentId)
                                    ?.reactions || []

                            const targetReaction = myCommentReactions.find(
                                (cr) => cr.id === updatedReaction.id
                            )
                            if (
                                targetReaction &&
                                targetReaction.reactionType !==
                                    updatedReaction.reactionType
                            ) {
                                const commentReactions = myCommentReactions.map(
                                    (cr) => {
                                        if (cr.id === updatedReaction.id) {
                                            return {
                                                ...cr,
                                                reactionType:
                                                    updatedReaction.reactionType,
                                            }
                                        }
                                        return cr
                                    }
                                )

                                updateCommentVotes(commentReactions!)
                                const updatedComments = postComments!.map(
                                    (c) => {
                                        if (c.id === commentId) {
                                            return {
                                                ...c,
                                                reactions: commentReactions,
                                            }
                                        }
                                        return c
                                    }
                                )

                                const updatedPosts = dataJoinRoom.roomAttendee?.room.posts!.map(
                                    (p) => {
                                        if (p.id === postId) {
                                            return {
                                                ...p,
                                                comments: updatedComments,
                                            }
                                        }
                                        return p
                                    }
                                )

                                cache.writeQuery({
                                    query: ROOM_JOIN,
                                    data: {
                                        roomAttendee: {
                                            ...dataJoinRoom.roomAttendee,
                                            room: {
                                                ...dataJoinRoom.roomAttendee
                                                    ?.room,
                                                posts: updatedPosts,
                                            },
                                        },
                                    },
                                    variables: {
                                        joinKey,
                                        nickName,
                                        firstPost: FIRST_POSTS,
                                        orderByPost: ORDER_BY_POST,
                                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                        afterPost: AFTER_POST,
                                        wherePost: WHERE_POST,
                                        filterPollRes: {
                                            userId: {
                                                equals: userId,
                                            },
                                        },
                                    },
                                })
                            }
                        }
                    }
                },
            }),
        deleteCommentReactionStatus,
        deleteCommentReaction: async () =>
            await deleteCommentReaction({
                variables: {
                    where: {
                        userId_commentId: {
                            commentId,
                            userId,
                        },
                    },
                },
                update: (cache, { data: delReactionRes, errors }) => {
                    if (delReactionRes && !errors) {
                        const deletedCommentReaction =
                            delReactionRes.commentReaction
                        if (dataJoinRoom && deletedCommentReaction) {
                            const postComments = dataJoinRoom.roomAttendee?.room.posts.find(
                                (p) => p.id === postId
                            )?.comments

                            let myCommentReactions =
                                postComments!.find((c) => c.id === commentId)
                                    ?.reactions || []

                            if (
                                myCommentReactions.find(
                                    (cr) => cr.id === deletedCommentReaction.id
                                )
                            ) {
                                const commentReactions = postComments!
                                    .find((c) => c.id === commentId)
                                    ?.reactions!.filter(
                                        (cr) =>
                                            cr.id !== deletedCommentReaction.id
                                    )

                                updateCommentVotes(commentReactions!)

                                const updatedComments = postComments!.map(
                                    (c) => {
                                        if (c.id === commentId) {
                                            return {
                                                ...c,
                                                reactions: commentReactions,
                                            }
                                        }
                                        return c
                                    }
                                )

                                const updatedPosts = dataJoinRoom.roomAttendee?.room.posts!.map(
                                    (p) => {
                                        if (p.id === postId) {
                                            return {
                                                ...p,
                                                comments: updatedComments,
                                            }
                                        }
                                        return p
                                    }
                                )
                                cache.writeQuery({
                                    query: ROOM_JOIN,
                                    data: {
                                        roomAttendee: {
                                            ...dataJoinRoom.roomAttendee,
                                            room: {
                                                ...dataJoinRoom.roomAttendee
                                                    ?.room,
                                                posts: updatedPosts,
                                            },
                                        },
                                    },
                                    variables: {
                                        joinKey,
                                        nickName,
                                        firstPost: FIRST_POSTS,
                                        orderByPost: ORDER_BY_POST,
                                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                        afterPost: AFTER_POST,
                                        wherePost: WHERE_POST,
                                        operation: `DELETE_COMMENT_REACTION_${deletedCommentReaction.id}`,
                                        filterPollRes: {
                                            userId: {
                                                equals: userId,
                                            },
                                        },
                                    },
                                })
                            }
                        }
                    }
                },
            }),
    }
}
