import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { $ } from 'react-jquery-plugin'
import { useSwipeable } from 'react-swipeable'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { AppLogger } from '../../../../../AppLogger'
import { getUserIdFromToken } from '../../../../../auth'
import { AttendeeRole } from '../../../../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_polls,
} from '../../../../../generated/JoinRoom'
import {
    POST_DELETE_DURATION,
    RESET_DURATION,
} from '../../../../common/constant'
import { EventAction, EventCategory, gaEvent } from '../../../../common/utilis'
import {
    getGuestCorrectAnsweredOptions,
    getOptionProgress,
    getParticipants,
    getSelectedOptions,
    isVotedPoll,
} from './helper'
import AdminOption from './options/admin/Option'
import GuestOption from './options/guest/Option'
import { usePoll } from './usePoll'

interface Props {
    data: JoinRoom_roomAttendee_room_polls
    dataJoinRoom: JoinRoom
    joinKey: string
    nickName: string | null | undefined
    isVisible: boolean
}

const logger = AppLogger.getInstance()
const Poll: React.FC<Props> = ({
    data,
    nickName,
    joinKey,
    dataJoinRoom,
    isVisible,
}) => {
    const { t } = useTranslation(['Room'])

    const threadEntryRef = useRef<HTMLDivElement>(null)

    // used for optimistic update
    const classListMap = useRef<Map<string, React.RefObject<HTMLDivElement>>>(
        new Map()
    )

    const pollId = data.id
    const role = dataJoinRoom.roomAttendee?.role!
    const userId = getUserIdFromToken()!
    const isMyPoll = data.createdBy.id === userId
    const authorName = isMyPoll ? t('ByMe') : data.nickName

    const sortedOptions =
        data && data.options
            ? [...data.options].sort((a, b) => (a.label > b.label && 1) || -1)
            : []

    const {
        deletePoll,
        deletePollStatus,
        updatePoll,
        updatePollStatus,
        createPollOptionResults,
        createPollOptionResultsStatus,
        pollResetOptionsResults,
        pollResetOptionsResultsStatus,
    } = usePoll(joinKey, userId, dataJoinRoom, pollId, classListMap, nickName)

    const toggleShowOptions = () => {
        if (threadEntryRef && threadEntryRef.current) {
            threadEntryRef.current.classList.toggle('switch-options-on')
        }
    }
    const handleDelete = async () => {
        if (deletePollStatus.loading) {
            return
        }
        if (threadEntryRef && threadEntryRef.current) {
            threadEntryRef.current.classList.toggle('switch-remove')
            setTimeout(async () => {
                $('#' + data.id).slideUp(POST_DELETE_DURATION)
                try {
                    await deletePoll()
                } catch (e) {
                    logger.error(`Error while deleting a poll`, e)
                }
            }, POST_DELETE_DURATION)
            setTimeout(() => {
                gaEvent(EventCategory.Poll, EventAction.Delete, data.content)
            }, POST_DELETE_DURATION * 2)
        }
    }

    const handleReset = async () => {
        if (pollResetOptionsResultsStatus.loading) {
            return
        }
        if (threadEntryRef && threadEntryRef.current) {
            toggleShowOptions()
            const res = await pollResetOptionsResults()
            console.log(res.data?.poll?.count)
            setTimeout(() => {
                gaEvent(EventCategory.Poll, EventAction.Reset, data.content)
            }, RESET_DURATION * 2)
        }
    }

    const handlePollVisualUpdate = async (
        isOpened: boolean,
        isResults: boolean
    ) => {
        //prevent truthy values for both
        if (isOpened === true && isResults === true) {
            const _isOpened = data.isOpened === isOpened ? !isOpened : isOpened
            const _isResults =
                data.isResults === isResults ? !isResults : isResults
            // switch them b4 sending the mutation
            $(`#isResults_${pollId}`).prop('checked', _isResults)
            $(`#isOpened${pollId}`).prop('checked', _isOpened)
        } else {
            $(`#isResults_${pollId}`).prop('checked', isResults)
            $(`#isOpened${pollId}`).prop('checked', isOpened)
        }
    }

    const handlePollUpdate = async (isOpened: boolean, isResults: boolean) => {
        if (updatePollStatus.loading) {
            return
        }
        //prevent truthy values for both
        if (isOpened === true && isResults === true) {
            const _isOpened = data.isOpened === isOpened ? !isOpened : isOpened
            const _isResults =
                data.isResults === isResults ? !isResults : isResults

            await updatePoll(_isOpened, _isResults)
        } else {
            await updatePoll(isOpened, isResults)
        }
    }

    const isSubmitBtnVisible = () => {
        switch (role) {
            case AttendeeRole.Admin:
                return false
            case AttendeeRole.Guest:
                return (
                    data.isMultipleResponses === true &&
                    !isVotedPoll(data, userId) &&
                    data.isResults === false
                )
            default:
                return false
        }
    }

    const isParticipantsNbrVisible = () => {
        switch (role) {
            case AttendeeRole.Admin:
                return true
            case AttendeeRole.Guest:
                return data.isResults === true
            default:
                return false
        }
    }
    const getPollClassList = () => {
        let classList = data.isMultipleResponses
            ? 'flag-multiple-solution '
            : ''
        if (role === AttendeeRole.Guest) {
            if (data.isImmediateResults) {
                if (isVotedPoll(data, userId)) {
                    classList +=
                        'switch-poll-closed  switch-poll-shown switch-poll-voted '
                }
            } else if (data.isOpened) {
                if (isVotedPoll(data, userId)) {
                    classList += ' switch-poll-voted '
                }
            } else if (data.isResults) {
                classList += ' switch-poll-closed switch-poll-shown '
            }
        }
        return classList
    }

    const getGuestAnswers = () => {
        const correctVotes = getGuestCorrectAnsweredOptions(data, userId)

        /**
         *
         */

        return data.isMultipleResponses
            ? ` ${correctVotes.length}/${data.options.length} ${t(
                  'VotingAnswer'
              )}`
            : correctVotes.length === 1
            ? t('VotingAnswerCorrect')
            : t('VotingAnswerWrong')
    }

    /**
     * to creating multiple options in one go
     */
    const handleMultipleOptionsCreate = async () => {
        if (createPollOptionResultsStatus.loading) {
            return
        }

        if (createPollOptionResultsStatus.error) {
            alert('opps')
        }

        const optionResults = getSelectedOptions(classListMap)

        await createPollOptionResults(
            pollId,
            data.isImmediateResultsBroadcast,
            optionResults
        )
    }

    const handlersShowOptions = useSwipeable({
        onSwipedLeft: (eventData) => {
            eventData.event.preventDefault()
            if (dataJoinRoom.roomAttendee?.role === AttendeeRole.Admin) {
                toggleShowOptions()
            } else {
                return
            }
        },
        onSwipedRight: (eventData) => {
            eventData.event.preventDefault()
            if (dataJoinRoom.roomAttendee?.role === AttendeeRole.Admin) {
                toggleShowOptions()
            } else {
                return
            }
        },
        delta: 5,
        preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
        trackTouch: true, // track touch input
        trackMouse: false, // track mouse input
        rotationAngle: 0,
    })

    const handlersToggleResults = useSwipeable({
        onSwipedLeft: async (eventData) => {
            eventData.event.preventDefault()
            eventData.event.stopPropagation()
            handlePollVisualUpdate(data.isOpened, !data.isResults)
            await handlePollUpdate(data.isOpened, !data.isResults)
        },
        onSwipedRight: async (eventData) => {
            eventData.event.preventDefault()
            eventData.event.stopPropagation()
            handlePollVisualUpdate(data.isOpened, !data.isResults)
            await handlePollUpdate(data.isOpened, !data.isResults)
        },
        delta: 5,
        preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
        trackTouch: true, // track touch input
        trackMouse: false, // track mouse input
        rotationAngle: 0,
    })

    const handlersToggleOpen = useSwipeable({
        onSwipedLeft: async (eventData) => {
            eventData.event.preventDefault()
            eventData.event.stopPropagation()
            handlePollVisualUpdate(!data.isOpened, data.isResults)
            await handlePollUpdate(!data.isOpened, data.isResults)
        },
        onSwipedRight: async (eventData) => {
            eventData.event.preventDefault()
            eventData.event.stopPropagation()
            handlePollVisualUpdate(!data.isOpened, data.isResults)
            await handlePollUpdate(!data.isOpened, data.isResults)
        },
        delta: 5,
        preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
        trackTouch: true, // track touch input
        trackMouse: false, // track mouse input
        rotationAngle: 0,
    })

    const [clipboardOpacity, setClipboardOpacity] = useState(1)
    // copy to clipboard
    const getContentWithCopyToClipboard = () => {
        return (
            <CopyToClipboard
                text={`${window.origin}/poll/${dataJoinRoom.roomAttendee?.room.num}/${data.id}`}
                onCopy={() => {
                    setClipboardOpacity(0.6)
                    setTimeout(() => {
                        setClipboardOpacity(1)
                    }, 400)
                }}
            >
                <p
                    className="message"
                    title={t('CopyPollToClipboard')}
                    style={{ cursor: 'pointer', opacity: clipboardOpacity }}
                >
                    {data.content}
                </p>
            </CopyToClipboard>
        )
    }

    const getParticipantsText = (count: number) => {
        return count < 2 ? t(`Votes${count}`) : t('Votes')
    }

    return isVisible ? (
        <div {...handlersShowOptions} className="thread-swipe">
            <div className="entry-thread" ref={threadEntryRef} id={data.id}>
                <div className={`thread-polling ${getPollClassList()}`}>
                    <ul className="content-buttons">
                        <li
                            className="button button-delete"
                            onClick={handleReset}
                        >
                            <p>{t('Reset')}</p>
                        </li>

                        <li
                            className="button button-delete"
                            data-delete="polling"
                            onClick={handleDelete}
                        >
                            <p>{t('Delete')}</p>
                        </li>
                    </ul>
                    <div className="polling-content">
                        {role === AttendeeRole.Admin ? (
                            getContentWithCopyToClipboard()
                        ) : (
                            <p className="message">{data.content}</p>
                        )}
                        <div
                            className="button-more"
                            onClick={toggleShowOptions}
                        >
                            <p>
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <line
                                        x1="95"
                                        y1="0"
                                        x2="95"
                                        y2="20"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                    <line
                                        x1="95"
                                        y1="80"
                                        x2="95"
                                        y2="100"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                    <line
                                        x1="95"
                                        y1="40"
                                        x2="95"
                                        y2="60"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                </svg>
                            </p>
                        </div>

                        <div className="polling">
                            {sortedOptions.map((o) => {
                                const {
                                    isResults,
                                    isMultipleResponses,
                                    isSolutions,
                                    isImmediateResults,
                                    isImmediateResultsBroadcast,
                                } = data
                                return role === AttendeeRole.Guest ? (
                                    <GuestOption
                                        key={o.id}
                                        pollId={pollId}
                                        dataJoinRoom={dataJoinRoom}
                                        joinKey={joinKey}
                                        nickName={nickName}
                                        option={o}
                                        role={role}
                                        isResults={isResults}
                                        isMultipleResponses={
                                            isMultipleResponses
                                        }
                                        isSolutions={isSolutions}
                                        isImmediateResults={isImmediateResults}
                                        classListMap={classListMap}
                                        isVotedPoll={isVotedPoll(data, userId)}
                                        progress={getOptionProgress(o, data)}
                                        votes={getParticipants(data, o.id)}
                                        isImmediateResultsBroadcast={
                                            isImmediateResultsBroadcast
                                        }
                                    ></GuestOption>
                                ) : (
                                    <AdminOption
                                        key={o.id}
                                        pollId={pollId}
                                        dataJoinRoom={dataJoinRoom}
                                        joinKey={joinKey}
                                        nickName={nickName}
                                        option={o}
                                        role={role}
                                        isMultipleResponses={
                                            isMultipleResponses
                                        }
                                        isSolutions={isSolutions}
                                        classListMap={classListMap}
                                        progress={getOptionProgress(o, data)}
                                        votes={getParticipants(data, o.id)}
                                    ></AdminOption>
                                )
                            })}

                            {isSubmitBtnVisible() && (
                                <button
                                    className="button-submit"
                                    onClick={handleMultipleOptionsCreate}
                                >
                                    <span>{t('Submit')}</span>
                                </button>
                            )}

                            <div className="feedbacks">
                                <p className="feedback feedback-thanks">
                                    {t('ThankYouForVoting')}
                                </p>
                                <p className="feedback feedback-closed">
                                    {isVotedPoll(data, userId) &&
                                    data.isSolutions
                                        ? getGuestAnswers()
                                        : t('ThePollIsClosed')}
                                </p>
                            </div>

                            {isParticipantsNbrVisible() && (
                                <div className="voting">
                                    <div className="count-vote">
                                        <div className="count-total">
                                            <span>{getParticipants(data)}</span>{' '}
                                            {getParticipantsText(
                                                getParticipants(data)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!data.isImmediateResults && (
                                <form className="entry-switch">
                                    <div
                                        className="label poll-closed"
                                        {...handlersToggleOpen}
                                    >
                                        <h4>{t('ClosePoll')}</h4>
                                        <label className="switch">
                                            <input
                                                onChange={async (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    handlePollVisualUpdate(
                                                        !data.isOpened,
                                                        data.isResults
                                                    )
                                                    await handlePollUpdate(
                                                        !data.isOpened,
                                                        data.isResults
                                                    )
                                                }}
                                                type="checkbox"
                                                id={`isOpened${pollId}`}
                                                checked={data.isOpened === true}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div
                                        className="label poll-hide"
                                        {...handlersToggleResults}
                                    >
                                        <h4>{t('ShowResults')}</h4>
                                        <label className="switch">
                                            <input
                                                onChange={async (e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    handlePollVisualUpdate(
                                                        data.isOpened,
                                                        !data.isResults
                                                    )
                                                    await handlePollUpdate(
                                                        data.isOpened,
                                                        !data.isResults
                                                    )
                                                }}
                                                id={`isResults_${pollId}`}
                                                type="checkbox"
                                                checked={
                                                    data.isResults === true
                                                }
                                            />

                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </form>
                            )}
                        </div>

                        <div className="content-footer">
                            <div className="credits">
                                <p>
                                    {moment(data.createdAt).format(
                                        'DD/MM/YYYY HH:mm'
                                    )}
                                    {authorName && `, ${authorName}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    )
}

export default Poll
