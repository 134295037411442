import { useSubscription } from '@apollo/client'
import { useEffect } from 'react'
import { $ } from 'react-jquery-plugin'
import { AppLogger } from '../../../AppLogger'
import { setUserPreferences } from '../../../auth'
import { UserData_user } from '../../../generated/UserData'
import {
    UserUpdateDataChange,
    UserUpdateDataChangeVariables,
} from '../../../generated/UserUpdateDataChange'
import { USER_UPDATE_SUBSCRIPTION } from '../../../queries/user'

const logger = AppLogger.getInstance()

export const useProfile = (userId: string) => {
    useSubscription<UserUpdateDataChange, UserUpdateDataChangeVariables>(
        USER_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                logger.debug(`USER_UPDATE_SUBSCRIPTION client:`, client)
                logger.debug(
                    `USER_UPDATE_SUBSCRIPTION subscriptionData:`,
                    subscriptionData
                )
                const newProfile = subscriptionData.data
                    ?.onUserUpdate as UserData_user

                //TODO  add new logic for the language changes
                setUserPreferences(newProfile)
            },
        }
    )

    /**
     * useEffect hooks
     */

    useEffect(() => {
        const onResizeFn = () => {
            let transitionsOnTimeout: any = null
            const transitionsOnDelay = 125
            const $root = $('#root')

            $root.addClass('flag-transitions-false')

            clearTimeout(transitionsOnTimeout)
            transitionsOnTimeout = setTimeout(function () {
                cssTransitionsOn()
            }, transitionsOnDelay)

            function cssTransitionsOn() {
                $root.removeClass('flag-transitions-false')
            }
        }
        //close the right menu for lobby and room pages
        $('body').removeClass('switch-remove-room-on')
        $('body').removeClass('switch-leave-room-on')
        $('body').removeClass('switch-clear-room-on')

        window.addEventListener('resize', onResizeFn)
        // handle gdpr notice

        return () => {
            window.removeEventListener('resize', onResizeFn)
        }
    }, [])
}
