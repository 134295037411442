import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
//import ReCAPTCHA from 'react-google-recaptcha'
import * as QueryString from 'query-string'
import {
    checkGdprAcceptance,
    getAccessToken,
    getUserIdFromToken,
    login,
    resetUserPreferredTheme,
    validateUser,
} from '../../../auth'
import { Theme } from '../../../generated/globalTypes'
import { AppLogger } from '../../../AppLogger'
const logger = AppLogger.getInstance()

export const useAuth = () => {
    const location = useLocation()

    const isLoading = location.state && location.state.screen
    const [loading, setLoading] = useState(!isLoading)

    useEffect(() => {
        const getLoggedUser = async () => {
            let preferredTheme: Theme | undefined = undefined

            const params = QueryString.parse(location.search)

            // when e.g https://.../home?theme=UniNE
            if (params.theme) {
                preferredTheme = params.theme as Theme
            }
            // when e.g https://.../?theme=UniNE ->https://.../home with state:{...props.location.state,theme: params.theme}
            // when e.g https://.../room/[joinKey]/?theme=UniNE ->https://.../room with state:{...props.location.state,theme: params.theme,  joinKey: props.match.params.joinKey,}
            if (location.state && location.state.theme) {
                preferredTheme = location.state.theme as Theme
            }

            const isLogged = getAccessToken()

            if (isLogged && isLogged !== 'undefined') {
                // validate the user
                logger.debug(
                    `Validate the user stored in the local storage object`
                )
                const loggedUser = getUserIdFromToken()
                let isValid = false
                if (loggedUser) {
                    isValid = await validateUser(loggedUser, preferredTheme)
                }
                logger.debug(`isValid:${isValid}`)
                if (!isValid) {
                    logger.debug(
                        `User has been deleted from DB - create new user`
                    )
                    const token = process.env.REACT_APP_RECAPTCHA_SITE_KEY //await reRef!.current!.executeAsync()
                    const loginRes = await login(token!, preferredTheme)
                    if (loginRes) {
                        setLoading(false)
                    } else {
                        logger.error(`Error login new user`)
                    }
                }
                setLoading(false)
            } else {
                logger.info(
                    `There is no user stored in the local storage - create new user`
                )
                logger.info(
                    `***************** NODE_ENV :${process.env.NODE_ENV}`
                )
                logger.info(
                    `REACT_APP_RECAPTCHA_SITE_KEY :${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
                )
                const token =
                    process.env
                        .REACT_APP_RECAPTCHA_SITE_KEY /*await reRef!.current!.executeAsync()*/

                const loginRes = await login(token!, preferredTheme)
                if (loginRes) {
                    setLoading(false)
                } else {
                    logger.error(`Error login new user`)
                }
            }
        }
        setTimeout(() => {
            // this is to avoid any null reference
            checkGdprAcceptance()
            if (!location.state || !location.state.screen) {
                /**
                 * getLoggedUser is called when you load the app (Home | Room) in the browser
                 */
                logger.debug(
                    `>>>>>>>>>>>>>>>>>> getLoggedUser >>>>>>>>>>>>>>>>>>>>`
                )
                getLoggedUser()
            } else {
                // When we refresh a page the state will be kept so the app will not call the getLoggedUser
                // function below is needed to re-attach the theme to the html root element
                resetUserPreferredTheme()
            }
        }, 100)
    }, [location])

    return {
        loading,
    }
}
