import React, { useEffect } from 'react'
import { $ } from 'react-jquery-plugin'
import { AttendeeRole } from '../../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_polls,
    JoinRoom_roomAttendee_room_posts,
} from '../../../generated/JoinRoom'
import { getRoomContentByActiveMenu, isVisiblePoll } from '../../common/utilis'
import Poll from '../../Room/partials/content/poll/Poll'
import Post from '../../Room/partials/content/post/Post'
import { SortMenu } from '../../Room/Room'

interface Props {
    data: JoinRoom
    joinKey: string
    objId: string
    typename: 'Post' | 'Poll'
}

const ExternalContent: React.FC<Props> = ({ data, joinKey, objId }) => {
    const role = data.roomAttendee?.role!
    const isModerated = data.roomAttendee?.room.isModerated!

    const activeContent = getRoomContentByActiveMenu(
        data.roomAttendee?.room!,
        SortMenu.RECENT,
        role
    )

    const isCommentingAllowed = (): boolean => {
        const { isCommentsAllowed, polls, isPollOnly } =
            data.roomAttendee?.room!
        const isCommentingAllowed =
            polls
                .filter((p) => p.isImmediateResults === false)
                .filter((p) => p.isOpened === true).length === 0 &&
            isCommentsAllowed

        return isCommentingAllowed && !isPollOnly
    }

    useEffect(() => {
        if (role && role === AttendeeRole.Admin) {
            $('body').addClass('flag-admin')
        }
    }, [role])

    useEffect(() => {
        $('div.container-content').addClass('flag-external-alias')
    }, [])

    const filterTargetObj = (
        activeContent: (
            | JoinRoom_roomAttendee_room_polls
            | JoinRoom_roomAttendee_room_posts
        )[]
    ) => {
        const polls = activeContent.filter((a) => a.__typename === 'Poll')
        if (objId.toLocaleLowerCase().startsWith('poll')) {
            const arr = objId.toLocaleLowerCase().split('poll') //poll1 poll2 ... poll122
            const pollIndex = arr[1] ? parseInt(arr[1]) - 1 : -1
            const reversedPolls = [...polls.reverse()]
            const targetPoll =
                polls.length > pollIndex && reversedPolls[pollIndex]
            return targetPoll ? [targetPoll] : []
        } else {
            const targetObj = activeContent.find((ac) => ac.id === objId)
            return targetObj ? [targetObj] : []
        }
    }

    return (
        <div className="entry-threads">
            {/** posts list and polls list here  */}
            {activeContent.length > 0 &&
                filterTargetObj(activeContent).map((a) =>
                    a.__typename === 'Poll' ? (
                        <Poll
                            key={a.id}
                            data={a}
                            dataJoinRoom={data}
                            joinKey={joinKey}
                            nickName={data.roomAttendee?.nickName}
                            isVisible={isVisiblePoll(
                                a,
                                data.roomAttendee?.role!
                            )}
                        ></Poll>
                    ) : (
                        <Post
                            key={a.id}
                            data={a}
                            fetchMore={() => {}}
                            dataJoinRoom={data}
                            joinKey={joinKey}
                            nickName={data.roomAttendee?.nickName}
                            isCommentingAllowed={isCommentingAllowed()}
                            isCommentPublished={!isModerated}
                            setRoomInfo={() => {}}
                            role={role}
                        ></Post>
                    )
                )}
        </div>
    )
}

export default ExternalContent
