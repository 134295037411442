import moment from 'moment'
import React, { useRef, useState } from 'react'
import { $ } from 'react-jquery-plugin'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_posts,
} from '../../../../../generated/JoinRoom'
import { POST_DELETE_DURATION } from '../../../../common/constant'
import CommentInput from './comment/CommentInput'
import Comment from './comment/Comment'
import {
    AttendeeRole,
    ReactionType,
} from '../../../../../generated/globalTypes'
import { getUserIdFromToken } from '../../../../../auth'
import { usePost } from './usePost'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../../../AppLogger'
import PostReaction from './reaction/Reaction'
import { getAllVotes, getComputedVotes } from './reactionHelper'
import { useSwipeable } from 'react-swipeable'
import { EventAction, EventCategory, gaEvent } from '../../../../common/utilis'

/**
 *
 */

interface Props {
    data: JoinRoom_roomAttendee_room_posts
    dataJoinRoom: JoinRoom
    joinKey: string
    nickName: string | null | undefined

    isCommentingAllowed: boolean
    isCommentPublished: boolean
    fetchMore: (filter?: string) => void
    setRoomInfo: Function
    role: AttendeeRole
}

const logger = AppLogger.getInstance()
const Post: React.FC<Props> = ({
    data,
    dataJoinRoom,
    nickName,
    isCommentingAllowed,
    joinKey,
    isCommentPublished,
    fetchMore,
    setRoomInfo,
    role,
}) => {
    const { t } = useTranslation(['Room'])
    const threadEntryRef = useRef<HTMLDivElement>(null)
    const messageEntryRef = useRef<HTMLDivElement>(null)

    // used for optimistic update
    const classListMap = useRef<
        Map<ReactionType, React.RefObject<HTMLDivElement>>
    >(new Map())

    const computedVotesRef = useRef<HTMLDivElement>(null)
    const allVotesRef = useRef<HTMLDivElement>(null)

    const userId = getUserIdFromToken()!
    const postId = data.id

    const getVotesText = (count: number) => {
        return count < 2 ? t(`Votes${count}`) : t('Votes')
    }

    const {
        publishPost,
        favoritePost,
        updatePostStatus,
        deletePost,
        deletePostStatus,
        createComment,
        createCommentStatus,
    } = usePost(
        joinKey,
        userId,
        dataJoinRoom,
        postId,
        nickName,
        allVotesRef,
        computedVotesRef,
        getVotesText,
        fetchMore,
        setRoomInfo,
        role
    )

    const isMyPost = data.createdBy.id === userId
    const authorName = isMyPost ? t('ByMe') : data.nickName
    const sortedComments =
        data && data.comments
            ? [...data.comments].sort((a, b) => {
                  return moment(a.createdAt).diff(b.createdAt)
              })
            : []

    const toggleShowOptions = () => {
        if (threadEntryRef && threadEntryRef.current) {
            threadEntryRef.current.classList.toggle('switch-options-on')
        }
    }

    const handleFavorite = async () => {
        if (updatePostStatus.error) {
            // TODO handle error
            logger.error(updatePostStatus.error)
        }
        if (messageEntryRef && messageEntryRef.current) {
            messageEntryRef.current.classList.toggle('switch-pinned')
            toggleShowOptions()
            const update = await favoritePost(!data.isFavorite)
            gaEvent(EventCategory.Post, EventAction.Favorite, data.content)
            logger.debug(`handleFavorite:`, update)
        }
    }

    const handlePublishYes = async () => {
        if (updatePostStatus.error) {
            // TODO handle error
            logger.error(updatePostStatus.error)
        } else {
            const update = await publishPost()
            gaEvent(EventCategory.Post, EventAction.Publish, data.content)
            logger.debug(`handlePublish:`, update)
        }
    }

    const handlePublishNon = async () => {
        if (deletePostStatus.error) {
            // TODO handle error
            logger.error(deletePostStatus.error)
        } else {
            const postPublishNon = await deletePost()
            gaEvent(EventCategory.Post, EventAction.UnPublish, data.content)
            logger.debug(`handlePublishNon:`, postPublishNon)
        }
    }

    const handleDelete = async () => {
        if (deletePostStatus.error) {
            // TODO handle error
            logger.error(deletePostStatus.error)
        }
        if (threadEntryRef && threadEntryRef.current) {
            threadEntryRef.current.classList.toggle('switch-remove')
            setTimeout(async () => {
                $('#' + data.id).slideUp(POST_DELETE_DURATION)
                try {
                    await deletePost()
                } catch (e) {
                    logger.error(`Error while deleting a Post `, e)
                }
            }, POST_DELETE_DURATION)
            setTimeout(() => {
                gaEvent(EventCategory.Post, EventAction.Delete, data.content)
            }, POST_DELETE_DURATION * 2)
        }
    }

    const openCloseComments = () => {
        if ($('body').hasClass('switch-options-open')) return

        if (threadEntryRef && threadEntryRef.current) {
            if (threadEntryRef.current.classList.contains('switch-options-on'))
                return

            threadEntryRef.current.classList.toggle('switch-comments-on')
            $('#' + postId).toggleClass('switch-swipe-comments-on')
        }
    }

    const commentCount = () => {
        const count = data.comments.filter((c) => c.isPublished).length!
        const transKey = count < 2 ? `Comments${count}` : `Comments`
        return `${count} ${t(transKey)}`
    }

    const handlersShowOptions = useSwipeable({
        onSwipedLeft: (eventData) => {
            eventData.event.preventDefault()
            if (
                isMyPost ||
                dataJoinRoom.roomAttendee?.role === AttendeeRole.Admin
            ) {
                toggleShowOptions()
            } else {
                return
            }
        },
        onSwipedRight: (eventData) => {
            eventData.event.preventDefault()
            if (
                isMyPost ||
                dataJoinRoom.roomAttendee?.role === AttendeeRole.Admin
            ) {
                toggleShowOptions()
            } else {
                return
            }
        },
        delta: 5,
        preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
        trackTouch: true, // track touch input
        trackMouse: false, // track mouse input
        rotationAngle: 0,
    })

    // To be checked with Harry
    const getModerateSwipeClass = (): string => {
        if (data.isPublished) {
            const hasUnpublishedComments = data.comments.some(
                (c) => !c.isPublished
            )
            return hasUnpublishedComments ? 'switch-comment-to-moderate' : ''
        } else {
            return 'switch-to-moderate'
        }
    }

    // add switch-to-moderate class at the entry-thread level if post is not published
    // add switch-comment-to-moderate at the entry-thread level if one or many comments are not published
    const getModerateClass = (): string => {
        if (data.isPublished) {
            const hasUnpublishedComments = data.comments.some(
                (c) => !c.isPublished
            )
            return hasUnpublishedComments ? 'switch-comment-to-moderate' : ''
        } else {
            return 'switch-to-moderate'
        }
    }

    const [clipboardOpacity, setClipboardOpacity] = useState(1)

    // copy to clipboard
    const getContentWithCopyToClipboard = () => {
        return (
            <CopyToClipboard
                text={`${window.origin}/post/${dataJoinRoom.roomAttendee?.room.num}/${data.id}`}
                onCopy={() => {
                    setClipboardOpacity(0.6)
                    setTimeout(() => {
                        setClipboardOpacity(1)
                    }, 400)
                }}
            >
                <p
                    className="message"
                    title={t('CopyPostToClipboard')}
                    style={{ cursor: 'pointer', opacity: clipboardOpacity }}
                >
                    {data.content}
                </p>
            </CopyToClipboard>
        )
    }

    const _computedVotes = getComputedVotes(data.reactions)

    return (
        <div
            {...handlersShowOptions}
            className={`thread-swipe ${getModerateSwipeClass()}`}
            /* id={postId} */
        >
            <div
                ref={threadEntryRef}
                className={`entry-thread  ${
                    isMyPost ? 'flag-my-message' : ' '
                } ${getModerateClass()}`}
                id={data.id}
            >
                <div
                    className={`thread-message ${
                        data.isFavorite === true &&
                        dataJoinRoom.roomAttendee?.role === AttendeeRole.Admin
                            ? 'switch-pinned'
                            : ''
                    }`}
                    ref={messageEntryRef}
                >
                    <ul className="content-buttons">
                        <li
                            className="button button-favorite"
                            onClick={handleFavorite}
                        >
                            <p>{t('Favorite')}</p>
                        </li>
                        <li
                            className="button button-delete"
                            data-delete="thread"
                            onClick={handleDelete}
                        >
                            <p>{t('Delete')}</p>
                        </li>
                    </ul>
                    <div className="message-content">
                        {role === AttendeeRole.Admin ? (
                            getContentWithCopyToClipboard()
                        ) : (
                            <p className="message">{data.content}</p>
                        )}
                        <div
                            className="button-more"
                            onClick={toggleShowOptions}
                        >
                            <p>
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <line
                                        x1="95"
                                        y1="0"
                                        x2="95"
                                        y2="20"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                    <line
                                        x1="95"
                                        y1="80"
                                        x2="95"
                                        y2="100"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                    <line
                                        x1="95"
                                        y1="40"
                                        x2="95"
                                        y2="60"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                </svg>
                            </p>
                        </div>
                        <div className="voting">
                            <PostReaction
                                baseClassName="hand down"
                                classListMap={classListMap}
                                post={data}
                                reactionType={ReactionType.Dislike}
                                joinKey={joinKey}
                                nickName={nickName}
                                allVotesRef={allVotesRef}
                                computedVotesRef={computedVotesRef}
                                dataJoinRoom={dataJoinRoom}
                            ></PostReaction>
                            <div className="count-vote">
                                <div
                                    className="count-average"
                                    ref={computedVotesRef}
                                >
                                    {_computedVotes > 0
                                        ? `+${_computedVotes}`
                                        : _computedVotes}
                                </div>
                                <div className="count-total" ref={allVotesRef}>
                                    <span>{getAllVotes(data.reactions)}</span>{' '}
                                    {getVotesText(getAllVotes(data.reactions))}
                                </div>
                            </div>
                            <PostReaction
                                allVotesRef={allVotesRef}
                                computedVotesRef={computedVotesRef}
                                baseClassName="hand up"
                                classListMap={classListMap}
                                post={data}
                                reactionType={ReactionType.Like}
                                joinKey={joinKey}
                                nickName={nickName}
                                dataJoinRoom={dataJoinRoom}
                            ></PostReaction>
                        </div>
                        <div className="content-footer">
                            <div
                                className="button-moderate no"
                                onClick={handlePublishNon}
                            >
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <line
                                        x1="0"
                                        y1="0"
                                        x2="100"
                                        y2="100"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                    <line
                                        x1="0"
                                        y1="100"
                                        x2="100"
                                        y2="0"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                </svg>
                            </div>
                            <div className="credits">
                                <p>
                                    {moment(data.createdAt).format(
                                        'DD/MM/YYYY HH:mm'
                                    )}
                                    {authorName && `, ${authorName}`}
                                </p>
                            </div>
                            {
                                <div
                                    className="comments-link"
                                    onClick={openCloseComments}
                                >
                                    <p>{commentCount()}</p>
                                </div>
                            }
                            <div
                                className="button-moderate yes"
                                onClick={handlePublishYes}
                            >
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        d="M0,50L40,100L100,0"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="thread-comments">
                    {sortedComments.map((c) => (
                        <Comment
                            data={c}
                            key={c.id}
                            joinKey={joinKey}
                            postId={data.id}
                            nickName={nickName}
                            dataJoinRoom={dataJoinRoom}
                            setRoomInfo={setRoomInfo}
                            role={role}
                        ></Comment>
                    ))}
                    <CommentInput
                        postId={data.id}
                        role={dataJoinRoom.roomAttendee?.role!}
                        isCommentingAllowed={isCommentingAllowed}
                        isCommentsAllowed={
                            dataJoinRoom.roomAttendee?.room!.isCommentsAllowed!
                        }
                        createComment={createComment}
                        createCommentStatus={createCommentStatus}
                        openCloseComments={openCloseComments}
                        isPublished={isCommentPublished}
                    />
                </div>
            </div>
        </div>
    )
}

export default Post
