import { useTranslation } from 'react-i18next'
import { $ } from 'react-jquery-plugin'
import { Language } from '../generated/globalTypes'
import { getUserIdFromToken, setGdprAcceptance } from '../auth'
import { LANGUAGES, LANGUAGE_KEY } from './common/constant'
import { useGdprNotice } from './useGdprNotice'

/**
 *
 */
const GdprNotice: React.FC = () => {
    const { i18n, t } = useTranslation(['GdprNotice', 'Languages'])
    const userId = getUserIdFromToken()!
    const { updateProfileLanguage } = useGdprNotice(userId)

    const currentLanguage = i18n.language
    const languages = Object.keys(Language)

    const language = localStorage.getItem(LANGUAGE_KEY)
        ? (localStorage.getItem(LANGUAGE_KEY) as Language)
        : currentLanguage

    const handleChange = async (language: Language) => {
        localStorage.setItem(LANGUAGE_KEY, language)
        // setup the language
        const _language = LANGUAGES[language]
        i18n.changeLanguage(_language)

        await updateProfileLanguage(language)
    }

    const handleOpenFurtherContent = () => {
        $('.entry-notice').addClass('switch-gdpr-further-content-open')
        $('.button-further-content').addClass('switch-button-further-content')
    }

    const handleAgree = () => {
        setGdprAcceptance()
    }

    return (
        <div className="gdpr-notice">
            <div className="entry-notice">
                <div className="entry-notice-content">
                    <div className="entry-notice-content-terms">
                        <h3>{t('TermsConditions')}</h3>
                        <ul>
                            <li>{t('NoPersonalDataCollected')}</li>
                            <li>{t('ServersInCH')}</li>
                            <li>{t('DataUsage')}</li>
                            <li>{t('UseAbuse')}</li>
                        </ul>

                        <div
                            className="button-further-content"
                            onClick={handleOpenFurtherContent}
                        >
                            <span>{t('ReadFurther')}</span>
                            <div className="gdpr-further-content">
                                <h4>{t('CollectionInformation')}</h4>
                                <p>{t('WhenInUse')}</p>
                                <ul>
                                    <li>{t('UserIdentifierParagraph')}</li>
                                    <li>{t('UsageDataParagraph')}</li>
                                    <li>{t('UserGeneratedDataParagraph')}</li>
                                    <li>{t('NicknamesMoodleParagraph')}</li>
                                </ul>
                                <h4>{t('UseOfInformation')}</h4>
                                <p>{t('UseOfInformationParagraph')}</p>
                                <h4>{t('SharingOfInformation')}</h4>
                                <p>{t('SharingOfInformationParagraph')}</p>
                                <h4>{t('LimitationOfUsage')}</h4>
                                <p>{t('LimitationOfUsageParagraph')}</p>
                                <h4>{t('CommunityGuidelines')}</h4>
                                <p>
                                    {t('CommunityGuidelinesL1')}
                                    <br />
                                    {t('CommunityGuidelinesL2')}
                                </p>
                                <ul>
                                    <li>{t('NoHarassment')}</li>
                                    <li>{t('NoRacism')}</li>
                                    <li>{t('NoOffensive')}</li>
                                    <li>{t('NoSpamming')}</li>
                                    <li>{t('NoSecurityAbuse')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="button-room agree" onClick={handleAgree}>
                        <span>{t('IAgreeBtn')}</span>
                    </div>

                    <div className="language-select-gdpr">
                        {languages.map((lan) => (
                            <span
                                key={lan}
                                className={`language ${
                                    lan === language ? 'selected' : ''
                                }`}
                                onClick={() => {
                                    // handle language change
                                    const _language = LANGUAGES[lan]
                                    i18n.changeLanguage(_language)
                                    return handleChange(lan as Language)
                                }}
                            >
                                {t(`Languages:${lan}`)}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GdprNotice
