import React, { useRef } from 'react'
import moment from 'moment'
import { $ } from 'react-jquery-plugin'
import { getUserIdFromToken } from '../../../../../../auth'
import {
    AttendeeRole,
    ReactionType,
} from '../../../../../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_posts_comments,
} from '../../../../../../generated/JoinRoom'
import { COMMENT_DELETE_DURATION } from '../../../../../common/constant'
import { useComment } from './useComment'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../../../../AppLogger'
import CommentReaction from './reaction/Reaction'
import { getAllVotes, getComputedVotes } from '../reactionHelper'
import { useSwipeable } from 'react-swipeable'
import {
    EventAction,
    EventCategory,
    gaEvent,
} from '../../../../../common/utilis'

interface Props {
    data: JoinRoom_roomAttendee_room_posts_comments
    nickName: string | null | undefined
    joinKey: string
    dataJoinRoom: JoinRoom
    postId: string
    setRoomInfo: Function
    role: AttendeeRole
}

const logger = AppLogger.getInstance()
const Comment: React.FC<Props> = ({
    data,
    nickName,
    joinKey,
    dataJoinRoom,
    postId,
    setRoomInfo,
    role,
}) => {
    const { t } = useTranslation(['Room'])
    const threadEntryRef = useRef<HTMLDivElement>(null)
    // used for optimistic update
    const classListMap = useRef<
        Map<ReactionType, React.RefObject<HTMLDivElement>>
    >(new Map())

    const computedVotesRef = useRef<HTMLDivElement>(null)
    const allVotesRef = useRef<HTMLDivElement>(null)

    const userId = getUserIdFromToken()!
    const commentId = data.id

    const getVotesText = (count: number) => {
        const key = count < 2 ? `Votes${count}` : 'Votes'
        return t(key)
    }

    const {
        deleteComment,
        deleteCommentStatus,
        publishComment,
        updateCommentStatus,
    } = useComment(
        joinKey,
        userId,
        dataJoinRoom,
        postId,
        commentId,
        allVotesRef,
        computedVotesRef,
        getVotesText,
        nickName,
        setRoomInfo,
        role
    )

    const isMyComment = data.createdBy.id === userId
    const authorName = isMyComment ? t('ByMe') : data.nickName

    const toggleShowOptions = () => {
        if (threadEntryRef && threadEntryRef.current) {
            threadEntryRef.current.classList.toggle('switch-options-on')
        }
    }

    const handleDelete = async () => {
        if (deleteCommentStatus.error) {
            // TODO handle error
            logger.error(deleteCommentStatus.error)
        }
        if (threadEntryRef && threadEntryRef.current) {
            threadEntryRef.current.classList.toggle('switch-remove')
            setTimeout(async () => {
                $('#' + data.id).slideUp(COMMENT_DELETE_DURATION)
                try {
                    await deleteComment()
                } catch (e) {
                    logger.error(`Error while deleting a Comment `, e)
                }
            }, COMMENT_DELETE_DURATION)
            setTimeout(() => {
                gaEvent(EventCategory.Comment, EventAction.Delete, data.content)
            }, COMMENT_DELETE_DURATION * 2)
        }
    }

    const handlePublishYes = async () => {
        if (updateCommentStatus.error) {
            // TODO handle error
            logger.error(updateCommentStatus.error)
        } else {
            const update = await publishComment()
            gaEvent(EventCategory.Comment, EventAction.Publish, data.content)
            logger.debug(`handlePublish:`, update)
        }
    }

    const handlePublishNon = async () => {
        if (deleteCommentStatus.error) {
            // TODO handle error
            logger.error(deleteCommentStatus.error)
        } else {
            const postPublishNon = await deleteComment()
            gaEvent(EventCategory.Comment, EventAction.UnPublish, data.content)
            logger.debug(`handlePublishNon:`, postPublishNon)
        }
    }

    const handlersShowOptions = useSwipeable({
        onSwipedLeft: (eventData) => {
            eventData.event.preventDefault()
            eventData.event.stopPropagation()

            if (
                isMyComment ||
                dataJoinRoom.roomAttendee?.role === AttendeeRole.Admin
            ) {
                toggleShowOptions()
            } else {
                return
            }
        },
        onSwipedRight: (eventData) => {
            eventData.event.preventDefault()
            eventData.event.stopPropagation()
            if (
                isMyComment ||
                dataJoinRoom.roomAttendee?.role === AttendeeRole.Admin
            ) {
                toggleShowOptions()
            } else {
                return
            }
        },
        delta: 5,
        preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
        trackTouch: true, // track touch input
        trackMouse: false, // track mouse input
        rotationAngle: 0,
    })

    const _computedVotes = getComputedVotes(data.reactions)
    // add the class switch-to-moderate at the thread-comment when the comment is not published
    return (
        <div {...handlersShowOptions}>
            <div
                className={`thread-comment  ${
                    isMyComment === true ? 'flag-my-comment' : ''
                } ${data.isPublished === false ? 'switch-to-moderate' : ''}`}
                ref={threadEntryRef}
            >
                <ul className="content-buttons">
                    <li
                        className="button button-delete"
                        data-delete="comment"
                        onClick={handleDelete}
                    >
                        <p>{t('Delete')}</p>
                    </li>
                </ul>
                <div className="comment-content">
                    <p className="message">{data.content}</p>
                    <div className="button-more" onClick={toggleShowOptions}>
                        <p>
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                            >
                                <line
                                    x1="95"
                                    y1="0"
                                    x2="95"
                                    y2="20"
                                    vectorEffect="non-scaling-stroke"
                                />
                                <line
                                    x1="95"
                                    y1="80"
                                    x2="95"
                                    y2="100"
                                    vectorEffect="non-scaling-stroke"
                                />
                                <line
                                    x1="95"
                                    y1="40"
                                    x2="95"
                                    y2="60"
                                    vectorEffect="non-scaling-stroke"
                                />
                            </svg>
                        </p>
                    </div>
                    <div className="voting">
                        <CommentReaction
                            allVotesRef={allVotesRef}
                            computedVotesRef={computedVotesRef}
                            joinKey={joinKey}
                            baseClassName="hand down"
                            classListMap={classListMap}
                            comment={data}
                            reactionType={ReactionType.Dislike}
                            nickName={nickName}
                            dataJoinRoom={dataJoinRoom}
                        ></CommentReaction>
                        <div className="count-vote">
                            <div
                                className="count-average"
                                ref={computedVotesRef}
                            >
                                {_computedVotes > 0
                                    ? `+${_computedVotes}`
                                    : _computedVotes}
                            </div>
                            <div className="count-total" ref={allVotesRef}>
                                <span>{getAllVotes(data.reactions)}</span>{' '}
                                {getVotesText(getAllVotes(data.reactions))}
                            </div>
                        </div>
                        <CommentReaction
                            allVotesRef={allVotesRef}
                            computedVotesRef={computedVotesRef}
                            joinKey={joinKey}
                            baseClassName="hand up"
                            classListMap={classListMap}
                            comment={data}
                            reactionType={ReactionType.Like}
                            nickName={nickName}
                            dataJoinRoom={dataJoinRoom}
                        ></CommentReaction>
                    </div>
                    <div className="content-footer">
                        <div
                            className="button-moderate no"
                            onClick={handlePublishNon}
                        >
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                            >
                                <line
                                    x1="0"
                                    y1="0"
                                    x2="100"
                                    y2="100"
                                    vectorEffect="non-scaling-stroke"
                                />
                                <line
                                    x1="0"
                                    y1="100"
                                    x2="100"
                                    y2="0"
                                    vectorEffect="non-scaling-stroke"
                                />
                            </svg>
                        </div>
                        <div className="credits">
                            <p>
                                {moment(data.createdAt).format(
                                    'DD/MM/YYYY HH:mm'
                                )}
                                {authorName && `, ${authorName}`}
                            </p>
                        </div>
                        <div
                            className="button-moderate yes"
                            onClick={handlePublishYes}
                        >
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M0,50L40,100L100,0"
                                    vectorEffect="non-scaling-stroke"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comment
