import { ReactionType } from '../../../../../generated/globalTypes'

/**
 * Returns the checked reaction
 * @param classListMap
 * @param className
 * @returns
 */
export const getCheckedReaction = (
    classListMap: React.MutableRefObject<
        Map<ReactionType, React.RefObject<HTMLDivElement>>
    >,
    className: string
) => {
    let reactionType: ReactionType | null = null
    if (classListMap.current) {
        for (let entry of Array.from(classListMap.current.entries())) {
            let key = entry[0]
            let value = entry[1]
            if (
                value.current &&
                value.current.classList.contains(className.trim())
            ) {
                reactionType = key
                break
            }
        }
    }
    return reactionType
}

/**
 * Add the provided className to the given reaction
 * @param reaction
 * @param classListMap
 * @param className
 * @returns
 */
export const addReaction = (
    reaction: ReactionType,
    classListMap: React.MutableRefObject<
        Map<ReactionType, React.RefObject<HTMLDivElement>>
    >,
    className: string
) => {
    const refClassListMap = classListMap.current.get(reaction)
    if (refClassListMap?.current) {
        if (refClassListMap?.current.classList.contains(className.trim())) {
            return
        }
        refClassListMap?.current?.classList.add(className.trim())
    }
}

/**
 * remove the provided className from the given reaction
 * @param reaction
 * @param classListMap
 * @param className
 */
export const removeReaction = (
    reaction: ReactionType,
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >,
    className: string
) => {
    const refClassListMap = classListMap.current.get(reaction)

    if (refClassListMap?.current) {
        if (refClassListMap.current.classList.contains(className.trim())) {
            refClassListMap?.current.classList.toggle(className.trim())
        }
    }
}

export const getComputedVotes = (
    reactions: { reactionType: ReactionType }[],
    optimisticLike = 0,
    optimisticDislike = 0
) => {
    const likes = reactions.filter((r) => r.reactionType === ReactionType.Like)
    const dislikes = reactions.filter(
        (r) => r.reactionType === ReactionType.Dislike
    )

    let likesLen = likes.length + optimisticLike
    let dislikesLen = dislikes.length + optimisticDislike

    const res = likesLen - dislikesLen
    return res
}

export const getAllVotes = (reactions: { reactionType: ReactionType }[]) => {
    return reactions.length
}

export const displayComputedVotes = (
    computedVotesRef: React.RefObject<HTMLDivElement>,
    _computedVotes: number
) => {
    if (computedVotesRef && computedVotesRef.current) {
        computedVotesRef.current.innerText = String(
            _computedVotes > 0 ? `+${_computedVotes}` : _computedVotes
        )
    }
}

export const displayAllVotes = (
    allVotesRef: React.RefObject<HTMLSpanElement>,
    _allVotes: number
) => {
    if (allVotesRef && allVotesRef.current) {
        allVotesRef.current.innerText = String(_allVotes)
    }
}
