import i18next from 'i18next'
import jwtDecode from 'jwt-decode'
import { $ } from 'react-jquery-plugin'
import { AppLogger } from './AppLogger'
import {
    BASE_URL,
    GDPR_NOTICE_KEY,
    GDPR_NOTICE_OK,
    LANGUAGES,
    LANGUAGE_KEY,
    THEME_KEY,
    TOKEN_KEY,
} from './components/common/constant'
import { Theme } from './generated/globalTypes'
import { UserData_user } from './generated/UserData'

const logger = AppLogger.getInstance()

export function getUserIdFromToken() {
    const _token = localStorage.getItem(TOKEN_KEY)!
    try {
        return jwtDecode<{ userId: string }>(_token).userId
    } catch (e) {
        logger.error(`Error while decoding JWT token:${_token}`)
    }
}

export function getAccessToken() {
    logger.debug(`accessTokenKey:${TOKEN_KEY}`)
    return localStorage.getItem(TOKEN_KEY)
}

interface BaseAuth {
    ok: boolean
    user?: UserData_user
}
interface Login extends BaseAuth {
    accessToken?: string
}

export async function login(
    reCAPTCHAToken: string,
    theme?: Theme
): Promise<boolean> {
    logger.debug(`login token:${reCAPTCHAToken}`)
    const response = await fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({ token: reCAPTCHAToken, theme }),
    })
    if (!response.ok) {
        return false
    }
    const res = (await response.json()) as Login
    logger.debug(`login res:${JSON.stringify(res, null, 3)}`)
    if (res.ok) {
        localStorage.setItem(TOKEN_KEY, res.accessToken!)
        setUserPreferences(res.user!, theme)
    }
    return res.ok
}

export async function validateUser(
    user: string,
    theme?: Theme
): Promise<boolean> {
    logger.debug(`user:${user}`)
    const response = await fetch(`${BASE_URL}/auth/validate`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({ user }),
    })

    if (!response.ok) {
        return false
    }
    const res = (await response.json()) as BaseAuth
    if (res.ok) {
        setUserPreferences(res.user!, theme)
    }
    return res.ok
}

export async function validateProfile(profile: string): Promise<boolean> {
    logger.debug(`profile:${profile}`)
    const response = await fetch(`${BASE_URL}/auth/validateProfile`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ profile }),
    })
    if (!response.ok) {
        return false
    }
    const res = (await response.json()) as Login
    if (res.ok) {
        localStorage.setItem(TOKEN_KEY, res.accessToken!)
        setUserPreferences(res.user!)
    }
    return res.ok
}

export async function validateJoinKey(joinKey: string): Promise<boolean> {
    logger.debug(`joinKey:${joinKey}`)
    const response = await fetch(`${BASE_URL}/room/validateJoinKey`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            authorization: localStorage.getItem(TOKEN_KEY) || '',
        },
        body: JSON.stringify({ joinKey }),
    })
    if (!response.ok) {
        return false
    }
    const { ok } = await response.json()
    return ok
}

export const setUserPreferences = (user: UserData_user, theme?: Theme) => {
    const preferredTheme = theme ? theme : user.theme
    localStorage.setItem(LANGUAGE_KEY, user.language)
    localStorage.setItem(THEME_KEY, preferredTheme)

    if (preferredTheme === Theme.SpeakUp) {
        $('html').removeClass()
    } else {
        if ($('html').hasClass(`theme-${preferredTheme}`)) return

        $('html').removeClass().addClass(`theme-${preferredTheme}`)
    }

    // setup the language
    const _language = LANGUAGES[user.language]
    i18next.changeLanguage(_language)
}

export const resetUserPreferredTheme = () => {
    const preferredTheme = localStorage.getItem(THEME_KEY)
    if (preferredTheme === Theme.SpeakUp) {
        $('html').removeClass()
    } else {
        if ($('html').hasClass(`theme-${preferredTheme}`)) return

        $('html').removeClass().addClass(`theme-${preferredTheme}`)
    }
}

export const checkGdprAcceptance = () => {
    const gdprNotice = localStorage.getItem(GDPR_NOTICE_KEY)
    if (gdprNotice && gdprNotice === GDPR_NOTICE_OK) {
        $('#root').removeClass('switch-gdpr')
    } else {
        if ($('#root').hasClass('switch-gdpr')) return

        $('#root').removeClass().addClass('switch-gdpr')
    }
}

export const setGdprAcceptance = () => {
    localStorage.setItem(GDPR_NOTICE_KEY, GDPR_NOTICE_OK)
    $('#root').removeClass('switch-gdpr')
}
