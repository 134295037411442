import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            Home: {
                Profile: 'User settings',
                ProfileIdCopied: 'ID copied',
                Language: 'Language',
                ThemeColor: 'Theme',
                JoinExistingRoom: 'Join existing room',
                Join: 'Join',
                CreateNewRoom: 'Create new room',
                RoomNamePlaceholder: 'Room name',
                RoomNumberPlaceholder: 'Room key',
                ImportIDPlaceholder: 'User ID',
                ImportID: 'Change user',
                Load: 'Change',
                ShowCurrentID: 'User ID',
                ImportIDCaption: `By changing user, all settings and rooms from the current user will be replaced.
                Write down the current user ID to be able to reload it in the
                future. Otherwise it will be lost forever.`,
                TemporaryRoom: 'Temporary',
                TemporaryRoomNote: 'The room closes after 24h',
                Nicknames: 'Nicknames',
                NicknamesNote: 'The room requires nicknames',
                Anonymous: 'Anonymous',
                AnonymousNote: 'When disabled, nicknames are required',
                ModeratedRoom: 'Moderated',
                ModeratedRoomNote: 'The messages in the room must be validated',
                PollOnlyRoom: 'Poll only room',
                PollOnlyRoomNote: 'Guest will not be allowed to post messages',
                Rooms: 'Rooms',
                AdminBadge: 'admin',
                Create: 'Create',
                Cancel: 'Cancel',
                Export: 'Export',
                Leave: 'Leave',
                Delete: 'Delete',
                Reset: 'Reset',
                ConfirmDeletion: 'Confirm deletion',
                ConfirmClear: 'Confirm delete all messages',
                ConfirmLeave: 'Confirm leave',
                ID: 'My ID',
                loading: 'loading',
            },
            Themes: {
                SpeakUp: 'SpeakUp',
                UniNE: 'UniNE',
                Unil: 'Unil',
                UniGE: 'UniGE',
                EPFL: 'EPFL',
            },
            Languages: {
                German: 'Deutsch',
                French: 'Français',
                English: 'English',
            },
            Room: {
                AdminBadge: 'admin',
                RoomInformation: 'Room settings',
                Number: 'Key',
                Expires: 'Closes',

                Messages: 'messages',
                Comments: 'comments',

                Messages0: 'messages',
                Comments0: 'comments',

                Messages1: 'message',
                Comments1: 'comment',

                CommentsAllowed: 'Allow posting',
                MessageAutomaticFlow: 'Automatic flow',
                GenerateAdminCode: 'Admin key',
                GenerateGuestCode: 'Room link',
                GuestUrlCopied: 'Link copied',
                ExportAsCSV: 'Export data',
                MessagePlaceholder: 'New message',
                CommentPlaceholder: 'New comment',
                LeaveTheRoom: 'Leave room',
                ClearTheRoom: 'Delete all messages',
                DeleteTheRoom: 'Delete room',
                Recent: 'Recent',
                Best: 'Best',
                Moderate: 'Moderate',
                Load: 'Load',
                NewMessages: 'See more',
                Favorite: 'Favorite',
                Delete: 'Delete',
                Votes: 'votes',
                Votes0: 'votes',
                Votes1: 'vote',
                ByMe: 'by me',
                CommentingTemporaryClosed: 'Commenting temporary closed',
                Submit: 'Submit',
                ThankYouForVoting: 'Thank you for voting',
                ThePollIsClosed: 'The poll is closed',
                Participants: 'participants',
                ClosePoll: 'Open poll',
                ShowResults: 'Show results',
                PostingTemporaryClosed: 'Posting disabled',
                PostingTemporaryClosedAdmin: 'Posting disabled for guests',
                PostingTemporaryClosedPollAdmin: 'Poll open, posting disabled',
                NumberOfChoices: 'Number of choices',
                MultipleResponses: 'Multiple selection',
                Solutions: 'Solution enabled',
                ImmediateResult: 'Immediate result',
                VotingAnswer: 'correct',
                VotingAnswerCorrect: 'Correct answer',
                VotingAnswerWrong: 'Wrong answer',
                Nickname: 'Nickname',
                FlashMessage: 'Message sent',
                GetQRCode: 'Get QR Code',
                CopyPostToClipboard: 'Click to copy this post url to clipboard',
                CopyPollToClipboard: 'Click to copy this poll url to clipboard',
            },

            Export: {
                Score: 'Score',
                RoomSummary: 'Room Summary',
                RoomId: 'Id',
                RoomName: 'Name',
                RoomAdminKey: 'Admin_key',
                RoomNumber: 'Key',
                RoomTemporary: 'Temporary',
                RoomNicknames: 'Nicknames',
                RoomAnomymous: 'Anonymous',
                RoomModerated: 'Moderated',
                RoomCreatedBy: 'Created_by',
                RoomCreatedAt: '	Created_at',
                RoomUsers: 'Users',
                RoomPosts: 'Posts',
                RoomPolls: 'Polls',

                PostCommentSummary: 'Posts & Comments',
                PostCommentId: 'Id',
                CommentPostId: 'Parent_id',
                PostCommentContent: 'Content',
                PostCommentPublished: 'Published',
                PostCommentFavorite: 'Favorite',
                PostCommentCreatedBy: 'Created_by',
                PostCommentCreatedAt: 'Created_at',
                PostCommentNickName: 'Nickname',
                PostCommentComments: 'Comments',
                PostCommentLikes: 'Likes',
                PostCommentDislikes: 'Dislikes',

                PollSummary: 'Poll Summary',
                PollId: 'Id',
                PollContent: 'Content',
                PollMultipleResponses: 'Multiple_selection',
                PollImmediateResults: 'Immediate_results',
                PollOpened: 'Opened',
                PollResults: 'Results',
                PollCreatedBy: 'Created_by',
                PollCreatedAt: 'Created_at',
                PollNickName: 'Nickname',
                PollOptions: 'Options',
                PollParticipants: 'Participants',
                PollSolutions: 'Solutions',
                PollOptionProgressParticipants:
                    'Option - Progress - Participants',

                actions: 'actions',
                UserSummary: 'User Summary',
            },
            GdprNotice: {
                TermsConditions: 'SpeakUp privacy policy',
                NoPersonalDataCollected: 'No personal data collected',
                ServersInCH: 'Servers in Switzerland',
                DataUsage: 'Data only used for research',
                UseAbuse: 'Use it, don’t abuse it!',
                ReadFurther: 'Read further',
                CollectionInformation: 'Collection of Information',
                WhenInUse:
                    ' When you access or use SpeakUp, the following data is stored on the SpeakUp servers:',
                UserIdentifierParagraph: ` User Identifier: an anonymous identifier is
                associated with your SpeakUp client
                (browser, or device). This identifier is
                required to give users updates about their
                actions (e.g., posts, likes). It can be
                deleted by clearing the browser cache or
                deleting the mobile app.`,
                UsageDataParagraph: ` Usage data: SpeakUp stores user actions such
                as when users post messages, like, dislike
                or delete messages, or provide answers to
                polls. Each piece of usage data is
                associated with a timestamp and a user
                identifier.`,
                UserGeneratedDataParagraph: `User generated data: SpeakUp stores user
                generated data such as rooms, messages,
                comments, polls, and votes. Each user
                generated piece of data is associated with a
                timestamp and a user identifier.`,
                NicknamesMoodleParagraph: `Nicknames and Moodle: in rooms embedded in
                Moodle that require nicknames, the Moodle
                user name will be used as nickname. With
                this setting, the app loses its anonymity.`,
                UseOfInformation: 'Use of Information',
                UseOfInformationParagraph: `SpeakUp servers are based in Switzerland and the
                information we collect is governed by Swiss law.
                We may use collected information for academic
                research purposes.`,
                SharingOfInformation: 'Sharing of Information',
                SharingOfInformationParagraph: `We may share anonymous usage information with
                other researchers`,
                LimitationOfUsage: 'Limitation of usage',
                LimitationOfUsageParagraph: `SpeakUp cannot be used for discussions related
                to illicit activities or any activity against
                applicable institutional regulations. Users are
                solely responsible for the comments they post
                and the rooms they create.`,
                CommunityGuidelines: 'Community Guidelines',
                CommunityGuidelinesL1: `Help us create a positive place where everyone
                can express themselves.`,
                CommunityGuidelinesL2: 'We do not allow:',

                NoHarassment: 'Harassment/Bullying',
                NoRacism: 'Racism/Discrimination/Hate speech',
                NoOffensive: 'Offensive language',
                NoSpamming: 'Spamming',
                NoSecurityAbuse: 'Abuse of security',
                IAgreeBtn: 'I agree',
            },
            Error: {
                Intro: 'Oh, sorry',
                MainText: 'Something bad happened during the load of this page',
                RedirectNotice: 'You will be redirected to login page',
                Seconds: 'Seconds',
            },
            Notification: {
                Title: 'New mobile app',
                Text1: `We are building a brand new mobile app to be released this summer.`,
                Text2: `WARNING: This new version will use a different user ID,
                To ensure that you will find your rooms after you make the update, you MUST
                write down your current user ID and used it in the new version.`,
            },
        },
        fr: {
            Home: {
                Profile: 'Votre profil',
                ProfileIdCopied: 'Numéro copié',
                Language: 'Langue',
                ThemeColor: 'Thème',
                JoinExistingRoom: 'Rejoindre une salle existante',
                Join: 'Rejoindre',
                CreateNewRoom: 'Créer une nouvelle salle',
                RoomNamePlaceholder: 'Nom de la salle',
                RoomNumberPlaceholder: 'Clé de la salle',
                ImportID: 'Changer de profil',
                ShowCurrentID: 'Numéro de profil',
                ImportIDCaption: `En changeant de profil, tous les réglages et les salles du profil actuel seront remplacés. Notez le numéro du profil actuel pour pouvoir le retrouver dans le futur. Sinon, il sera perdu à jamais.`,
                ImportIDPlaceholder: 'Numéro de profil',
                Load: 'Changer',
                TemporaryRoom: 'Temporaire',
                TemporaryRoomNote: 'La salle se ferme après 24h',
                Nicknames: 'Surnoms',
                NicknamesNote: 'La salle nécessite des pseudos',
                Anonymous: 'Anonyme',
                AnonymousNote: 'Si désactivé, un pseudo est requis',
                ModeratedRoom: 'Modérée',
                ModeratedRoomNote: 'Les messages doivent être validés',
                PollOnlyRoom: 'Salle de sondage uniquement',
                PollOnlyRoomNote:
                    'Les invités ne seront pas autorisés à publier des messages',
                Rooms: 'Salles',
                AdminBadge: 'admin',
                Create: 'Créer',
                Cancel: 'Annuler',
                Export: 'Exporter',
                Leave: 'Quitter',
                Delete: 'Supprimer',
                Reset: 'Réinitialiser',
                ConfirmDeletion: 'Confirmer la suppression',
                ConfirmClear: 'Confirmer effacer tous les messages',
                ConfirmLeave: 'Confirmer le départ',
                ID: 'Mon Identifiant',
                loading: 'Chargement',
            },
            Themes: {
                SpeakUp: 'SpeakUp',
                UniNE: 'UniNE',
                Unil: 'Unil',
                UniGE: 'UniGE',
                EPFL: 'EPFL',
            },
            Languages: {
                German: 'Deutsch',
                French: 'Français',
                English: 'English',
            },
            Room: {
                AdminBadge: 'admin',
                RoomInformation: 'Détails de la salle',
                Number: 'Clé',
                Expires: 'Fermeture',
                Messages: 'messages',
                Comments: 'commentaires',
                CommentsAllowed: 'Messages activés',
                MessageAutomaticFlow: 'Flux automatique',
                GenerateAdminCode: 'Clé admin',
                GenerateGuestCode: 'Lien à partager',
                GuestUrlCopied: 'Lien copié',

                ExportAsCSV: 'Exporter',
                MessagePlaceholder: 'Nouveau message',
                CommentPlaceholder: 'Nouveau commentaire',
                LeaveTheRoom: 'Quitter',
                ClearTheRoom: 'Effacer tous les messages',
                DeleteTheRoom: 'Supprimer',
                Recent: 'Récents',
                Best: 'Meilleurs',
                Moderate: 'Modération',
                Load: 'Charger',
                NewMessages: 'Voir la suite',
                Favorite: 'Favori',
                Delete: 'Supprimer',
                Votes: 'votes',
                ByMe: 'par moi',
                CommentingTemporaryClosed: 'Messages désactivés',
                Submit: 'Soumettre',
                ThankYouForVoting: "Merci d'avoir voté",
                ThePollIsClosed: 'Le sondage est fermé',
                Participants: 'participants',
                ClosePoll: 'Ouvrir le sondage',
                ShowResults: 'Afficher les résultats',
                PostingTemporaryClosed: 'Messages désactivés',
                PostingTemporaryClosedAdmin: 'Messages désactivés',
                PostingTemporaryClosedPollAdmin:
                    'Sondage ouvert, messages désactivés',
                NumberOfChoices: 'Nombre de choix',
                MultipleResponses: 'Sélection multiple',
                Solutions: 'Réponses corrigées',
                ImmediateResult: 'Résultats immédiats',
                VotingAnswer: 'correct',
                VotingAnswerCorrect: 'Réponse correcte',
                VotingAnswerWrong: 'Réponse fausse',

                Messages0: 'message',
                Comments0: 'commentaire',
                Messages1: 'message',
                Comments1: 'commentaire',
                Votes0: 'vote',
                Votes1: 'vote',
                Nickname: 'Votre pseudo',
                FlashMessage: 'Message envoyé',
                GetQRCode: 'Obtenir le code QR',
                CopyPostToClipboard: `Cliquez pour copier l'URL de ce message dans le clipboard`,
                CopyPollToClipboard: `Cliquez pour copier l'URL de ce sondage dans le clipboard`,
            },
            Export: {
                Score: 'Score',
                RoomSummary: 'Room Summary',
                RoomId: 'Id',
                RoomName: 'Name',
                RoomAdminKey: 'Admin_key',
                RoomNumber: 'Key',
                RoomTemporary: 'Temporary',
                RoomNicknames: 'Nicknames',
                RoomAnomymous: 'Anonymous',
                RoomModerated: 'Moderated',
                RoomCreatedBy: 'Created_by',
                RoomCreatedAt: '	Created_at',
                RoomUsers: 'Users',
                RoomPosts: 'Posts',
                RoomPolls: 'Polls',

                PostCommentSummary: 'Posts & Comments',
                PostCommentId: 'Id',
                CommentPostId: 'Parent_id',
                PostCommentContent: 'Content',
                PostCommentPublished: 'Published',
                PostCommentFavorite: 'Favorite',
                PostCommentCreatedBy: 'Created_by',
                PostCommentCreatedAt: 'Created_at',
                PostCommentNickName: 'Nickname',
                PostCommentComments: 'Comments',
                PostCommentLikes: 'Likes',
                PostCommentDislikes: 'Dislikes',

                PollSummary: 'Poll Summary',
                PollId: 'Id',
                PollContent: 'Content',
                PollMultipleResponses: 'Multiple_selection',
                PollImmediateResults: 'Immediate_results',
                PollOpened: 'Opened',
                PollResults: 'Results',
                PollCreatedBy: 'Created_by',
                PollCreatedAt: 'Created_at',
                PollNickName: 'Nickname',
                PollOptions: 'Options',
                PollParticipants: 'Participants',
                PollSolutions: 'Solutions',
                PollOptionProgressParticipants:
                    'Option - Progress - Participants',

                actions: 'actions',
                UserSummary: 'User Summary',
            },
            GdprNotice: {
                TermsConditions: 'Politique de confidentialité SpeakUp',
                NoPersonalDataCollected: 'Pas de donnée personnelle',
                ServersInCH: 'Serveurs en Suisse',
                DataUsage: 'Données pour la recherche ',
                UseAbuse: `Respect de la communauté`,
                ReadFurther: 'Lire la suite',
                CollectionInformation: `Collecte d'informations`,
                WhenInUse:
                    'Lorsque vous accédez à ou utilisez SpeakUp, les données suivantes sont stockées sur les serveurs de SpeakUp',
                UserIdentifierParagraph: ` Identifiant de l'utilisateur : un identifiant anonyme est associé à votre client SpeakUp (navigateur, ou mobile). Cet identifiant est nécessaire pour vous donner des mises à jour sur vos actions (par exemple, les messages, les "likes").Vous pouvez le supprimer en vidant le cache du navigateur ou en supprimant l'application mobile.`,
                UsageDataParagraph: ` Données d'utilisation : Lorsque vous effectuez une action sur un message (par exemple, aimez, postez, supprimez) nous stockons un descriptif de l’action associée à votre identifiant anonyme.`,
                UserGeneratedDataParagraph: `Données générées par vous :  Les serveurs contiennent des données générées par les utilisatrices et les utilisateurs, telles que les messages des salles, les commentaires, les sondages et les votes. Chaque donnée générée par vous est associée à un temps de création et à votre identifiant anonyme.`,
                NicknamesMoodleParagraph: `Pseudo et Moodle : dans les salles intégrées à Moodle qui nécessitent un pseudo, le nom d'utilisateur de Moodle sera utilisé comme pseudo. Avec cette configuration, l'application perd son anonymat.`,
                UseOfInformation: 'Utilisation des informations',
                UseOfInformationParagraph: `Les serveurs de SpeakUp sont basés en Suisse et les informations recueillies sont régies par le droit suisse. Les informations collectées peuvent être utilisées à des fins de recherche académique.`,
                SharingOfInformation: 'Partage des informations',
                SharingOfInformationParagraph: `Les informations d'utilisation anonymes peuvent être partagées avec d'autres groupes de recherche académique. Google Analytics est utilisé dans SpeakUp pour nous fournir d'autres analyses en temps réel. Pour plus d'informations sur les pratiques de Google Analytics en matière de confidentialité, veuillez consulter le site http://www.google.com/intl/en/policies/.`,
                LimitationOfUsage: `Limitation de l'utilisation`,
                LimitationOfUsageParagraph: `SpeakUp ne peut être utilisé pour des discussions liées à des activités illicites ou à toute activité contraire aux règlements institutionnels en vigueur. Les utilisatrices et les utilisateurs sont seul-e-s responsables des commentaires qu'elles ou ils publient et des espaces qu'elles ou ils créent.`,
                CommunityGuidelines: 'Directives de la communauté',
                CommunityGuidelinesL1: `Aidez-nous à créer un lieu positif où chacun-e peut s'exprimer.`,
                CommunityGuidelinesL2: `Nous n'autorisons pas :`,

                NoHarassment: `Le harcèlement / l'intimidation`,
                NoRacism:
                    'Le racisme / la discrimination / les discours haineux',
                NoOffensive: 'Les insultes',
                NoSpamming: 'Le spam',
                NoSecurityAbuse: 'Les abus de sécurité',
                IAgreeBtn: `J'accepte`,
            },
            Error: {
                Intro: 'Oh, désolé',
                DefaultText:
                    "Une erreur s'est produite lors du chargement de cette page",
                JoinText: 'Il y a un problème avec le numéro de la salle',
                RedirectNotice: 'Vous serez redirigé vers la page de connexion',
                Seconds: 'Seconds',
            },

            Notification: {
                Title: 'Nouvelle application mobile',
                Text1: `Nous sommes en train de créer une toute nouvelle application mobile qui sera lancée cet été.`,

                Text2: `AVERTISSEMENT: Cette nouvelle version utilisera un identifiant d'utilisateur différent,
                Pour vous assurer que vous retrouverez vos salles après avoir effectué la mise à jour, vous DEVEZ
                noter votre ID utilisateur actuel et l'utiliser dans la nouvelle version.`,
            },
        },
        de: {
            Home: {
                Profile: 'Benutzereinstellungen',
                ProfileIdCopied: 'ID kopiert',
                Language: 'Sprache',
                ThemeColor: 'Farbe',
                JoinExistingRoom: 'Raum beitreten',
                Join: 'Beitreten',
                CreateNewRoom: 'Raum erstellen',
                RoomNamePlaceholder: 'Raumname',
                RoomNumberPlaceholder: 'Raumschlüssel',
                ImportIDPlaceholder: 'Benutzer-ID',
                ImportID: 'Benutzer ändern',
                Load: 'Ändern',
                ShowCurrentID: 'Benutzer-ID',
                ImportIDCaption:
                    'Durch einen Benutzerwechsel werden alle Einstellungen und Räume des aktuellen Benutzers ersetzt. Notieren Sie sich die aktuelle Benutzer-ID, um sie wider in Zukunft aufrufen zu können. Sonst ist es für immer verloren.',
                TemporaryRoom: 'Temporär',
                TemporaryRoomNote: 'Der Raum schließt nach 24h',
                Nicknames: 'Nicknames',
                NicknamesNote: 'Der Raum erfordert Nicknames',
                Anonymous: 'Anonym',
                AnonymousNote: 'Wenn deaktiviert, sind Spitznamen erforderlich',
                ModeratedRoom: 'Moderiert',
                ModeratedRoomNote:
                    'Die Nachrichten im Raum müssen validiert werden',
                Rooms: 'Räume',
                AdminBadge: 'admin',
                Create: 'Erstellen',
                Cancel: 'Abbrechen',
                Export: 'Exportieren',
                Leave: 'Verlassen',
                Reset: 'Zurücksetzen',
                Delete: 'Löschen',
                ConfirmDeletion: 'Löschen bestätigen',
                ConfirmClear: 'Reset bestätigen',
                ConfirmLeave: 'Verlassen bestätigen ',
                ID: 'Meine ID',
                loading: 'laden',
            },
            Themes: {
                SpeakUp: 'SpeakUp',
                UniNE: 'UniNE',
                Unil: 'Unil',
                UniGE: 'UniGE',
                EPFL: 'EPFL',
            },
            Languages: {
                German: 'Deutsch',
                French: 'Français',
                English: 'English',
            },
            Room: {
                AdminBadge: 'admin',
                RoomInformation: 'Raumeinstellungen',
                Number: 'Schlüssel',
                Expires: 'Schließt',

                Messages: 'Nachrichten',
                Comments: 'Kommentare',

                Messages0: 'Nachrichten',
                Comments0: 'Kommentare',

                Messages1: 'Nachricht',
                Comments1: 'Kommentar',

                CommentsAllowed: 'Posten zulassen',
                MessageAutomaticFlow: 'Automatischer Flow',
                GenerateAdminCode: 'Adminschlüssel',
                GenerateGuestCode: 'Raumlink',
                GuestUrlCopied: 'Link kopiert',
                ExportAsCSV: 'Daten exportieren',
                MessagePlaceholder: 'Neue Nachricht',
                CommentPlaceholder: 'Neuer Kommentar',
                LeaveTheRoom: 'Raum verlassen',
                ClearTheRoom: 'Raum zurücksetzen',
                DeleteTheRoom: 'Raum löschen',
                Recent: 'Neuste',
                Best: 'Beste',
                Moderate: 'Moderieren',
                Load: 'Load',
                NewMessages: 'Mehr sehen',
                Favorite: 'Favorit',
                Delete: 'Löschen',
                Votes: 'Stimmen',
                Votes0: 'Stimmen',
                Votes1: 'Stimme',
                ByMe: 'von mir',
                CommentingTemporaryClosed:
                    'Kommentieren vorübergehend geschlossen',
                Submit: 'Absenden',
                ThankYouForVoting: 'Danke für Ihre Stimme',
                ThePollIsClosed: 'Die Umfrage ist geschlossen',
                Participants: 'Teilnehmer',
                ClosePoll: 'Umfrage öffnen',
                ShowResults: 'Ergebnisse anzeigen',
                PostingTemporaryClosed: 'Posting deaktiviert',
                PostingTemporaryClosedAdmin: 'Posting für Gäste deaktiviert',
                PostingTemporaryClosedPollAdmin:
                    'Umfrage offen, Posting deaktiviert',
                NumberOfChoices: 'Anzahl der Auswahlmöglichkeiten',
                MultipleResponses: 'Mehrfachauswahl',
                Solutions: 'Lösung aktiviert',
                ImmediateResult: 'Sofortiges Ergebnis',
                VotingAnswer: 'richtig',
                VotingAnswerCorrect: 'Richtige Antwort',
                VotingAnswerWrong: 'Falsche Antwort',
                Nickname: 'Nickname',
                FlashMessage: 'Nachricht gesendet',
                GetQRCode: 'QR-Code holen',

                CopyPostToClipboard: `Klicken Sie hier, um die Url dieses Beitrags in die Zwischenablage zu kopieren`,
                CopyPollToClipboard: `Klicken Sie hier, um die Url dieser Umfrage in die Zwischenablage zu kopieren`,
            },

            Export: {
                Score: 'Score',
                RoomSummary: 'Room Summary',
                RoomId: 'Id',
                RoomName: 'Name',
                RoomAdminKey: 'Admin_key',
                RoomNumber: 'Key',
                RoomTemporary: 'Temporary',
                RoomNicknames: 'Nicknames',
                RoomAnomymous: 'Anonymous',
                RoomModerated: 'Moderated',
                RoomCreatedBy: 'Created_by',
                RoomCreatedAt: '	Created_at',
                RoomUsers: 'Users',
                RoomPosts: 'Posts',
                RoomPolls: 'Polls',

                PostCommentSummary: 'Posts & Comments',
                PostCommentId: 'Id',
                CommentPostId: 'Parent_id',
                PostCommentContent: 'Content',
                PostCommentPublished: 'Published',
                PostCommentFavorite: 'Favorite',
                PostCommentCreatedBy: 'Created_by',
                PostCommentCreatedAt: 'Created_at',
                PostCommentNickName: 'Nickname',
                PostCommentComments: 'Comments',
                PostCommentLikes: 'Likes',
                PostCommentDislikes: 'Dislikes',

                PollSummary: 'Poll Summary',
                PollId: 'Id',
                PollContent: 'Content',
                PollMultipleResponses: 'Multiple_selection',
                PollImmediateResults: 'Immediate_results',
                PollOpened: 'Opened',
                PollResults: 'Results',
                PollCreatedBy: 'Created_by',
                PollCreatedAt: 'Created_at',
                PollNickName: 'Nickname',
                PollOptions: 'Options',
                PollParticipants: 'Participants',
                PollSolutions: 'Solutions',
                PollOptionProgressParticipants:
                    'Option - Progress - Participants',

                actions: 'actions',
                UserSummary: 'User Summary',
            },
            GdprNotice: {
                TermsConditions: 'SpeakUp Datenschutzpolitik',
                NoPersonalDataCollected: 'Keine persönlichen Daten erhoben',
                ServersInCH: 'Server in der Schweiz',
                DataUsage: 'Daten nur für Forschung verwendet',
                UseAbuse: 'Benutzen, nicht missbrauchen!',
                ReadFurther: 'Weiterlesen',
                CollectionInformation: 'Sammlung von Informationen',
                WhenInUse: `Wenn Sie auf SpeakUp zugreifen oder es benutzen, werden die folgenden Daten auf den 
SpeakUp-Servern gespeichert:`,
                UserIdentifierParagraph: ` UserID: Eine anonymen UserID wird mit Ihrem SpeakUp-Client (Browser oder Gerät) verknüpft. Dieser UserID wird benötigt, um die Nutzer über ihre Aktionen (z.B. Beiträge, Likes) zu informieren. Dieser kann gelöscht werden, indem der Browser-Cache geleert wird oder indem die Mobile App gelöscht wird.`,
                UsageDataParagraph: `Nutzungsdaten: Wenn Sie eine Aktion auf einer Nachricht durchführen (z.B. Likes, Beiträge, löschen), wird die Aktion mit einem Zeitstempel und einer UserID gespeichert.`,
                UserGeneratedDataParagraph: ` Benutzergenerierte Daten:  Die Server enthalten nutzergenerierte Daten wie Raumnachrichten, Kommentare, Umfragen und Abstimmungen. Jeder von den Nutzern erzeugte Datensatz wird mit einem Zeitstempel und einer UserID gespeichert.`,
                NicknamesMoodleParagraph: `Nicknames und Moodle: In Räumen, die in Moodle eingebettet sind und Nicknames erfordern, wird der Moodle-Benutzername als Nickname verwendet. Mit dieser Einstellung verliert die App ihre Anonymität.`,
                UseOfInformation: 'Verwendung von Informationen',
                UseOfInformationParagraph: `Die SpeakUp-Server befinden sich in der Schweiz und die gesammelten Informationen unterliegen dem Schweizer Recht. Die gesammelten Informationen können für akademische Forschungszwecke verwendet werden.`,
                SharingOfInformation: 'Weitergabe von Informationen',
                SharingOfInformationParagraph: `Anonyme Nutzungsinformationen können mit anderen Forschern teilt werden.
Wir verwenden Google Analytics in unserer Mobil- und Web App, um uns weitere Echtzeit-Analysen zur Verfügung zu stellen. Weitere Informationen über die Datenschutzpraktiken von Google Analytics finden Sie unter http://www.google.com/intl/en/policies/.`,
                LimitationOfUsage: 'Einschränkung der Nutzung',
                LimitationOfUsageParagraph: `SpeakUp kann nicht für Diskussionen über illegale Aktivitäten oder Aktivitäten, die gegen die geltenden institutionellen Vorschriften verstoßen, genutzt werden. Die Nutzer sind allein verantwortlich für die Kommentare, die sie veröffentlichen, und die Räume, die sie erstellen.`,
                CommunityGuidelines: 'Community-Richtlinien',
                CommunityGuidelinesL1: `Helfen Sie uns, einen positiven Ort zu schaffen, an dem sich jeder ausdrücken kann.`,
                CommunityGuidelinesL2: 'Wir erlauben nicht:',

                NoHarassment: 'Belästigung / Mobbing',
                NoRacism: 'Rassismus/Diskriminierung/Hasstiraden',
                NoOffensive: 'Beleidigende Sprache',
                NoSpamming: 'Spamming',
                NoSecurityAbuse: 'Missbrauch der Sicherheit',
                IAgreeBtn: 'Ich stimme zu',
            },
            Error: {
                Intro: 'Oh, sorry',
                MainText: 'Something bad happened during the load of this page',
                RedirectNotice: 'You will be redirected to login page',
                Seconds: 'Seconds',
            },

            Notification: {
                Title: 'Neue Mobile-App',
                Text1: `Wir sind dabei, eine brandneue mobile App zu entwickeln, die diesen Sommer veröffentlicht werden soll.`,

                Text2: `WARNUNG: Diese neue Version wird eine andere Benutzer-ID verwenden,
                Um sicherzustellen, dass Sie Ihre Räume nach der Aktualisierung wiederfinden, MÜSSEN Sie
                Ihre aktuelle Benutzer-ID notieren und sie in der neuen Version verwenden.`,
            },
        },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    // ns: ['translations'],
    // defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
    },

    react: {
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        useSuspense: true,
    },
})

export default i18n
