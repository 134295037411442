import { gql } from '@apollo/client'
import { postDetailFragment, postReactionDetailFragment } from './fragments'

/**
 * QUERIES
 */

/**
 * MUTATIONS
 */
export const POST_CREATE = gql`
    mutation CreateOnePost($data: PostCreateInput!) {
        post: createOnePost(data: $data) {
            ...PostDetail
        }
    }
    ${postDetailFragment}
`

export const POST_DELETE = gql`
    mutation DeleteOnePost($where: PostWhereUniqueInput!) {
        post: deleteOnePost(where: $where) {
            id
        }
    }
`

export const POST_UPDATE = gql`
    mutation UpdateOnePost(
        $data: PostUpdateInput!
        $where: PostWhereUniqueInput!
    ) {
        post: updateOnePost(data: $data, where: $where) {
            ...PostDetail
        }
    }
    ${postDetailFragment}
`

// Reaction

export const POST_REACTION_CREATE = gql`
    mutation CreateOnePostReaction($data: PostReactionCreateInput!) {
        postReaction: createOnePostReaction(data: $data) {
            ...PostReactionDetail
        }
    }
    ${postReactionDetailFragment}
`

export const POST_REACTION_DELETE = gql`
    mutation DeleteOnePostReaction($where: PostReactionWhereUniqueInput!) {
        postReaction: deleteOnePostReaction(where: $where) {
            id
        }
    }
`

export const POST_REACTION_UPDATE = gql`
    mutation UpdateOnePostReaction(
        $data: PostReactionUpdateInput!
        $where: PostReactionWhereUniqueInput!
    ) {
        postReaction: updateOnePostReaction(data: $data, where: $where) {
            ...PostReactionDetail
        }
    }
    ${postReactionDetailFragment}
`

/**
 * SUBSCRIPTIONS
 */
export const POST_CREATE_SUBSCRIPTION = gql`
    subscription PostCreateDataChange($userId: ID!) {
        onPostCreate(userId: $userId) {
            ...PostDetail
        }
    }
    ${postDetailFragment}
`

export const POST_UPDATE_SUBSCRIPTION = gql`
    subscription PostUpdateDataChange($userId: ID!) {
        onPostUpdate(userId: $userId) {
            ...PostDetail
        }
    }
    ${postDetailFragment}
`

export const POST_DELETE_SUBSCRIPTION = gql`
    subscription PostDeleteDataChange($userId: ID!) {
        onPostDelete(userId: $userId) {
            id
        }
    }
`

export const POST_REACTION_CREATE_SUBSCRIPTION = gql`
    subscription PostReactionCreateDataChange($userId: ID!) {
        onPostReactionCreate(userId: $userId) {
            ...PostReactionDetail
        }
    }
    ${postReactionDetailFragment}
`

export const POST_REACTION_UPDATE_SUBSCRIPTION = gql`
    subscription PostReactionUpdateDataChange($userId: ID!) {
        onPostReactionUpdate(userId: $userId) {
            ...PostReactionDetail
        }
    }
    ${postReactionDetailFragment}
`

export const POST_REACTION_DELETE_SUBSCRIPTION = gql`
    subscription PostReactionDeleteDataChange($userId: ID!) {
        onPostReactionDelete(userId: $userId) {
            id
        }
    }
`
