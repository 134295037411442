import { useMutation, useSubscription } from '@apollo/client'
import { AppLogger } from '../../../../../../AppLogger'

import {
    CreateOnePostReaction,
    CreateOnePostReactionVariables,
} from '../../../../../../generated/CreateOnePostReaction'

import {
    DeleteOnePostReaction,
    DeleteOnePostReactionVariables,
} from '../../../../../../generated/DeleteOnePostReaction'
import { ReactionType } from '../../../../../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_posts_reactions,
} from '../../../../../../generated/JoinRoom'
import {
    PostReactionCreateDataChange,
    PostReactionCreateDataChangeVariables,
} from '../../../../../../generated/PostReactionCreateDataChange'
import {
    PostReactionDeleteDataChange,
    PostReactionDeleteDataChangeVariables,
} from '../../../../../../generated/PostReactionDeleteDataChange'
import {
    PostReactionUpdateDataChange,
    PostReactionUpdateDataChangeVariables,
} from '../../../../../../generated/PostReactionUpdateDataChange'

import {
    UpdateOnePostReaction,
    UpdateOnePostReactionVariables,
} from '../../../../../../generated/UpdateOnePostReaction'

import {
    POST_REACTION_CREATE,
    POST_REACTION_UPDATE,
    POST_REACTION_DELETE,
    POST_REACTION_CREATE_SUBSCRIPTION,
    POST_REACTION_DELETE_SUBSCRIPTION,
    POST_REACTION_UPDATE_SUBSCRIPTION,
} from '../../../../../../queries/post'
import { ROOM_JOIN } from '../../../../../../queries/room'
import {
    FIRST_POSTS,
    ORDER_BY_POST,
    AFTER_POST,
    WHERE_POST,
    SESSION_KEY,
    ORDER_BY_PUBLISHED_POST,
} from '../../../../../common/constant'
import { isSameUserSession } from '../../../../../common/utilis'
import { getAllVotes, getComputedVotes } from '../reactionHelper'

const logger = AppLogger.getInstance()

export function usePostReaction(
    userId: string,
    postId: string,
    joinKey: string,
    allVotesRef: React.RefObject<HTMLDivElement>,
    computedVotesRef: React.RefObject<HTMLDivElement>,
    getVotesText: (count: number) => string,
    nickName: string | null | undefined,
    dataJoinRoom: JoinRoom
) {
    /**
     * Mutations
     */

    //Post Reaction CRUD
    const [createPostReaction, createPostReactionStatus] = useMutation<
        CreateOnePostReaction,
        CreateOnePostReactionVariables
    >(POST_REACTION_CREATE)

    const [updatePostReaction, updatePostReactionStatus] = useMutation<
        UpdateOnePostReaction,
        UpdateOnePostReactionVariables
    >(POST_REACTION_UPDATE)

    const [deletePostReaction, deletePostReactionStatus] = useMutation<
        DeleteOnePostReaction,
        DeleteOnePostReactionVariables
    >(POST_REACTION_DELETE)

    /**
     *
     * @param newReactions
     */
    const updatePostVotes = (
        newReactions: JoinRoom_roomAttendee_room_posts_reactions[]
    ) => {
        const _computedVotes = getComputedVotes(newReactions)
        const _computedVotesVal =
            _computedVotes > 0 ? `+${_computedVotes}` : _computedVotes
        if (computedVotesRef.current) {
            computedVotesRef.current.innerText = String(_computedVotesVal)
        }
        const allVotes = getAllVotes(newReactions)
        const voteText = getVotesText(allVotes)
        if (allVotesRef.current) {
            allVotesRef.current.innerHTML = `
                <span >
             ${allVotes}
            </span> ${voteText}
                `
        }
    }

    /**
     * Subscriptions
     */

    // Post Reaction

    /**
    |---------------------------------------------------------
    | Triggered on post reaction create
    | onSubscriptionData: 
    | 1 - update the current joined room by adding to apollo cache the newly added post reaction
    |---------------------------------------------------------
    */
    useSubscription<
        PostReactionCreateDataChange,
        PostReactionCreateDataChangeVariables
    >(POST_REACTION_CREATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const addedPostReaction =
                subscriptionData.data?.onPostReactionCreate

            if (dataJoinRoom && addedPostReaction) {
                const currentPostReactions =
                    dataJoinRoom.roomAttendee?.room.posts.find(
                        (p) => p.id === postId
                    )?.reactions || []
                const args = addedPostReaction.id.split('_')
                const _postId = args ? (args[1] as string) : ''

                const exists = currentPostReactions.find(
                    (pr) =>
                        pr.id === addedPostReaction.id &&
                        pr.reactionType === addedPostReaction.reactionType
                )

                // add reaction when not exist and when same post
                if (_postId === postId && !exists) {
                    logger.debug(
                        `POST_REACTION_CREATE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const postReactions = [
                        ...currentPostReactions,
                        addedPostReaction,
                    ]
                    updatePostVotes(postReactions)
                    const updatedPosts = dataJoinRoom.roomAttendee?.room.posts.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    reactions: postReactions,
                                }
                            }
                            return p
                        }
                    )

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...dataJoinRoom.roomAttendee,
                                room: {
                                    ...dataJoinRoom.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.log(
                        `POST_REACTION_CREATE_SUBSCRIPTION post reaction already added or not the target post`
                    )
                }
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on post reaction update
    | onSubscriptionData: 
    | 1 - update the current joined room by modifying the existing post reaction
    | 2 - editable field is: ReactionType: Like | Dislike
    |---------------------------------------------------------
    */
    useSubscription<
        PostReactionUpdateDataChange,
        PostReactionUpdateDataChangeVariables
    >(POST_REACTION_UPDATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const updatedPostReaction =
                subscriptionData.data?.onPostReactionUpdate
            if (dataJoinRoom && updatedPostReaction) {
                const args = updatedPostReaction.id?.split('_')
                const _postId = args ? (args[1] as string) : ''

                if (
                    _postId === postId &&
                    !isSameUserSession(updatedPostReaction.modifiedBy)
                ) {
                    logger.debug(
                        `POST_REACTION_UPDATE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const myPostReactions =
                        dataJoinRoom.roomAttendee?.room.posts.find(
                            (p) => p.id === postId
                        )?.reactions || []

                    const postReactions = myPostReactions.map((pr) => {
                        if (pr.id === updatedPostReaction.id) {
                            return {
                                ...pr,
                                reactionType: updatedPostReaction.reactionType,
                            }
                        }
                        return pr
                    })

                    const updatedPosts = dataJoinRoom.roomAttendee?.room.posts.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    reactions: postReactions,
                                }
                            }
                            return p
                        }
                    )
                    updatePostVotes(postReactions!)
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...dataJoinRoom.roomAttendee,
                                room: {
                                    ...dataJoinRoom.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `POST_REACTION_UPDATE_SUBSCRIPTION don't update not the target post`
                    )
                }
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on post reaction delete
    | onSubscriptionData: 
    | 1 - update the current joined room by removing from apollo cache the deleted post reaction
    |---------------------------------------------------------
    */
    useSubscription<
        PostReactionDeleteDataChange,
        PostReactionDeleteDataChangeVariables
    >(POST_REACTION_DELETE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const deletedPostReaction =
                subscriptionData.data?.onPostReactionDelete

            if (dataJoinRoom && deletedPostReaction) {
                const args = deletedPostReaction.id?.split('_')
                const _postId = args ? (args[1] as string) : ''

                const myPostReactions =
                    dataJoinRoom.roomAttendee?.room.posts.find(
                        (p) => p.id === postId
                    )?.reactions || []

                if (
                    _postId === postId &&
                    myPostReactions.find(
                        (pr) => pr.id === deletedPostReaction.id
                    )
                ) {
                    logger.debug(
                        `POST_REACTION_DELETE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const postReactions = dataJoinRoom.roomAttendee?.room.posts
                        .find((p) => p.id === postId)
                        ?.reactions!.filter((pr) => {
                            return pr.id !== deletedPostReaction.id
                        })

                    const updatedPosts = dataJoinRoom.roomAttendee?.room.posts.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    reactions: postReactions,
                                }
                            }
                            return p
                        }
                    )
                    updatePostVotes(postReactions!)
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...dataJoinRoom.roomAttendee,
                                room: {
                                    ...dataJoinRoom.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            operation: `DELETE_POST_REACTION_${deletedPostReaction.id}`,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `POST_REACTION_DELETE_SUBSCRIPTION don't delete  already deleted or not the target post`
                    )
                }
            }
        },
    })
    /**
     * useEffect hooks
     */

    return {
        createPostReactionStatus,
        createPostReaction: async (reaction: ReactionType) =>
            await createPostReaction({
                variables: {
                    data: {
                        reactionType: reaction,
                        id: `${userId}_${postId}`,
                        post: {
                            connect: {
                                id: postId,
                            },
                        },
                        createdBy: {
                            connect: {
                                id: userId,
                            },
                        },
                    },
                },

                update: (cache, { data: createPostReactionRes, errors }) => {
                    if (createPostReactionRes && !errors) {
                        const addedPostReaction =
                            createPostReactionRes.postReaction

                        if (dataJoinRoom && addedPostReaction) {
                            const currentPostReactions =
                                dataJoinRoom.roomAttendee?.room.posts.find(
                                    (p) => p.id === postId
                                )?.reactions || []

                            // more security to avoid wired behavior
                            const exists = currentPostReactions.find(
                                (pr) =>
                                    pr.id === addedPostReaction.id &&
                                    pr.reactionType ===
                                        addedPostReaction.reactionType
                            )

                            if (!exists) {
                                let postReactions: JoinRoom_roomAttendee_room_posts_reactions[] = []

                                if (currentPostReactions) {
                                    postReactions = [
                                        ...currentPostReactions,
                                        addedPostReaction,
                                    ]
                                } else {
                                    postReactions = [addedPostReaction]
                                }
                                updatePostVotes(postReactions)
                                const updatedPosts = dataJoinRoom.roomAttendee?.room.posts.map(
                                    (p) => {
                                        if (p.id === postId) {
                                            return {
                                                ...p,
                                                reactions: postReactions,
                                            }
                                        }
                                        return p
                                    }
                                )

                                cache.writeQuery({
                                    query: ROOM_JOIN,
                                    data: {
                                        roomAttendee: {
                                            ...dataJoinRoom.roomAttendee,
                                            room: {
                                                ...dataJoinRoom.roomAttendee
                                                    ?.room,
                                                posts: updatedPosts,
                                            },
                                        },
                                    },
                                    variables: {
                                        joinKey,
                                        nickName,
                                        firstPost: FIRST_POSTS,
                                        orderByPost: ORDER_BY_POST,
                                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                        afterPost: AFTER_POST,
                                        wherePost: WHERE_POST,
                                        filterPollRes: {
                                            userId: {
                                                equals: userId,
                                            },
                                        },
                                    },
                                })

                                logger.debug(
                                    `createPostReaction add newly created reaction`
                                )
                            }
                        }
                    }
                },
            }),
        updatePostReactionStatus,
        updatePostReaction: async (reaction: ReactionType) =>
            await updatePostReaction({
                variables: {
                    data: {
                        reactionType: {
                            set: reaction,
                        },
                        modifiedBy: {
                            set: sessionStorage.getItem(SESSION_KEY),
                        },
                    },
                    where: {
                        userId_postId: {
                            postId,
                            userId,
                        },
                    },
                },
                update: (cache, { data: updatePostReactionRes, errors }) => {
                    if (updatePostReactionRes && !errors) {
                        const updatedPostReaction =
                            updatePostReactionRes.postReaction

                        if (dataJoinRoom && updatedPostReaction) {
                            const myPostReactions =
                                dataJoinRoom.roomAttendee?.room.posts.find(
                                    (p) => p.id === postId
                                )?.reactions || []

                            const targetReaction = myPostReactions.find(
                                (pr) => pr.id === updatedPostReaction.id
                            )

                            if (
                                targetReaction &&
                                targetReaction.reactionType !==
                                    updatedPostReaction.reactionType
                            ) {
                                const postReactions = myPostReactions.map(
                                    (pr) => {
                                        if (pr.id === updatedPostReaction.id) {
                                            return {
                                                ...pr,
                                                reactionType:
                                                    updatedPostReaction.reactionType,
                                            }
                                        }
                                        return pr
                                    }
                                )
                                updatePostVotes(postReactions!)
                                const updatedPosts = dataJoinRoom.roomAttendee?.room.posts.map(
                                    (p) => {
                                        if (p.id === postId) {
                                            return {
                                                ...p,
                                                reactions: postReactions,
                                            }
                                        }
                                        return p
                                    }
                                )

                                cache.writeQuery({
                                    query: ROOM_JOIN,
                                    data: {
                                        roomAttendee: {
                                            ...dataJoinRoom.roomAttendee,
                                            room: {
                                                ...dataJoinRoom.roomAttendee
                                                    ?.room,
                                                posts: updatedPosts,
                                            },
                                        },
                                    },
                                    variables: {
                                        joinKey,
                                        nickName,
                                        firstPost: FIRST_POSTS,
                                        orderByPost: ORDER_BY_POST,
                                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                        afterPost: AFTER_POST,
                                        wherePost: WHERE_POST,
                                        filterPollRes: {
                                            userId: {
                                                equals: userId,
                                            },
                                        },
                                    },
                                })
                            }
                        }
                    }
                },
            }),
        deletePostReactionStatus,
        deletePostReaction: async () =>
            await deletePostReaction({
                variables: {
                    where: {
                        userId_postId: {
                            postId,
                            userId,
                        },
                    },
                },
                update: (cache, { data: deletePostReactionRes, errors }) => {
                    if (deletePostReactionRes && !errors) {
                        const deletedPostReaction =
                            deletePostReactionRes.postReaction

                        if (dataJoinRoom && deletedPostReaction) {
                            logger.debug(
                                `deletePostReaction  ${JSON.stringify(
                                    deletedPostReaction,
                                    null,
                                    3
                                )}`
                            )

                            const myPostReactions =
                                dataJoinRoom.roomAttendee?.room.posts.find(
                                    (p) => p.id === postId
                                )?.reactions || []

                            if (
                                myPostReactions.find(
                                    (pr) => pr.id === deletedPostReaction.id
                                )
                            ) {
                                const postReactions = myPostReactions.filter(
                                    (pr) => {
                                        return pr.id !== deletedPostReaction.id
                                    }
                                )
                                updatePostVotes(postReactions!)
                                const updatedPosts = dataJoinRoom.roomAttendee?.room.posts.map(
                                    (p) => {
                                        if (p.id === postId) {
                                            return {
                                                ...p,
                                                reactions: postReactions,
                                            }
                                        }
                                        return p
                                    }
                                )
                                cache.writeQuery({
                                    query: ROOM_JOIN,
                                    data: {
                                        roomAttendee: {
                                            ...dataJoinRoom.roomAttendee,
                                            room: {
                                                ...dataJoinRoom.roomAttendee
                                                    ?.room,
                                                posts: updatedPosts,
                                            },
                                        },
                                    },
                                    variables: {
                                        joinKey,
                                        nickName,
                                        firstPost: FIRST_POSTS,
                                        orderByPost: ORDER_BY_POST,
                                        orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                        afterPost: AFTER_POST,
                                        wherePost: WHERE_POST,
                                        operation: `DELETE_POST_REACTION_${deletedPostReaction.id}`,
                                        filterPollRes: {
                                            userId: {
                                                equals: userId,
                                            },
                                        },
                                    },
                                })
                                cache.evict({ id: deletedPostReaction.id })
                            }
                        }
                    }
                },
            }),
    }
}
