import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
import { AppLogger } from '../../AppLogger'

import { AttendeeRole } from '../../generated/globalTypes'
import { JoinRoom, JoinRoomVariables } from '../../generated/JoinRoom'

import {
    RoomDeleteDataChange,
    RoomDeleteDataChangeVariables,
} from '../../generated/RoomDeleteDataChange'
import {
    RoomJoinDataChange,
    RoomJoinDataChangeVariables,
} from '../../generated/RoomJoinDataChange'
import {
    RoomLeaveDataChange,
    RoomLeaveDataChangeVariables,
} from '../../generated/RoomLeaveDataChange'
import {
    RoomUpdateDataChange,
    RoomUpdateDataChangeVariables,
} from '../../generated/RoomUpdateDataChange'

import {
    UpdateOneRoomAttendee,
    UpdateOneRoomAttendeeVariables,
} from '../../generated/UpdateOneRoomAttendee'

import { UserData } from '../../generated/UserData'

import {
    ROOM_ATTENDEE_UPDATE,
    ROOM_DELETE_SUBSCRIPTION,
    ROOM_JOIN,
    ROOM_JOIN_SUBSCRIPTION,
    ROOM_LEAVE_SUBSCRIPTION,
    ROOM_UPDATE_SUBSCRIPTION,
} from '../../queries/room'

import { USER_QUERY } from '../../queries/user'
import { validateJoinKey } from '../../auth'
import {
    AFTER_POST,
    FIRST_POSTS,
    ORDER_BY_POST,
    ORDER_BY_PUBLISHED_POST,
    SESSION_KEY,
} from '../common/constant'

const logger = AppLogger.getInstance()
export function useExternal(
    joinKey: string,
    userId: string,
    nickName: string | null,
    objId: string
) {
    const location = useLocation()
    const history = useHistory()

    const orderByPost = useRef(ORDER_BY_POST)
    const orderByPublishedPost = useRef(ORDER_BY_PUBLISHED_POST)
    const afterPost = useRef(AFTER_POST)

    /**
     * Queries
     */

    const { data, loading, error, fetchMore } = useQuery<
        JoinRoom,
        JoinRoomVariables
    >(ROOM_JOIN, {
        variables: {
            joinKey,
            nickName,
            firstPost: FIRST_POSTS,
            orderByPost: orderByPost.current,
            orderByPublishedPost: orderByPublishedPost.current,
            afterPost: afterPost.current,
            wherePost: {
                id: {
                    equals: objId,
                },
            },
            filterPollRes: {
                userId: {
                    equals: userId,
                },
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        // fetchPolicy: 'cache-first', // this is the default
        //fetchPolicy: 'cache-and-network',
        //fetchPolicy: 'network-only',
        //fetchPolicy: 'cache-only',
        //fetchPolicy: 'no-cache',
        // pollInterval: ATTENDEE_POLL_INTERVAL,
    })

    /**
     * Mutations
     */

    const [updateRoomAttendee, updateRoomAttendeeStatus] =
        useMutation<UpdateOneRoomAttendee, UpdateOneRoomAttendeeVariables>(
            ROOM_ATTENDEE_UPDATE
        )

    /**
     * Subscriptions
     */

    /**
    |---------------------------------------------------------
    | Triggered on room update
    | onSubscriptionData: 
    | 1 - update the current joined room 
    | 2 - updated fields are : isAutomaticFlow, isCommentsAllowed
    |---------------------------------------------------------
    */
    useSubscription<RoomUpdateDataChange, RoomUpdateDataChangeVariables>(
        ROOM_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room join or re-join
    | onSubscriptionData: 
    | 1 - update the home page mainly the joined rooms block
    |  a - in case of a new join - we add the record
    |  b - in case of a re-join - we update the lastSeenAt 
    |  c - in case of nickname add
    |---------------------------------------------------------
    */

    useSubscription<RoomJoinDataChange, RoomJoinDataChangeVariables>(
        ROOM_JOIN_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const userData = await client.readQuery<UserData>({
                    query: USER_QUERY,
                    variables: {
                        id: userId,
                    },
                })
                const existingRooms = userData?.user?.joinedRooms!
                if (existingRooms) {
                    logger.debug(
                        `ROOM_JOIN_SUBSCRIPTION existingRooms:`,
                        existingRooms
                    )
                    const _roomAttendee = subscriptionData.data?.onRoomJoin!
                    let updatedRooms = [...existingRooms]

                    const { id: userIdRoomIdRole, role } = _roomAttendee

                    const roomAttendeeArgs = userIdRoomIdRole.split('_')
                    const roomAttendeeUserId = roomAttendeeArgs
                        ? (roomAttendeeArgs[0] as string)
                        : ''
                    const roomAttendeeRoomId = roomAttendeeArgs
                        ? (roomAttendeeArgs[1] as string)
                        : ''

                    const attendeeExist = existingRooms.find(
                        (r) => r.id === userIdRoomIdRole
                    )
                    if (roomAttendeeUserId === userId) {
                        // attendee record exist
                        if (attendeeExist) {
                            // triggered by admin
                            if (role === AttendeeRole.Admin) {
                                // update admin and guest joined record in case of user has joined as an admin and as a guest
                                updatedRooms = [
                                    ...existingRooms.map((r) => {
                                        if (r.room.id === roomAttendeeRoomId) {
                                            return {
                                                ...r,
                                                lastSeenAt:
                                                    _roomAttendee.lastSeenAt,
                                                nickName:
                                                    _roomAttendee.nickName,
                                            }
                                        }
                                        return r
                                    }),
                                ]
                            } else {
                                // triggered by guest  update the guest attendee
                                updatedRooms = [
                                    ...existingRooms.map((r) => {
                                        if (
                                            r.room.id === roomAttendeeRoomId &&
                                            r.role === role
                                        ) {
                                            return {
                                                ...r,
                                                lastSeenAt:
                                                    _roomAttendee.lastSeenAt,
                                                nickName:
                                                    _roomAttendee.nickName,
                                            }
                                        }
                                        return r
                                    }),
                                ]
                            }
                        } else {
                            // attendee doesn't exist  - add it
                            logger.debug(
                                `Same user case Add new roomAttendee record `
                            )
                            updatedRooms = [...updatedRooms, _roomAttendee]
                        }
                    }

                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...userData?.user,
                                joinedRooms: updatedRooms,
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on room leave
    | onSubscriptionData: 
    | 1 - update the joinedRooms block by removing the room attendee record
    | 2 - navigate the joined user to home page 
    |---------------------------------------------------------
    */
    useSubscription<RoomLeaveDataChange, RoomLeaveDataChangeVariables>(
        ROOM_LEAVE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const userData = await client.readQuery<UserData>({
                    query: USER_QUERY,
                    variables: {
                        id: userId,
                    },
                })

                if (userData?.user?.joinedRooms) {
                    client.writeQuery({
                        query: USER_QUERY,
                        data: {
                            user: {
                                ...userData?.user,
                                joinedRooms: userData?.user?.joinedRooms.filter(
                                    (r) =>
                                        r.id !==
                                        subscriptionData.data?.onRoomLeave?.id
                                ),
                            },
                        },
                        variables: {
                            id: userId,
                        },
                    })
                }
                // navigate the user back to home
                history.push(
                    {
                        pathname: '/',
                    },
                    {
                        screen: 'external',
                    }
                )
            },
        }
    )
    /**
    |---------------------------------------------------------
    | Triggered on room delete
    | onSubscriptionData: update the joinedRooms block by removing the room attendee record
    |---------------------------------------------------------
    */
    useSubscription<RoomDeleteDataChange, RoomDeleteDataChangeVariables>(
        ROOM_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const userData = await client.readQuery<UserData>({
                    query: USER_QUERY,
                    variables: {
                        id: userId,
                    },
                })

                client.writeQuery({
                    query: USER_QUERY,
                    data: {
                        user: {
                            ...userData?.user,
                            joinedRooms: userData?.user?.joinedRooms.filter(
                                (r) =>
                                    r.room.id !==
                                    subscriptionData.data?.onRoomDelete?.id
                            ),
                        },
                    },
                    variables: {
                        id: userId,
                    },
                })

                // navigate the user back to home
                history.push(
                    {
                        pathname: '/',
                    },
                    {
                        screen: 'external',
                    }
                )
            },
        }
    )

    /**
     * useEffects
     */
    useEffect(() => {
        /**
         * setupRoomJoin will handle the nickname pop-up. mainly when the room page
         * is accessed directly from a shared link that contains the joinKey
         * NB: we may accept a nickname params (Moodle login ect..)
         */
        if (!joinKey || !objId) {
            history.push(
                {
                    pathname: '/',
                },
                {
                    screen: 'external',
                }
            )
        }
        const setupRoomJoin = async () => {
            try {
                logger.debug(`setupExternalJoin joinKey :${joinKey}`)

                if (joinKey) {
                    // not internal
                    logger.debug(`setupExternalJoin navigation direct link`)
                    // validate the provided joinKey
                    try {
                        const isValid = await validateJoinKey(joinKey)
                        if (!isValid) {
                            // push to room page
                            const pathname = `/error/${joinKey}`

                            history.push(
                                {
                                    pathname,
                                },
                                {
                                    screen: 'room',
                                }
                            )
                        }
                    } catch (e) {
                        logger.error(
                            `setupRoomJoin error on validateJoinKey call `
                        )
                    }

                    // for modal to show up we should add to the root div the class="switch-modal"
                    // when room page is accessed directly from a shared link that contains the joinKey
                    // TODO check if this room requires a nickname
                    // if yes - show a popup so user can enters his nickname

                    // add session key to identify user
                    const sessionKey = v4()
                    sessionStorage.setItem(SESSION_KEY, sessionKey)
                }
            } catch (e) {}
        }
        setupRoomJoin()
    }, [joinKey, location, history, objId])

    return {
        fetchMore,
        data,
        loading,
        error,

        updateRoomAttendeeStatus,
        updateRoomAttendee: async (
            nickName: string,
            role: AttendeeRole,
            roomId: string
        ) =>
            await updateRoomAttendee({
                variables: {
                    data: {
                        nickName: {
                            set: nickName,
                        },
                        lastSeenAt: {
                            set: new Date(),
                        },
                    },
                    where: {
                        userId_roomId_role: {
                            userId,
                            role,
                            roomId,
                        },
                    },
                },
            }),
    }
}
