import { useApolloClient } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { subscriptionClient } from '../../../apolloClient'
import { AppLogger } from '../../../AppLogger'

const logger = AppLogger.getInstance()

export function useSubscriptionClient() {
    const client = useApolloClient()
    const isMounted = useRef(false)
    const [clientConnectionError, setClientConnectionError] = useState<boolean>(
        false
    )

    /**
    |---------------------------------------------------------
    | monitor the subscriptionClient connectivity  status
    |---------------------------------------------------------
    */
    subscriptionClient.onConnected(() => {
        if (clientConnectionError === true && isMounted.current) {
            logger.info(
                'onConnected hook - switch clientConnectionError to false'
            )
            setClientConnectionError(false)
        }
    })
    subscriptionClient.onReconnected(() => {
        if (clientConnectionError === true && isMounted.current) {
            logger.info(
                'onReconnected hook - switch clientConnectionError to false'
            )
            setClientConnectionError(false)
            client.refetchQueries({
                include: 'active',
            })
        }
    })
    subscriptionClient.onReconnecting(() => {
        logger.info(
            'onReconnecting hook - client is waiting the server feedback'
        )
    })
    subscriptionClient.onDisconnected(() => {
        if (clientConnectionError === false && isMounted.current) {
            logger.info(
                'onDisconnected hook - switch clientConnectionError to true'
            )
            setClientConnectionError(true)
        }
    })

    /**
     * This to avoid updating the state on unmounted component
     */
    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    /**
     * clientConnectionError used to display a message to the end user about the connectivity status
     * TODO : implement some logic to retry the connection with the server
     */
    return {
        clientConnectionError,
        isMounted,
    }
}
