import React, { useEffect, useRef } from 'react'
//import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router-dom'
import { $ } from 'react-jquery-plugin'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../AppLogger'
import { ROOM_CREATE_BLOCK_SLIDE_DURATION } from '../../common/constant'
import { EventAction, EventCategory, gaEvent } from '../../common/utilis'

export interface RoomForm {
    name: string
    isTemporary: boolean
    isNicknames: boolean
    isAnonymous: boolean
    isModerated: boolean
    isPollOnly: boolean
    isCommentsAllowed: boolean
    isAutomaticFlow: boolean
    reCaptchaToken: string
}

interface Props {
    createRoom: Function
}

const logger = AppLogger.getInstance()

const CreateRoom: React.FC<Props> = ({ createRoom }) => {
    const { t } = useTranslation(['Home'])
    const history = useHistory()
    //let reRef = useRef<ReCAPTCHA>(null)
    const lobbyCreateRef = useRef<HTMLDivElement>(null)
    const refInput = useRef<HTMLInputElement>(null)

    const defaultsRoomValues = {
        name: '',
        // readonly fields
        isTemporary: true,
        isAnonymous: true,
        isModerated: false,
        isPollOnly: false,
        reCaptchaToken: '',
        isNicknames: false,
        // editable fields
        isCommentsAllowed: true,
        isAutomaticFlow: true,
    }

    let validationSchema = Yup.object().shape({
        // name: Yup.string().required(t('Room name is required')),
    })

    const handleCreateRoom = async (room: RoomForm): Promise<void> => {
        try {
            logger.info(
                `***************** handleCreateRoom NODE_ENV :${process.env.NODE_ENV}`
            )
            logger.debug(
                `handleCreateRoom room: ${JSON.stringify(room, null, 3)}`
            )
            if (!room.name || room.name.trim().length === 0) {
                if (refInput.current) {
                    refInput.current.focus()
                }
                return
            }
            const token = process.env.REACT_APP_RECAPTCHA_SITE_KEY //await reRef!.current!.executeAsync()
            logger.info(
                `***************** handleCreateRoom reCaptchaToken :${token}`
            )
            room.reCaptchaToken = token!

            const payload = {
                ...room,
                isNicknames: !room.isAnonymous,
            } as any

            delete payload.isAnonymous

            const createdRoom = await createRoom(payload)
            gaEvent(EventCategory.Room, EventAction.Create, room.name)
            // push to room page
            const pathname = `/room/${createdRoom.data.room.adminKey}`

            history.push(
                {
                    pathname,
                },
                {
                    screen: 'home',
                    action: 'roomCreation',
                }
            )
        } catch (e) {
            logger.error(e.message)
            // handle error
        }
    }
    const toggleForm = () => {
        if (lobbyCreateRef && lobbyCreateRef.current) {
            if (lobbyCreateRef.current.classList.contains('switch-selected')) {
                lobbyCreateRef.current.classList.remove('switch-selected')
                $('#createRoomForm').slideUp(ROOM_CREATE_BLOCK_SLIDE_DURATION)
            } else {
                $('#lobbyRoomCreate').siblings().removeClass('switch-selected')
                $('#joinRoomForm').slideUp(ROOM_CREATE_BLOCK_SLIDE_DURATION)
                lobbyCreateRef.current.classList.add('switch-selected')
                $('#createRoomForm').slideDown(ROOM_CREATE_BLOCK_SLIDE_DURATION)
            }
        }
    }

    useEffect(() => {
        $('.lobby-choices:not(.switch-selected) .lobby-form').slideUp(0)
    }, [])

    return (
        <div
            className="lobby-choices create"
            ref={lobbyCreateRef}
            id="lobbyRoomCreate"
        >
            <div className="entry-title" onClick={toggleForm}>
                <h3>{t('CreateNewRoom')}</h3>
            </div>
            <Formik
                initialValues={{
                    ...defaultsRoomValues,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions: FormikHelpers<RoomForm>) => {
                    actions.setSubmitting(false)
                    handleCreateRoom(values)
                }}
            >
                {({
                    values,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                }) => (
                    <form
                        className="form entry-switch lobby-form"
                        id="createRoomForm"
                        onSubmit={handleSubmit}
                    >
                        <input
                            type="text"
                            maxLength={20}
                            placeholder={t('RoomNamePlaceholder')}
                            className="input-text"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            value={values.name}
                            ref={refInput}
                        />
                        <div className="label">
                            <h4>{t('TemporaryRoom')}</h4>
                            <p>{t('TemporaryRoomNote')}</p>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    aria-label={t('TemporaryRoom')}
                                    name="isTemporary"
                                    id="isTemporary"
                                    onChange={handleChange}
                                    checked={values.isTemporary}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>

                        <div className="label">
                            <h4>{t('Anonymous')}</h4>
                            <p>{t('AnonymousNote')}</p>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    aria-label={t('Anonymous')}
                                    name="isAnonymous"
                                    id="isAnonymous"
                                    onChange={handleChange}
                                    checked={values.isAnonymous}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {!values.isPollOnly && (
                            <div className="label">
                                <h4>{t('ModeratedRoom')}</h4>
                                <p>{t('ModeratedRoomNote')}</p>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        aria-label={t('ModeratedRoom')}
                                        name="isModerated"
                                        id="isModerated"
                                        onChange={handleChange}
                                        checked={values.isModerated}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        )}

                        {/**
                        {!values.isModerated && (
                            <div className="label">
                                <h4>{t('PollOnlyRoom')}</h4>
                                <p>{t('PollOnlyRoomNote')}</p>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name="isPollOnly"
                                        id="isPollOnly"
                                        onChange={handleChange}
                                        checked={values.isPollOnly}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        )}  */}
                        <button className={`button-submit `} type="submit">
                            <span>{t('Create')}</span>
                        </button>
                    </form>
                )}
            </Formik>

            {/**
  <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                size="invisible"
                ref={reRef}
            />
 */}
        </div>
    )
}

export default CreateRoom
