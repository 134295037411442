import { gql } from '@apollo/client'
import {
    commentDetailFragment,
    commentReactionDetailFragment,
} from './fragments'

/**
 * QUERIES
 */

/**
 * MUTATIONS
 */

export const COMMENT_CREATE = gql`
    mutation CreateOneComment($data: CommentCreateInput!) {
        comment: createOneComment(data: $data) {
            ...CommentDetail
        }
    }
    ${commentDetailFragment}
`

export const COMMENT_UPDATE = gql`
    mutation UpdateOneComment(
        $data: CommentUpdateInput!
        $where: CommentWhereUniqueInput!
    ) {
        comment: updateOneComment(data: $data, where: $where) {
            ...CommentDetail
        }
    }
    ${commentDetailFragment}
`

export const COMMENT_DELETE = gql`
    mutation DeleteOneComment($where: CommentWhereUniqueInput!) {
        comment: deleteOneComment(where: $where) {
            id
        }
    }
`

// Reaction

export const COMMENT_REACTION_CREATE = gql`
    mutation CreateOneCommentReaction($data: CommentReactionCreateInput!) {
        commentReaction: createOneCommentReaction(data: $data) {
            ...CommentReactionDetail
        }
    }
    ${commentReactionDetailFragment}
`

export const COMMENT_REACTION_UPDATE = gql`
    mutation UpdateOneCommentReaction(
        $data: CommentReactionUpdateInput!
        $where: CommentReactionWhereUniqueInput!
    ) {
        commentReaction: updateOneCommentReaction(data: $data, where: $where) {
            ...CommentReactionDetail
        }
    }
    ${commentReactionDetailFragment}
`

export const COMMENT_REACTION_DELETE = gql`
    mutation DeleteOneCommentReaction(
        $where: CommentReactionWhereUniqueInput!
    ) {
        commentReaction: deleteOneCommentReaction(where: $where) {
            id
        }
    }
`

/**
 * SUBSCRIPTIONS
 */
export const COMMENT_CREATE_SUBSCRIPTION = gql`
    subscription CommentCreateDataChange($userId: ID!) {
        onCommentCreate(userId: $userId) {
            ...CommentDetail
        }
    }
    ${commentDetailFragment}
`

export const COMMENT_UPDATE_SUBSCRIPTION = gql`
    subscription CommentUpdateDataChange($userId: ID!) {
        onCommentUpdate(userId: $userId) {
            ...CommentDetail
        }
    }
    ${commentDetailFragment}
`

export const COMMENT_DELETE_SUBSCRIPTION = gql`
    subscription CommentDeleteDataChange($userId: ID!) {
        onCommentDelete(userId: $userId) {
            id
        }
    }
`

export const COMMENT_REACTION_CREATE_SUBSCRIPTION = gql`
    subscription CommentReactionCreateDataChange($userId: ID!) {
        onCommentReactionCreate(userId: $userId) {
            ...CommentReactionDetail
        }
    }
    ${commentReactionDetailFragment}
`

export const COMMENT_REACTION_UPDATE_SUBSCRIPTION = gql`
    subscription CommentReactionUpdateDataChange($userId: ID!) {
        onCommentReactionUpdate(userId: $userId) {
            ...CommentReactionDetail
        }
    }
    ${commentReactionDetailFragment}
`

export const COMMENT_REACTION_DELETE_SUBSCRIPTION = gql`
    subscription CommentReactionDeleteDataChange($userId: ID!) {
        onCommentReactionDelete(userId: $userId) {
            id
        }
    }
`
