import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
} from 'react-router-dom'
import * as QueryString from 'query-string'
import Dispatcher from '../components/Dispatcher'

const ErrorPage = () => {
    return (
        <div className="wrapper">
            <div className="page_error">
                <div className="page-inner-bg"></div>
                <div className="page-content">
                    <h1>We are so sorry</h1>
                    <p className="page-description">Page can't be found</p>
                </div>
            </div>
        </div>
    )
}

const AppRouter = () => {
    const basename = process.env.PUBLIC_URL
    return (
        <Router basename={basename}>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={(props: any) => {
                        const params = QueryString.parse(props.location.search)
                        return (
                            <Redirect
                                exact={true}
                                from="/"
                                to={{
                                    pathname: '/home',
                                    state: {
                                        ...props.location.state,
                                        theme: params.theme,
                                    },
                                }}
                            />
                        )
                    }}
                />
                <Route path="/home" component={Dispatcher} />
                <Route
                    exact
                    path="/room/:joinKey"
                    render={(props: any) => {
                        const params = QueryString.parse(props.location.search)
                        return (
                            <Redirect
                                exact={true}
                                from="/room/:joinKey"
                                to={{
                                    pathname: '/room',
                                    state: {
                                        ...props.location.state,
                                        joinKey: props.match.params.joinKey,
                                        theme: params.theme,
                                    },
                                }}
                            />
                        )
                    }}
                />

                <Route
                    exact
                    path="/room/join/:joinKey"
                    component={Dispatcher}
                />

                {/** Added new routes to open in a new window or in an iFrame one poll or one post */}
                <Route
                    exact
                    path="/poll/:joinKey/:pollId"
                    component={Dispatcher}
                />
                <Route
                    exact
                    path="/post/:joinKey/:postId"
                    component={Dispatcher}
                />

                <Route exact path="/room" component={Dispatcher} />
                <Route component={ErrorPage} />
            </Switch>
        </Router>
    )
}

export default AppRouter
