import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'ts-debounce'
import { AppLogger } from '../../../../../../../AppLogger'

import { getUserIdFromToken } from '../../../../../../../auth'
import { AttendeeRole } from '../../../../../../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_polls_options,
} from '../../../../../../../generated/JoinRoom'
import {
    CLASS_ROW,
    DEBOUNCED_WAIT,
    SWITCH_SELECTED,
    SWITCH_SOLUTION,
} from '../../../../../../common/constant'
import {
    addClassByOptionId,
    getCheckedOptionId,
    isOptionHasClassName,
    removeClassByOptionId,
} from '../../helper'
import { useOption } from '.././useOption'

const logger = AppLogger.getInstance()

/**
 *
 */
interface Props {
    pollId: string
    dataJoinRoom: JoinRoom
    joinKey: string
    role: AttendeeRole
    option: JoinRoom_roomAttendee_room_polls_options
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >
    isResults: boolean
    isMultipleResponses: boolean
    isSolutions: boolean
    isImmediateResults: boolean
    isVotedPoll: boolean
    isImmediateResultsBroadcast: boolean
    progress?: number
    votes?: number
    nickName: string | null | undefined
}
const GuestOption: React.FC<Props> = ({
    pollId,
    dataJoinRoom,
    joinKey,
    role,
    option,
    classListMap,
    isResults,
    isMultipleResponses,
    isSolutions,
    isImmediateResults,
    isVotedPoll,
    isImmediateResultsBroadcast,
    progress = 0,
    votes = 0,
    nickName,
}) => {
    const userId = getUserIdFromToken()!
    const refClassList = useRef<HTMLDivElement>(null)

    const {
        updatePollOptionsResult,
        updatePollOptionsResultStatus,
        deletePollOptionResult,
        deletePollOptionResultStatus,
        updatePollOptionResult,
        updatePollOptionResultStatus,
    } = useOption(
        joinKey,
        userId,
        dataJoinRoom,
        pollId,
        role,
        classListMap,
        nickName
    )

    const [classList] = useState<string>(() => {
        let _classList = ''

        // guest result on this pollOption
        const guestResult = option.results.find((r) => {
            const args = r.id.split('_')
            const _id = args ? (args[0] as string) : ''
            return _id === userId
        })

        if (isResults || isImmediateResults) {
            if (isSolutions) {
                if (option.isChecked) {
                    _classList = SWITCH_SOLUTION
                }
            }
        }
        if (guestResult && guestResult.isChecked) {
            _classList += ` ${SWITCH_SELECTED}`
        }
        return _classList
    })

    /**
     * Mono response Poll
     * PollOptionResult debounced functions
     */
    const debouncedUpdatePollOptionsResult = debounce(
        updatePollOptionsResult,
        DEBOUNCED_WAIT,
        { isImmediate: false }
    )
    const debouncedDeletePollOptionResult = debounce(
        deletePollOptionResult,
        DEBOUNCED_WAIT,
        { isImmediate: false }
    )

    /**
     * Multi response Poll
     */
    const debouncedUpdatePollOptionResult = debounce(
        updatePollOptionResult,
        DEBOUNCED_WAIT,
        { isImmediate: false }
    )

    const mutate = async () => {
        if (
            updatePollOptionsResultStatus.error ||
            deletePollOptionResultStatus.error ||
            updatePollOptionResultStatus.error
        ) {
            logger.error(updatePollOptionsResultStatus.error)
            logger.error(deletePollOptionResultStatus.error)
            //TODO handle error
            return
        }
        // Guest can't submit his answer when:
        // case 1: poll is immediate and already voted
        // case 2: when admin close the poll and publish the result
        if ((isImmediateResults && isVotedPoll) || isResults) {
            return
        }
        if (isMultipleResponses) {
            // more than option can be checked by the guest
            // Submit btn will be visible to write the answer to db if the poll is not voted yet
            const prevStatusChecked = isOptionHasClassName(
                option.id,
                classListMap,
                SWITCH_SELECTED
            )

            if (isVotedPoll) {
                // there is no submit button
                if (prevStatusChecked) {
                    // case 1: option was checked  the new  checked status will be set to false
                    removeClassByOptionId(
                        option.id,
                        classListMap,
                        SWITCH_SELECTED
                    )
                    await debouncedUpdatePollOptionResult(option.id, false)
                } else {
                    // case 2: option was unchecked, the new checked status will be set to true
                    addClassByOptionId(option.id, classListMap, SWITCH_SELECTED)
                    await debouncedUpdatePollOptionResult(option.id, true)
                }
            } else {
                // case 2: not voted poll yet
                // there is a submit button

                if (prevStatusChecked) {
                    // case 1: option was checked  the new  checked status will be set to false
                    removeClassByOptionId(
                        option.id,
                        classListMap,
                        SWITCH_SELECTED
                    )
                } else {
                    // case 2: option was unchecked, the new checked status will be set to true
                    addClassByOptionId(option.id, classListMap, SWITCH_SELECTED)
                }
            }
        } else {
            // only one or zero option can be checked
            // 2 main methods to be used
            const prevSelectedOptionId = getCheckedOptionId(
                classListMap,
                SWITCH_SELECTED
            )

            if (prevSelectedOptionId) {
                //updatePollOptionsResult,
                if (prevSelectedOptionId !== option.id) {
                    // case 1: new checked option is # than the previous one
                    removeClassByOptionId(
                        prevSelectedOptionId,
                        classListMap,
                        SWITCH_SELECTED
                    )
                    addClassByOptionId(option.id, classListMap, SWITCH_SELECTED)
                    //await updatePollOptionsResult(pollId, option.id)
                    await debouncedUpdatePollOptionsResult(
                        pollId,
                        isImmediateResultsBroadcast,
                        option.id
                    )
                } else {
                    //case 2: new checked option is the same as the previous
                    removeClassByOptionId(
                        prevSelectedOptionId,
                        classListMap,
                        SWITCH_SELECTED
                    )
                    //await deletePollOptionResult(option.id)
                    await debouncedDeletePollOptionResult(option.id)
                }
            } else {
                // case 3: new checked option (there was no previous option checked)
                addClassByOptionId(option.id, classListMap, SWITCH_SELECTED)
                //await updatePollOptionsResult(pollId, option.id)
                await debouncedUpdatePollOptionsResult(
                    pollId,
                    isImmediateResultsBroadcast,
                    option.id
                )
            }
        }
    }

    useEffect(() => {
        // register option
        classListMap.current.set(option.id, refClassList)
        return () => {
            logger.debug('cleanUp....................')
        }
    }, [option.id, classListMap])

    /**
     * Below use effect is used to render the correct admin answer
     */
    useEffect(() => {
        logger.debug('---------- useEffect SWITCH_SOLUTION start ---- ')
        if (isResults || isImmediateResults) {
            if (isSolutions) {
                if (option.isChecked) {
                    // case 1: admin checked the option switch it to green
                    addClassByOptionId(option.id, classListMap, SWITCH_SOLUTION)
                } else {
                    // case 2: admin unchecked the option switch it normal
                    removeClassByOptionId(
                        option.id,
                        classListMap,
                        SWITCH_SOLUTION
                    )
                }
            }
        }
        return () => {
            logger.debug('---------- useEffect SWITCH_SOLUTION cleanup ---- ')
        }
    }, [
        isResults,
        isImmediateResults,
        isSolutions,
        option.isChecked,
        option.id,
        classListMap,
    ])

    return (
        <div className={`${CLASS_ROW} ${classList}`} ref={refClassList}>
            <div className="row-label" onClick={mutate}>
                <p>{option.label}</p>
            </div>
            <div className="row-progress">
                <div
                    className="progress"
                    style={{ width: `${Math.ceil(progress)}%` }}
                >
                    <div className="row-value">
                        <p> {progress}%</p>
                    </div>
                </div>
            </div>
            <div className="row-count">
                <p>{votes}</p>
            </div>
        </div>
    )
}

export default GuestOption
