import React, { useEffect } from 'react'
import * as QueryString from 'query-string'
import { $ } from 'react-jquery-plugin'
import { useLocation } from 'react-router-dom'
import { useExternal } from './useExternal'
import { useProfile } from '../common/hooks/useProfile'
import { useSubscriptionClient } from '../common/hooks/useSubscriptionClient'
import { getUserIdFromToken } from '../../auth'
import NicknameModal from '../Room/partials/NicknameModal'
import ExternalContent from './partials/ExternalContent'

interface Props {
    joinKey: string
    objId: string
    typename: 'Post' | 'Poll'
}

const External: React.FC<Props> = ({ joinKey, objId, typename }) => {
    const locationRoom = useLocation()
    const params = QueryString.parse(locationRoom.search)
    const userId = getUserIdFromToken()!
    const nickName = (params.nickName ? params.nickName : null) as string | null

    useSubscriptionClient()
    useProfile(userId)

    const { data, loading, updateRoomAttendee, updateRoomAttendeeStatus } =
        useExternal(joinKey, userId, nickName, objId)

    useEffect(() => {
        $('body').addClass('flag-loading')
        if (!loading) {
            setTimeout(() => $('body').removeClass('flag-loading'), 0)
        }

        return () => {
            $('body').removeClass('flag-loading')
        }
    }, [loading])

    // remove class switch-moderate-select
    useEffect(() => {
        $('#root').removeClass('switch-gdpr')
        return () => {
            $('body').removeClass('switch-moderate-select')
        }
    }, [])

    //if (loading || !data) return <div>{t('loading')} ...</div>
    if (loading || !data) return <div></div>

    // nickname logic
    if (data && data.roomAttendee && data.roomAttendee.room.isNicknames) {
        const { nickName } = data.roomAttendee

        if (!nickName) {
            $('body').addClass('switch-modal')
        } else {
            $('body').removeClass('switch-modal')
        }
    }

    return (
        <div className="container-main template-messages">
            <div
                className="container-content"
                //style={{ width: '100%', padding: '0 0.5rem', top: '0.5rem' }}
            >
                <ExternalContent
                    data={data}
                    joinKey={joinKey}
                    objId={objId}
                    typename={typename}
                />
            </div>
            <div className="iphone-extra-layout"></div>
            <NicknameModal
                data={data}
                updateRoomAttendee={updateRoomAttendee}
                updateRoomAttendeeStatus={updateRoomAttendeeStatus}
            />
        </div>
    )
}

export default External
