import { useMutation, useSubscription } from '@apollo/client'
import {
    CommentDeleteDataChange,
    CommentDeleteDataChangeVariables,
} from '../../../../../../generated/CommentDeleteDataChange'

import {
    CommentUpdateDataChange,
    CommentUpdateDataChangeVariables,
} from '../../../../../../generated/CommentUpdateDataChange'

import {
    DeleteOneComment,
    DeleteOneCommentVariables,
} from '../../../../../../generated/DeleteOneComment'

import { JoinRoom } from '../../../../../../generated/JoinRoom'
import {
    UpdateOneComment,
    UpdateOneCommentVariables,
} from '../../../../../../generated/UpdateOneComment'

import {
    COMMENT_DELETE,
    COMMENT_DELETE_SUBSCRIPTION,
    COMMENT_UPDATE,
    COMMENT_UPDATE_SUBSCRIPTION,
} from '../../../../../../queries/comment'
import { ROOM_JOIN } from '../../../../../../queries/room'
import { AppLogger } from '../../../../../../AppLogger'
import {
    FIRST_POSTS,
    ORDER_BY_POST,
    AFTER_POST,
    WHERE_POST,
    SESSION_KEY,
    ORDER_BY_PUBLISHED_POST,
} from '../../../../../common/constant'
import { isSameUserSession, updateRoomInfo } from '../../../../../common/utilis'
import { AttendeeRole } from '../../../../../../generated/globalTypes'
const logger = AppLogger.getInstance()
export function useComment(
    joinKey: string,
    userId: string,
    data: JoinRoom,
    postId: string,
    commentId: string,
    allVotesRef: React.RefObject<HTMLDivElement>,
    computedVotesRef: React.RefObject<HTMLDivElement>,
    getVotesText: (count: number) => string,
    nickName: string | null | undefined,
    setRoomInfo: Function,
    role: AttendeeRole
) {
    /**
     * Mutations
     */

    // Comment CRUD
    const [updateComment, updateCommentStatus] = useMutation<
        UpdateOneComment,
        UpdateOneCommentVariables
    >(COMMENT_UPDATE)

    const [deleteComment, deleteCommentStatus] = useMutation<
        DeleteOneComment,
        DeleteOneCommentVariables
    >(COMMENT_DELETE)

    /**
     * Subscriptions
     */

    /**
    |---------------------------------------------------------
    | Triggered on comment update
    | onSubscriptionData: 
    | 1 - update the current joined room by modifying the existing comment 
    | 2 - editable field is: isPublished 
    |---------------------------------------------------------
    */
    useSubscription<CommentUpdateDataChange, CommentUpdateDataChangeVariables>(
        COMMENT_UPDATE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: ({ client, subscriptionData }) => {
                const updatedComment = subscriptionData.data?.onCommentUpdate
                if (
                    updatedComment &&
                    updatedComment.id === commentId &&
                    !isSameUserSession(updatedComment.modifiedBy)
                ) {
                    logger.debug(
                        `COMMENT_UPDATE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const postComments = data?.roomAttendee?.room.posts
                        .find((p) => p.id === postId)
                        ?.comments.map((c) => {
                            if (c.id === updatedComment.id) {
                                return {
                                    ...c,
                                    isPublished: updatedComment.isPublished,
                                }
                            }
                            return c
                        })

                    const updatedPosts = data?.roomAttendee?.room.posts.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    comments: postComments,
                                }
                            }
                            return p
                        }
                    )
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `COMMENT_UPDATE_SUBSCRIPTION don't do anything sameUser session or not the target comment`
                    )
                }
            },
        }
    )

    /**
    |---------------------------------------------------------
    | Triggered on comment delete
    | onSubscriptionData: 
    | 1 - update the current joined room by removing from apollo cache the deleted comment
    |---------------------------------------------------------
    */
    useSubscription<CommentDeleteDataChange, CommentDeleteDataChangeVariables>(
        COMMENT_DELETE_SUBSCRIPTION,
        {
            variables: {
                userId,
            },
            onSubscriptionData: async ({ client, subscriptionData }) => {
                const deletedComment = subscriptionData.data?.onCommentDelete
                const myPostComments =
                    data?.roomAttendee?.room.posts.find((p) => p.id === postId)
                        ?.comments || []
                if (
                    deletedComment &&
                    myPostComments.find((pc) => pc.id === deletedComment.id)
                ) {
                    logger.debug(
                        `COMMENT_DELETE_SUBSCRIPTION subscriptionData:`,
                        subscriptionData
                    )
                    const postComments = myPostComments.filter(
                        (c) => c.id !== deletedComment.id
                    )

                    const updatedPosts = data?.roomAttendee?.room.posts.map(
                        (p) => {
                            if (p.id === postId) {
                                return {
                                    ...p,
                                    comments: postComments,
                                }
                            }
                            return p
                        }
                    )
                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    posts: updatedPosts,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: AFTER_POST,
                            wherePost: WHERE_POST,
                            operation: `DELETE_COMMENT_${deletedComment.id}`,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                } else {
                    logger.debug(
                        `COMMENT_DELETE_SUBSCRIPTION don't do anything already deleted or not the target comment`
                    )
                    if (role === AttendeeRole.Admin) {
                        await updateRoomInfo(data, setRoomInfo)
                    }
                }
            },
        }
    )

    /**
     * useEffect hooks
     */
    return {
        updateCommentStatus,
        publishComment: async () =>
            await updateComment({
                variables: {
                    data: {
                        isPublished: {
                            set: true,
                        },
                        modifiedBy: {
                            set: sessionStorage.getItem(SESSION_KEY),
                        },
                    },
                    where: {
                        id: commentId,
                    },
                },
                update(cache, { data: updatedCommentRes, errors }) {
                    if (updatedCommentRes && !errors) {
                        const updatedComment = updatedCommentRes.comment!
                        const postComments = data?.roomAttendee?.room.posts
                            .find((p) => p.id === postId)
                            ?.comments.map((c) => {
                                if (c.id === updatedComment.id) {
                                    return {
                                        ...c,
                                        isPublished: updatedComment.isPublished,
                                    }
                                }
                                return c
                            })

                        const updatedPosts = data?.roomAttendee?.room.posts.map(
                            (p) => {
                                if (p.id === postId) {
                                    return {
                                        ...p,
                                        comments: postComments,
                                    }
                                }
                                return p
                            }
                        )
                        cache.writeQuery({
                            query: ROOM_JOIN,
                            data: {
                                roomAttendee: {
                                    ...data?.roomAttendee,
                                    room: {
                                        ...data?.roomAttendee?.room,
                                        posts: updatedPosts,
                                    },
                                },
                            },
                            variables: {
                                joinKey,
                                nickName,
                                firstPost: FIRST_POSTS,
                                orderByPost: ORDER_BY_POST,
                                orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                afterPost: AFTER_POST,
                                wherePost: WHERE_POST,
                                filterPollRes: {
                                    userId: {
                                        equals: userId,
                                    },
                                },
                            },
                        })
                    }
                },
            }),
        deleteCommentStatus,
        deleteComment: async () =>
            await deleteComment({
                variables: {
                    where: {
                        id: commentId,
                    },
                },
                update(cache, { data: deletedComment, errors }) {
                    if (deletedComment && deletedComment.comment && !errors) {
                        const postComments = data?.roomAttendee?.room.posts
                            .find((p) => p.id === postId)
                            ?.comments.filter(
                                (c) => c.id !== deletedComment.comment!.id
                            )

                        const updatedPosts = data?.roomAttendee?.room.posts.map(
                            (p) => {
                                if (p.id === postId) {
                                    return {
                                        ...p,
                                        comments: postComments,
                                    }
                                }
                                return p
                            }
                        )
                        cache.writeQuery({
                            query: ROOM_JOIN,
                            data: {
                                roomAttendee: {
                                    ...data?.roomAttendee,
                                    room: {
                                        ...data?.roomAttendee?.room,
                                        posts: updatedPosts,
                                    },
                                },
                            },
                            variables: {
                                joinKey,
                                nickName,
                                firstPost: FIRST_POSTS,
                                orderByPost: ORDER_BY_POST,
                                orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                                afterPost: AFTER_POST,
                                wherePost: WHERE_POST,
                                operation: `DELETE_COMMENT_${deletedComment.comment.id}`,
                                filterPollRes: {
                                    userId: {
                                        equals: userId,
                                    },
                                },
                            },
                        })
                        cache.evict({ id: deletedComment.comment.id })
                    }
                },
            }),
    }
}
