import React from 'react'

import { JoinRoom } from '../../../../generated/JoinRoom'
import {
    getRoomContentByActiveMenu,
    isVisiblePoll,
} from '../../../common/utilis'
import { SortMenu } from '../../Room'
import FlashNotification from './FlashNotification'
import LoadMore from './LoadMore'
import Poll from './poll/Poll'
import Post from './post/Post'

interface Props {
    data: JoinRoom
    activeMenu: SortMenu
    joinKey: string
    fetchMore: (filter?: string) => void
    roomInfo: {
        posts: number
        postsNotPub?: number
        comments?: number
    }
    setRoomInfo: Function
}

const MenuContent: React.FC<Props> = ({
    activeMenu,
    data,
    joinKey,
    fetchMore,
    roomInfo,
    setRoomInfo,
}) => {
    const role = data.roomAttendee?.role!
    const isModerated = data.roomAttendee?.room.isModerated!

    const activeContent = getRoomContentByActiveMenu(
        data.roomAttendee?.room!,
        activeMenu,
        role
    )

    const isCommentingAllowed = (): boolean => {
        const {
            isCommentsAllowed,
            polls,
            isPollOnly,
        } = data.roomAttendee?.room!
        const isCommentingAllowed =
            polls
                .filter((p) => p.isImmediateResults === false)
                .filter((p) => p.isOpened === true).length === 0 &&
            isCommentsAllowed

        return isCommentingAllowed && !isPollOnly
    }

    return (
        <div className="entry-threads">
            <FlashNotification />
            {/** posts list and polls list here  */}
            {activeContent.map((a) =>
                a.__typename === 'Poll' ? (
                    <Poll
                        key={a.id}
                        data={a}
                        dataJoinRoom={data}
                        joinKey={joinKey}
                        nickName={data.roomAttendee?.nickName}
                        isVisible={isVisiblePoll(a, data.roomAttendee?.role!)}
                    ></Poll>
                ) : (
                    <Post
                        key={a.id}
                        data={a}
                        fetchMore={fetchMore}
                        dataJoinRoom={data}
                        joinKey={joinKey}
                        nickName={data.roomAttendee?.nickName}
                        isCommentingAllowed={isCommentingAllowed()}
                        isCommentPublished={!isModerated}
                        setRoomInfo={setRoomInfo}
                        role={role}
                    ></Post>
                )
            )}
            <LoadMore
                activeContent={activeContent}
                fetchMore={fetchMore}
                activeMenu={activeMenu}
                roomInfo={roomInfo}
                role={role}
                isModerated={isModerated}
            />
        </div>
    )
}

export default MenuContent
