import { MutationResult } from '@apollo/client'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../AppLogger'
import { JoinRoom } from '../../../generated/JoinRoom'
import { UpdateOneRoomAttendee } from '../../../generated/UpdateOneRoomAttendee'

interface Props {
    data: JoinRoom
    updateRoomAttendee: Function
    updateRoomAttendeeStatus: MutationResult<UpdateOneRoomAttendee>
}

const logger = AppLogger.getInstance()
const NicknameModal: React.FC<Props> = ({
    data,
    updateRoomAttendee,
    updateRoomAttendeeStatus,
}) => {
    const { t } = useTranslation(['Room'])
    const ref = useRef<HTMLInputElement>(null)
    const [nickname, setNickname] = useState<string>('')

    const handleAddNickname = async () => {
        if (updateRoomAttendeeStatus.error) {
            // TODO handle error case
            logger.error(
                `handleAddNickname error:`,
                updateRoomAttendeeStatus.error
            )
        } else {
            if (!nickname) {
                if (ref && ref.current) {
                    ref.current.focus()
                }
            } else {
                try {
                    logger.info(`
                      nickname:${nickname}
                      role:${data.roomAttendee?.role}
                      roomId:${data.roomAttendee?.room.id}
                      `)
                    await updateRoomAttendee(
                        nickname,
                        data.roomAttendee?.role,
                        data.roomAttendee?.room.id
                    )
                } catch (e) {
                    logger.error(`updateRoomAttendee error:${e}`)
                }
            }
        }
    }
    return (
        <div className="modal">
            <div className="entry-input-nickname">
                <div className="form">
                    <input
                        type="text"
                        onChange={(e) => setNickname(e.target.value)}
                        placeholder={t('Nickname')}
                        className="input-text"
                        maxLength={20}
                        ref={ref}
                    />
                </div>
                <div
                    className="button-room confirm"
                    onClick={handleAddNickname}
                >
                    <span>{t('Submit')}</span>
                </div>
            </div>
        </div>
    )
}

export default NicknameModal
