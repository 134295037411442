import React from 'react'
import { $ } from 'react-jquery-plugin'
import { useTranslation } from 'react-i18next'
import {
    JoinRoom_roomAttendee_room_polls,
    JoinRoom_roomAttendee_room_posts,
} from '../../../../generated/JoinRoom'
import { SortMenu } from '../../Room'
import { FIRST_POSTS } from '../../../common/constant'
import { AttendeeRole } from '../../../../generated/globalTypes'

interface Props {
    activeMenu: SortMenu
    activeContent: (
        | JoinRoom_roomAttendee_room_polls
        | JoinRoom_roomAttendee_room_posts
    )[]
    fetchMore: () => void
    roomInfo: {
        posts: number
        postsNotPub?: number
        comments?: number
    }
    role: AttendeeRole
    isModerated: boolean
}

const LoadMore: React.FC<Props> = ({
    activeContent,
    fetchMore,
    activeMenu,
    roomInfo,
    role,
    isModerated,
}) => {
    const { t } = useTranslation(['Room'])
    const getPosts = () => {
        let isPublished = true
        if (activeMenu === SortMenu.MODERATE) {
            isPublished = false
        }
        const myPosts = activeContent.filter(
            (a) => a.__typename === 'Post' && a.isPublished === isPublished
        )

        return myPosts
    }
    const loadMore = async () => {
        await fetchMore()
    }

    if (activeMenu === SortMenu.MODERATE) {
        if (roomInfo.postsNotPub && getPosts().length < roomInfo.postsNotPub) {
            fetchMore()
        }
    } else if (role === AttendeeRole.Admin && isModerated) {
        if (getPosts().length < roomInfo.posts) {
            fetchMore()
        }
    }

    const shouldShowLoadMore = (): boolean => {
        if (
            $('body').hasClass('switch-options-open') ||
            activeMenu === SortMenu.MODERATE ||
            (role === AttendeeRole.Admin && isModerated)
        ) {
            return false
        } else {
            return (
                roomInfo.posts > FIRST_POSTS &&
                roomInfo.posts > getPosts().length
            )
        }
    }
    return (
        <div className="load-new-message">
            {shouldShowLoadMore() && (
                <button
                    className="button-submit"
                    type="button"
                    onClick={loadMore}
                >
                    <span className="new-message-text">{t('NewMessages')}</span>
                </button>
            )}
        </div>
    )
}

export default LoadMore
