import { useMutation, useSubscription } from '@apollo/client'
import { AppLogger } from '../../../../../../AppLogger'

import {
    DeleteOnePollOptionResult,
    DeleteOnePollOptionResultVariables,
} from '../../../../../../generated/DeleteOnePollOptionResult'
import { AttendeeRole } from '../../../../../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_polls_options,
    JoinRoom_roomAttendee_room_polls_options_results,
} from '../../../../../../generated/JoinRoom'
import {
    PollOptionResultCreateDataChange,
    PollOptionResultCreateDataChangeVariables,
} from '../../../../../../generated/PollOptionResultCreateDataChange'
import {
    PollOptionResultDeleteDataChange,
    PollOptionResultDeleteDataChangeVariables,
} from '../../../../../../generated/PollOptionResultDeleteDataChange'
import {
    PollOptionResultUpdateDataChange,
    PollOptionResultUpdateDataChangeVariables,
} from '../../../../../../generated/PollOptionResultUpdateDataChange'
import {
    PollOptionsResultUpdateDataChange,
    PollOptionsResultUpdateDataChangeVariables,
} from '../../../../../../generated/PollOptionsResultUpdateDataChange'

import {
    UpdateOnePollOption,
    UpdateOnePollOptionVariables,
} from '../../../../../../generated/UpdateOnePollOption'
import {
    UpdateOnePollOptionResult,
    UpdateOnePollOptionResultVariables,
} from '../../../../../../generated/UpdateOnePollOptionResult'
import {
    UpdatePollOptionResults,
    UpdatePollOptionResultsVariables,
} from '../../../../../../generated/UpdatePollOptionResults'
import {
    UpdatePollOptions,
    UpdatePollOptionsVariables,
} from '../../../../../../generated/UpdatePollOptions'

import {
    POLL_OPTION_UPDATE,
    POLL_OPTIONS_UPDATE,
    POLL_OPTION_RESULT_CREATE_SUBSCRIPTION,
    POLL_OPTION_RESULT_DELETE,
    POLL_OPTION_RESULT_DELETE_SUBSCRIPTION,
    POLL_OPTION_RESULT_UPDATE,
    POLL_OPTION_RESULT_UPDATE_SUBSCRIPTION,
    POLL_OPTIONS_RESULT_UPDATE,
    POLL_OPTIONS_RESULT_UPDATE_SUBSCRIPTION,
} from '../../../../../../queries/poll'
import { ROOM_JOIN } from '../../../../../../queries/room'
import {
    FIRST_POSTS,
    ORDER_BY_POST,
    ORDER_BY_PUBLISHED_POST,
    SESSION_KEY,
    SWITCH_SELECTED,
    WHERE_POST,
} from '../../../../../common/constant'
import { removeClassByOptionId } from '../helper'
const logger = AppLogger.getInstance()
export function useOption(
    joinKey: string,
    userId: string,
    data: JoinRoom,
    pollId: string,
    role: AttendeeRole,
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >,
    nickName: string | null | undefined
) {
    const polls = data?.roomAttendee?.room.polls || []
    const targetPoll = polls.find((p) => p.id === pollId)!
    /**
     * Mutations
     */

    const [updatePollOption, updatePollOptionStatus] =
        useMutation<UpdateOnePollOption, UpdateOnePollOptionVariables>(
            POLL_OPTION_UPDATE
        )

    const [updatePollOptions, updatePollOptionsStatus] =
        useMutation<UpdatePollOptions, UpdatePollOptionsVariables>(
            POLL_OPTIONS_UPDATE
        )

    const [updatePollOptionResult, updatePollOptionResultStatus] = useMutation<
        UpdateOnePollOptionResult,
        UpdateOnePollOptionResultVariables
    >(POLL_OPTION_RESULT_UPDATE)

    const [updatePollOptionsResult, updatePollOptionsResultStatus] =
        useMutation<UpdatePollOptionResults, UpdatePollOptionResultsVariables>(
            POLL_OPTIONS_RESULT_UPDATE
        )

    const [deletePollOptionResult, deletePollOptionResultStatus] = useMutation<
        DeleteOnePollOptionResult,
        DeleteOnePollOptionResultVariables
    >(POLL_OPTION_RESULT_DELETE)

    const getUpdatedPollOptionResults = (
        pollOptionId: string,
        optionResult: JoinRoom_roomAttendee_room_polls_options_results[]
    ): JoinRoom_roomAttendee_room_polls_options[] => {
        const pollOptions = targetPoll.options
        return pollOptions.map((po) => {
            if (po.id === pollOptionId) {
                return {
                    ...po,
                    results: optionResult,
                }
            } else {
                if (targetPoll.isMultipleResponses) {
                    return po
                } else {
                    return {
                        ...po,
                        results: [],
                    }
                }
            }
        })
    }

    /**
     * Subscriptions
     */

    /**
    |---------------------------------------------------------
    | Triggered on poll option result create
    | onSubscriptionData: 
    | 1 - update the current joined room by add this poll option result
    |---------------------------------------------------------
    */
    useSubscription<
        PollOptionResultCreateDataChange,
        PollOptionResultCreateDataChangeVariables
    >(POLL_OPTION_RESULT_CREATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(
                `POLL_OPTION_RESULT_CREATE_SUBSCRIPTION client:`,
                client
            )
            logger.debug(
                `POLL_OPTION_RESULT_CREATE_SUBSCRIPTION subscriptionData:`,
                subscriptionData
            )
            const createdPollOptionResult =
                subscriptionData.data?.onPollOptionResultCreate

            if (createdPollOptionResult) {
                // all polls
                const args = createdPollOptionResult.id?.split('_')
                const pollOptionId = args ? (args[1] as string) : ''

                if (targetPoll.options.find((o) => o.id === pollOptionId)) {
                    const pollOptionResults = getUpdatedPollOptionResults(
                        pollOptionId,
                        [createdPollOptionResult]
                    )

                    const updatedPolls = polls.map((p) => {
                        if (p.id === pollId) {
                            return {
                                ...p,
                                options: pollOptionResults,
                            }
                        }
                        return p
                    })

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    polls: [...updatedPolls],
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: null,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                }
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on poll option result update
    | onSubscriptionData: 
    | 1 - update the current joined room by updating this poll option result
    | 2 - editable field: isChecked
    |---------------------------------------------------------
    */
    useSubscription<
        PollOptionResultUpdateDataChange,
        PollOptionResultUpdateDataChangeVariables
    >(POLL_OPTION_RESULT_UPDATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(
                `POLL_OPTION_RESULT_UPDATE_SUBSCRIPTION client:`,
                client
            )
            logger.debug(
                `POLL_OPTION_RESULT_UPDATE_SUBSCRIPTION subscriptionData:`,
                subscriptionData
            )
            const updatedPollOptionResult =
                subscriptionData.data?.onPollOptionResultUpdate

            if (updatedPollOptionResult) {
                const args = updatedPollOptionResult.id?.split('_')
                const pollOptionId = args ? (args[1] as string) : ''

                if (targetPoll.options.find((o) => o.id === pollOptionId)) {
                    const pollOptionResults = getUpdatedPollOptionResults(
                        pollOptionId,
                        [updatedPollOptionResult]
                    )

                    const updatedPolls = polls.map((p) => {
                        if (p.id === pollId) {
                            return {
                                ...p,
                                options: pollOptionResults,
                            }
                        }
                        return p
                    })

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    polls: [...updatedPolls],
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: null,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                }
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on poll options result update
    | onSubscriptionData: 
    | 1 - update the current joined room by updating this poll option result
    | 2 - editable field: isChecked
    |---------------------------------------------------------
    */

    useSubscription<
        PollOptionsResultUpdateDataChange,
        PollOptionsResultUpdateDataChangeVariables
    >(POLL_OPTIONS_RESULT_UPDATE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(
                `POLL_OPTIONS_RESULT_UPDATE_SUBSCRIPTION client:`,
                client
            )
            logger.debug(
                `POLL_OPTIONS_RESULT_UPDATE_SUBSCRIPTION subscriptionData:`,
                subscriptionData
            )
            const updatedPollOptionResult =
                subscriptionData.data?.onPollOptionsResultUpdate

            if (updatedPollOptionResult) {
                const args = updatedPollOptionResult.id.split('_')
                const pollOptionId = args ? (args[1] as string) : ''

                if (targetPoll.options.find((o) => o.id === pollOptionId)) {
                    const pollOptionResults = getUpdatedPollOptionResults(
                        pollOptionId,
                        [updatedPollOptionResult]
                    )

                    const updatedPolls = polls.map((p) => {
                        if (p.id === pollId) {
                            return {
                                ...p,
                                options: pollOptionResults,
                            }
                        }
                        return p
                    })
                    logger.debug(
                        `POLL_OPTIONS_RESULT_UPDATE_SUBSCRIPTION updatedPolls`,
                        updatedPolls
                    )

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    polls: updatedPolls,
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: null,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                }
            }
        },
    })

    /**
    |---------------------------------------------------------
    | Triggered on poll option result delete
    | onSubscriptionData: 
    | 1 - update the current joined room by deleting this poll option result
    |---------------------------------------------------------
    */
    useSubscription<
        PollOptionResultDeleteDataChange,
        PollOptionResultDeleteDataChangeVariables
    >(POLL_OPTION_RESULT_DELETE_SUBSCRIPTION, {
        variables: {
            userId,
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            logger.debug(
                `POLL_OPTION_RESULT_DELETE_SUBSCRIPTION client:`,
                client
            )
            logger.debug(
                `POLL_OPTION_RESULT_DELETE_SUBSCRIPTION subscriptionData:`,
                subscriptionData
            )
            const deletedPollOptionResult =
                subscriptionData.data?.onPollOptionResultDelete

            if (deletedPollOptionResult) {
                const args = deletedPollOptionResult.id?.split('_')
                const pollOptionId = args ? (args[1] as string) : ''
                if (targetPoll.options.find((o) => o.id === pollOptionId)) {
                    /** added to remove the selection */
                    removeClassByOptionId(
                        pollOptionId,
                        classListMap,
                        SWITCH_SELECTED
                    )

                    const pollOptionResults = getUpdatedPollOptionResults(
                        pollOptionId,
                        []
                    )
                    const updatedPolls = polls.map((p) => {
                        if (p.id === pollId) {
                            return {
                                ...p,
                                options: pollOptionResults,
                            }
                        }
                        return p
                    })

                    client.writeQuery({
                        query: ROOM_JOIN,
                        data: {
                            roomAttendee: {
                                ...data?.roomAttendee,
                                room: {
                                    ...data?.roomAttendee?.room,
                                    polls: [...updatedPolls],
                                },
                            },
                        },
                        variables: {
                            joinKey,
                            nickName,
                            firstPost: FIRST_POSTS,
                            orderByPost: ORDER_BY_POST,
                            orderByPublishedPost: ORDER_BY_PUBLISHED_POST,
                            afterPost: null,
                            wherePost: WHERE_POST,
                            filterPollRes: {
                                userId: {
                                    equals: userId,
                                },
                            },
                        },
                    })
                }
            }
        },
    })

    /**
     * useEffect hooks
     */

    return {
        updatePollOptionStatus,
        updatePollOption: async (pollOptionId: string, isChecked: boolean) =>
            await updatePollOption({
                variables: {
                    where: {
                        id: pollOptionId,
                    },
                    data: {
                        isChecked: {
                            set: isChecked,
                        },
                        modifiedBy: {
                            set: sessionStorage.getItem(SESSION_KEY),
                        },
                    },
                },
                update: (cache, { data: updatePollOptionRes }) => {
                    if (updatePollOptionRes) {
                        const updatePollOption = updatePollOptionRes.pollOption!
                        if (updatePollOption) {
                            const updatedPollOptions = targetPoll.options.map(
                                (po) => {
                                    return {
                                        ...po,
                                        isChecked:
                                            po.id === updatePollOption.id
                                                ? updatePollOption.isChecked
                                                : targetPoll.isMultipleResponses
                                                ? po.isChecked
                                                : false, // if poll is multi responses poll then integrate other result else put the received one
                                    }
                                }
                            )

                            const updatedPolls = polls.map((p) => {
                                if (p.id === pollId) {
                                    return {
                                        ...p,
                                        options: updatedPollOptions,
                                    }
                                }
                                return p
                            })

                            logger.debug(
                                `updatePollOption after merging results : ${JSON.stringify(
                                    updatedPolls,
                                    null,
                                    3
                                )}`
                            )
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls: [...updatedPolls],
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),

        updatePollOptionResultStatus,
        updatePollOptionResult: async (
            pollOptionId: string,
            isChecked: boolean
        ) =>
            await updatePollOptionResult({
                variables: {
                    data: {
                        isChecked: {
                            set: isChecked,
                        },
                        id: {
                            set: `${userId}_${pollOptionId}`,
                        },
                    },
                    where: {
                        id: `${userId}_${pollOptionId}`,
                    },
                },
                update: (cache, { data: updatePollOptionResultRes }) => {
                    if (updatePollOptionResultRes) {
                        const updatePollOptionResult =
                            updatePollOptionResultRes.pollOptionResult!

                        if (data) {
                            const pollOptionResults =
                                getUpdatedPollOptionResults(pollOptionId, [
                                    updatePollOptionResult,
                                ])

                            const updatedPolls = polls.map((p) => {
                                if (p.id === pollId) {
                                    return {
                                        ...p,
                                        options: pollOptionResults,
                                    }
                                }
                                return p
                            })

                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls: [...updatedPolls],
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),

        updatePollOptionsResultStatus,
        updatePollOptionsResult: async (
            pollId: string,
            isImmediateResultsBroadcast: boolean,
            checkedOpId: string
        ) =>
            await updatePollOptionsResult({
                variables: {
                    pollId,
                    isImmediateResultsBroadcast,
                    checkedOpId,
                },
                update: (cache, { data: updatePollOptionsResultRes }) => {
                    if (updatePollOptionsResultRes) {
                        const updatePollOptionsResult =
                            updatePollOptionsResultRes.pollOptionResult!

                        if (data) {
                            const pollOptionResults =
                                getUpdatedPollOptionResults(checkedOpId, [
                                    updatePollOptionsResult,
                                ])

                            const updatedPolls = polls.map((p) => {
                                if (p.id === pollId) {
                                    return {
                                        ...p,
                                        options: pollOptionResults,
                                    }
                                }
                                return p
                            })

                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls: [...updatedPolls],
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),
        updatePollOptionsStatus,
        updatePollOptions: async (pollId: string, checkedOpId: string) =>
            await updatePollOptions({
                variables: {
                    pollId,
                    checkedOpId,
                    modifiedBy: sessionStorage.getItem(SESSION_KEY),
                },
                update: (cache, { data: updatePollOptionsRes }) => {
                    if (updatePollOptionsRes) {
                        const updatePollOptions =
                            updatePollOptionsRes.pollOption!
                        if (data) {
                            const updatedPollOptions = targetPoll.options.map(
                                (po) => {
                                    return {
                                        ...po,
                                        isChecked:
                                            po.id === updatePollOptions.id
                                                ? updatePollOptions.isChecked
                                                : targetPoll.isMultipleResponses
                                                ? po.isChecked
                                                : false, // if poll is multi responses poll then integrate other result else put the received one
                                    }
                                }
                            )

                            const updatedPolls = polls.map((p) => {
                                if (p.id === pollId) {
                                    return {
                                        ...p,
                                        options: updatedPollOptions,
                                    }
                                }
                                return p
                            })

                            logger.debug(
                                `updatePollOption after merging results : ${JSON.stringify(
                                    updatedPolls,
                                    null,
                                    3
                                )}`
                            )
                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls: [...updatedPolls],
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),
        deletePollOptionResultStatus,
        deletePollOptionResult: async (pollOptionId: string) =>
            await deletePollOptionResult({
                variables: {
                    where: {
                        id: `${userId}_${pollOptionId}`,
                    },
                },
                update: (cache, { data: deletePollOptionResultRes }) => {
                    if (deletePollOptionResultRes) {
                        if (data) {
                            const pollOptionResults =
                                getUpdatedPollOptionResults(pollOptionId, [])

                            const updatedPolls = polls.map((p) => {
                                if (p.id === pollId) {
                                    return {
                                        ...p,
                                        options: pollOptionResults,
                                    }
                                }
                                return p
                            })

                            cache.writeQuery({
                                query: ROOM_JOIN,
                                data: {
                                    roomAttendee: {
                                        ...data?.roomAttendee,
                                        room: {
                                            ...data?.roomAttendee?.room,
                                            polls: [...updatedPolls],
                                        },
                                    },
                                },
                                variables: {
                                    joinKey,
                                    nickName,
                                    firstPost: FIRST_POSTS,
                                    orderByPost: ORDER_BY_POST,
                                    orderByPublishedPost:
                                        ORDER_BY_PUBLISHED_POST,
                                    afterPost: null,
                                    wherePost: WHERE_POST,
                                    filterPollRes: {
                                        userId: {
                                            equals: userId,
                                        },
                                    },
                                },
                            })
                        }
                    }
                },
            }),
    }
}
