import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'ts-debounce'
import { AppLogger } from '../../../../../../../AppLogger'

import { getUserIdFromToken } from '../../../../../../../auth'
import { AttendeeRole } from '../../../../../../../generated/globalTypes'
import {
    JoinRoom,
    JoinRoom_roomAttendee_room_polls_options,
} from '../../../../../../../generated/JoinRoom'
import {
    CLASS_ROW,
    DEBOUNCED_WAIT,
    SWITCH_SOLUTION,
} from '../../../../../../common/constant'
import {
    addClassByOptionId,
    getCheckedOptionId,
    isOptionHasClassName,
    removeClassByOptionId,
} from '../../helper'
import { useOption } from '.././useOption'

const logger = AppLogger.getInstance()

/**
 *
 */
interface Props {
    pollId: string
    dataJoinRoom: JoinRoom
    joinKey: string
    role: AttendeeRole
    option: JoinRoom_roomAttendee_room_polls_options
    classListMap: React.MutableRefObject<
        Map<string, React.RefObject<HTMLDivElement>>
    >
    isMultipleResponses: boolean
    isSolutions: boolean

    progress?: number
    votes?: number
    nickName: string | null | undefined
}
const AdminOption: React.FC<Props> = ({
    pollId,
    dataJoinRoom,
    joinKey,
    role,
    option,
    classListMap,
    isMultipleResponses,
    isSolutions,
    progress = 0,
    votes = 0,
    nickName,
}) => {
    const userId = getUserIdFromToken()!
    const refClassList = useRef<HTMLDivElement>(null)

    const {
        updatePollOptions,
        updatePollOptionsStatus,
        updatePollOption,
        updatePollOptionStatus,
    } = useOption(
        joinKey,
        userId,
        dataJoinRoom,
        pollId,
        role,
        classListMap,
        nickName
    )

    const [classList] = useState<string>(() => {
        let _classList = ''
        if (isSolutions) {
            if (option.isChecked) {
                _classList = SWITCH_SOLUTION
            }
        }
        return _classList
    })

    /**
     * Mono response Poll
     * PollOption debounced functions
     */
    const debouncedUpdatePollOptions = debounce(
        updatePollOptions,
        DEBOUNCED_WAIT,
        { isImmediate: false }
    )

    // Mono && Multi
    const debounceUpdatePollOption = debounce(
        updatePollOption,
        DEBOUNCED_WAIT,
        { isImmediate: false }
    )

    const mutate = async () => {
        if (updatePollOptionsStatus.error || updatePollOptionStatus.error) {
            //TODO handle error
            return
        }
        // Admin can submit his answer(s) when isSolutions flag is set to true
        if (isSolutions) {
            if (isMultipleResponses) {
                // there is no submit button
                const prevStatusChecked = isOptionHasClassName(
                    option.id,
                    classListMap,
                    SWITCH_SOLUTION
                )
                if (prevStatusChecked) {
                    // case 1: option was checked  the new  checked status will be set to false
                    removeClassByOptionId(
                        option.id,
                        classListMap,
                        SWITCH_SOLUTION
                    )
                    await debounceUpdatePollOption(option.id, false)
                } else {
                    // case 2: option was unchecked, the new checked status will be set to true
                    addClassByOptionId(option.id, classListMap, SWITCH_SOLUTION)
                    await debounceUpdatePollOption(option.id, true)
                }
            } else {
                const prevSelectedOptionId = getCheckedOptionId(
                    classListMap,
                    SWITCH_SOLUTION
                )
                if (prevSelectedOptionId) {
                    if (prevSelectedOptionId !== option.id) {
                        //case 1: new checked option is # than the previous one
                        removeClassByOptionId(
                            prevSelectedOptionId,
                            classListMap,
                            SWITCH_SOLUTION
                        )
                        addClassByOptionId(
                            option.id,
                            classListMap,
                            SWITCH_SOLUTION
                        )
                        await debouncedUpdatePollOptions(pollId, option.id)
                    } else {
                        //case 2: new checked option is the same as the previous
                        removeClassByOptionId(
                            option.id,
                            classListMap,
                            SWITCH_SOLUTION
                        )
                        await debounceUpdatePollOption(option.id, false)
                    }
                } else {
                    // case 3: new checked option (there was no previous option checked)
                    addClassByOptionId(option.id, classListMap, SWITCH_SOLUTION)
                    await debounceUpdatePollOption(option.id, true)
                }
            }
        }
    }

    /*
    const getOptionClass = () => {
        // try this block
         
        if (isSolutions) {
            if (option.isChecked) {
                addClassByOptionId(option.id, classListMap, SWITCH_SOLUTION)
            } else {
                removeClassByOptionId(option.id, classListMap, SWITCH_SOLUTION)
            }
        }
        return ''
    }
    */
    useEffect(() => {
        // register option
        classListMap.current.set(option.id, refClassList)

        return () => {
            logger.debug('--- useEffect cleanUp .........')
        }
    }, [option.id, classListMap])

    return (
        <div className={`${CLASS_ROW} ${classList}`} ref={refClassList}>
            <div className="row-label" onClick={mutate}>
                <p>{option.label}</p>
            </div>
            <div className="row-progress">
                <div className="progress" style={{ width: `${progress}%` }}>
                    <div className="row-value">
                        <p> {progress}%</p>
                    </div>
                </div>
            </div>
            <div className="row-count">
                <p>{votes}</p>
            </div>
        </div>
    )
}

export default AdminOption
