import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { $ } from 'react-jquery-plugin'
import { JoinRoom } from '../../../generated/JoinRoom'
import { AttendeeRole } from '../../../generated/globalTypes'
import { useTranslation } from 'react-i18next'
import { SWITCH_ON } from '../../common/constant'
import { AppLogger } from '../../../AppLogger'

const logger = AppLogger.getInstance()
/**
 *
 */
interface Props {
    data: JoinRoom
    joinKey: string
    updateRoomAttendeeLastSeen: Function
}
const Header: React.FC<Props> = ({
    data,
    joinKey,
    updateRoomAttendeeLastSeen,
}) => {
    const { t } = useTranslation(['Room'])
    const history = useHistory()

    const isAdminAttendee =
        data.roomAttendee?.role! === AttendeeRole.Admin ? true : false

    const toggleRoomInfoView = () => {
        $('body').toggleClass('switch-information-container-on')
        $('body').removeClass('switch-remove-room-on')
        $('body').removeClass('switch-leave-room-on')
        $('body').removeClass('switch-clear-room-on')

        setTimeout(() => {
            $('#copyAdminCode').removeClass(SWITCH_ON)
            $('#copyGuestCode').removeClass(SWITCH_ON)
        }, 0)
    }

    useEffect(() => {
        if (isAdminAttendee) {
            $('body').addClass('flag-admin')
        }
        return () => {
            let classList = 'switch-information-container-on'
            classList += isAdminAttendee ? ' flag-admin' : ' '
            $('body').removeClass(classList)
        }
    }, [isAdminAttendee])

    return (
        <header className="header">
            <h1>
                {data.roomAttendee?.room.name}
                {data.roomAttendee?.role === AttendeeRole.Admin && (
                    <span className="admin-badge">{t('AdminBadge')}</span>
                )}
            </h1>
            <div className="button-back">
                <a
                    href="/"
                    onClick={async (e) => {
                        e.preventDefault()
                        try {
                            const roomId = data.roomAttendee?.room.id
                            const role = data.roomAttendee?.role
                            await updateRoomAttendeeLastSeen(roomId, role)
                        } catch (e) {
                            logger.error(
                                `Error when calling updateRoomAttendeeLastSeen`
                            )
                        }

                        if ($('body').hasClass('switch-modal')) {
                            window.location.replace('/')
                        } else {
                            history.push(
                                {
                                    pathname: '/',
                                },
                                {
                                    screen: 'room',
                                }
                            )
                        }
                    }}
                >
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                    >
                        <polygon
                            points="100 50 50 0 0 50 0 100 100 100 100 50"
                            vectorEffect="non-scaling-stroke"
                        />
                    </svg>
                </a>
            </div>
            <div className="logo">
                <a
                    href="/room"
                    onClick={(e) => {
                        e.preventDefault()

                        history.push(
                            {
                                pathname: '/room',
                            },
                            {
                                screen: 'room',
                                joinKey,
                            }
                        )
                    }}
                >
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 530 1000"
                    >
                        <path d="M221.60329,1000V589.11313c-38.60519-15.47873-86.04686-54.12973-86.04686-54.12973L9.269,408.51732A26.35884,26.35884,0,0,1,9.001,371.165h0a26.275,26.275,0,0,1,18.58684-7.87449c15.94872.7169,28.83193,11.13836,38.58458,20.91391C83.55222,401.58419,125.5358,450.904,156.82765,450.904s64.77564-63.88238,64.77564-117.33872V76.02479a26.40522,26.40522,0,0,1,52.81044,0V303.05448H296.624L307.62954,25.33987a26.40349,26.40349,0,0,1,52.7651,2.10262l-10.93727,275.612h23.90979L399.44829,52.89832a26.41157,26.41157,0,0,1,52.54064,5.45581L426.46849,303.05448H444.4076l.62482,1.41091,29.99547-166.796a26.39645,26.39645,0,1,1,51.9584,9.34953L483.43835,389.21587c-12.07973,67.10043-28.4082,139.5055-63.68082,178.06717L443.53921,1000Z" />
                    </svg>
                </a>
            </div>
            <div className="button-information" onClick={toggleRoomInfoView}>
                <div className="open">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                    >
                        <polygon
                            points="0 0 100 0 100 100 0 100"
                            vectorEffect="non-scaling-stroke"
                        />
                    </svg>
                </div>
                <div className="close">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                    >
                        <line
                            x1="0"
                            y1="0"
                            x2="100"
                            y2="100"
                            vectorEffect="non-scaling-stroke"
                        />
                        <line
                            x1="0"
                            y1="100"
                            x2="100"
                            y2="0"
                            vectorEffect="non-scaling-stroke"
                        />
                    </svg>
                </div>
            </div>
        </header>
    )
}

export default Header
