import { gql } from '@apollo/client'

export const userDetailFragment = gql`
    fragment UserDetail on User {
        id
        profileId
        language
        theme
    }
`

export const postDetailFragment = gql`
    fragment PostDetail on Post {
        id
        content
        nickName
        isFavorite
        isPublished
        createdAt
        updatedAt
        modifiedBy
        createdBy {
            id
        }
        room {
            id
        }
    }
`

export const postReactionDetailFragment = gql`
    fragment PostReactionDetail on PostReaction {
        id
        reactionType
        createdAt
        updatedAt
        modifiedBy
    }
`

export const commentDetailFragment = gql`
    fragment CommentDetail on Comment {
        id
        content
        nickName
        isPublished
        createdAt
        updatedAt
        createdBy {
            id
        }
        post {
            id
        }
        modifiedBy
    }
`

export const commentReactionDetailFragment = gql`
    fragment CommentReactionDetail on CommentReaction {
        id
        reactionType
        createdAt
        updatedAt
        modifiedBy
    }
`
export const pollOptionDetailFragment = gql`
    fragment PollOptionDetail on PollOption {
        id
        label
        isChecked
        createdAt
        updatedAt
        modifiedBy
    }
`

export const pollOptionResultDetailFragment = gql`
    fragment PollOptionResultDetail on PollOptionResult {
        id
        isChecked
        createdAt
        updatedAt
    }
`
export const pollDetailFragment = gql`
    fragment PollDetail on Poll {
        id
        content
        extendedResultData
        nickName
        isMultipleResponses
        isSolutions
        isImmediateResults
        isImmediateResultsBroadcast
        isOpened
        isResults
        createdAt
        updatedAt

        options {
            ...PollOptionDetail
        }
        createdBy {
            id
        }
        room {
            id
        }
        modifiedBy
    }
    ${pollOptionDetailFragment}
`

export const roomAttendeeDetailLobbyFragment = gql`
    fragment RoomAttendeeDetail on RoomAttendee {
        id
        role
        joinKey
        nickName
        lastSeenAt
        room {
            id
            name
            num
            posts {
                id

                isFavorite
                isPublished
                createdAt
                updatedAt
                comments {
                    id
                    isPublished
                    createdAt
                    updatedAt
                }
            }
            polls(
                first: 1
                orderBy: { createdAt: desc }
                where: { isOpened: { equals: true } }
            ) {
                id
                createdAt
            }
        }
    }
`

export const roomFullDetailFragment = gql`
    fragment RoomDetail on Room {
        id
        name
        num
        adminKey
        isTemporary
        isNicknames
        isModerated
        isPollOnly
        isCommentsAllowed
        isAutomaticFlow
        createdAt
        updatedAt
        modifiedBy
        createdBy {
            id
        }
        polls {
            ...PollDetail
            options {
                ...PollOptionDetail
                results(where: $filterPollRes) {
                    ...PollOptionResultDetail
                }
            }
        }
        posts(
            first: $firstPost
            orderBy: [$orderByPublishedPost, $orderByPost]
            after: $afterPost
            where: $wherePost
        ) {
            ...PostDetail
            reactions {
                ...PostReactionDetail
            }
            comments {
                ...CommentDetail
                reactions {
                    ...CommentReactionDetail
                }
            }
        }
    }

    ${postDetailFragment}
    ${postReactionDetailFragment}
    ${commentDetailFragment}
    ${commentReactionDetailFragment}
    ${pollDetailFragment}
    ${pollOptionDetailFragment}
    ${pollOptionResultDetailFragment}
`

export const pollResultDetailFragment = gql`
    fragment PollResultsDetail on Poll {
        ...PollDetail
        options {
            ...PollOptionDetail
            results(where: $filter) {
                ...PollOptionResultDetail
            }
        }
    }
    ${pollDetailFragment}
    ${pollOptionDetailFragment}
    ${pollOptionResultDetailFragment}
`
