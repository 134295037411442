import React, { useEffect } from 'react'
import { isIOS, isAndroid } from 'react-device-detect'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { $ } from 'react-jquery-plugin'
//import ReCAPTCHA from 'react-google-recaptcha'
import { useAuth } from './common/hooks/useAuth'
import Home from './Home/Home'
import Room from './Room/Room'
import GdprNotice from './GdprNotice'
import External from './External/External'

const getExternalParamsFromProps = (
    typename: 'Post' | 'Poll',
    params: Record<string, string>
): {
    joinKey: string
    objId: string
    typename: 'Post' | 'Poll'
} => {
    const eParams = {
        joinKey: '',
        objId: '',
    }
    if (params) {
        if (params.joinKey) {
            eParams.joinKey = params.joinKey
        }
        eParams.objId = typename === 'Post' ? params.postId : params.pollId
    }
    return { ...eParams, typename }
}

const Dispatcher: React.FC = (props: any) => {
    //let reRef = useRef<ReCAPTCHA>(null)
    const { loading } = useAuth()

    useEffect(() => {
        $('body').addClass('flag-loading')
        if (!loading) {
            $('body').removeClass('flag-loading')
        }

        if (isIOS) {
            // for top safe zone impacts iphone mobile app
            $('body').addClass('flag-extratop-iphone')

            // for bottom safe zone
            $('body').addClass('flag-iphone-layout')
        }
        if (isAndroid) {
            // for top safe zone impacts android mobile app
            $('body').addClass('flag-extratop-android')
        }
        return () => {
            $('body').removeClass('flag-loading')
        }
    }, [loading])

    const renderHome = (props: any) => {
        return (
            <>
                <ReactNotifications
                    isMobile={true}
                    className="react-notification-width"
                />
                <Home {...props} />
                <GdprNotice />
            </>
        )
    }

    const renderRoom = (props: any) => {
        return (
            <>
                <Room {...props} />
                <GdprNotice />
            </>
        )
    }

    const renderPoll = (props: any) => {
        return (
            <>
                <External
                    {...getExternalParamsFromProps('Poll', props.match.params)}
                />
            </>
        )
    }

    const renderPost = (props: any) => {
        return (
            <>
                <External
                    {...getExternalParamsFromProps('Post', props.match.params)}
                />
            </>
        )
    }

    const render = (props: any) => {
        if (props.location.pathname.startsWith('/home')) {
            return renderHome(props)
        } else if (props.location.pathname.startsWith('/poll')) {
            return renderPoll(props)
        } else if (props.location.pathname.startsWith('/post')) {
            return renderPost(props)
        } else {
            return renderRoom(props)
        }
    }

    return loading ? (
        <div>
            <label></label>
            {/*
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                size="invisible"
                ref={reRef}
            />
            */}
        </div>
    ) : (
        render(props)
    )
}

export default Dispatcher
