import React, { useRef } from 'react'
import { $ } from 'react-jquery-plugin'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'
import { JoinRoom } from '../../../generated/JoinRoom'
import { useTranslation } from 'react-i18next'
import { AppLogger } from '../../../AppLogger'
import { SWITCH_ON } from '../../common/constant'
import { roomExport } from '../../common/excel-export'
import { isMobile } from 'react-device-detect'
import { EventAction, EventCategory, gaEvent } from '../../common/utilis'
import QRCode from './QRCode'

interface Props {
    data: JoinRoom
    joinKey: string
    exportRoom: Function
    leaveRoom: Function
    deleteRoom: Function
    updateRoom: Function
    deleteRoomContent: Function
    roomInfo: {
        posts: number
        postsNotPub?: number
        comments?: number
    }
}

const logger = AppLogger.getInstance()
const Settings: React.FC<Props> = ({
    data,
    leaveRoom,
    deleteRoom,
    updateRoom,
    joinKey,
    roomInfo,
    deleteRoomContent,
}) => {
    const { t } = useTranslation(['Room'])
    let allPosts = roomInfo.posts
    if (roomInfo.postsNotPub) {
        allPosts += roomInfo.postsNotPub
    }
    const refBtnGuestCode = useRef<HTMLLIElement>(null)
    const refBtnAdminCode = useRef<HTMLLIElement>(null)

    const handleLeaveRoom = async () => {
        const roomId = data.roomAttendee?.room.id
        const joinRole = data.roomAttendee?.role
        await leaveRoom(roomId, joinRole)
        gaEvent(
            EventCategory.Room,
            EventAction.Leave,
            data.roomAttendee?.room.name!
        )
    }

    const handleRoomUpdate = async (
        isAutomaticFlow: boolean,
        isCommentsAllowed: boolean
    ) => {
        logger.debug(
            `isAutomaticFlow:${isAutomaticFlow} - isCommentsAllowed:${isCommentsAllowed}`
        )

        await updateRoom(
            data.roomAttendee?.room.id,
            isAutomaticFlow,
            isCommentsAllowed
        )
    }

    const handleDeleteRoom = async () => {
        await deleteRoom(data.roomAttendee?.room.id)
        gaEvent(
            EventCategory.Room,
            EventAction.Delete,
            data.roomAttendee?.room.name!
        )
    }

    const handleClearRoom = async () => {
        await deleteRoomContent(data.roomAttendee?.room.id)
        $('body').toggleClass('switch-information-container-on')
        $('body').removeClass('switch-clear-room-on')
    }

    const copyAdminCode = () => {
        if (refBtnAdminCode.current) {
            if (!refBtnAdminCode.current.classList.contains(SWITCH_ON)) {
                refBtnAdminCode.current.classList.add(SWITCH_ON)
            }
        }
    }

    const hideAdminCode = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (refBtnAdminCode.current) {
            if (refBtnAdminCode.current.classList.contains(SWITCH_ON.trim())) {
                refBtnAdminCode.current.classList.remove(SWITCH_ON)
            }
        }
    }

    const copyGuestCode = () => {
        if (refBtnGuestCode.current) {
            if (!refBtnGuestCode.current.classList.contains(SWITCH_ON)) {
                refBtnGuestCode.current.classList.add(SWITCH_ON)
            }
        }
    }

    const hideGuestCode = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (refBtnGuestCode.current) {
            if (refBtnGuestCode.current.classList.contains(SWITCH_ON.trim())) {
                refBtnGuestCode.current.classList.remove(SWITCH_ON)
            }
        }
    }

    const messageCount = (count: number) => {
        const transKey = count < 2 ? `Messages${count}` : `Messages`
        return `${count} ${t(transKey)}`
    }

    const commentCount = (count: number) => {
        const transKey = count < 2 ? `Comments${count}` : `Comments`
        return `${count} ${t(transKey)}`
    }

    const handleExportRoom = async () => {
        const roomId = data.roomAttendee?.room.id!
        await roomExport(roomId)
        gaEvent(
            EventCategory.Room,
            EventAction.Delete,
            data.roomAttendee?.room.name!
        )
    }

    const cleanBodyCLasses = () => {
        $('body').removeClass('switch-leave-room-on')
        $('body').removeClass('switch-remove-room-on')
        $('body').removeClass('switch-clear-room-on')
    }

    return (
        <>
            <div className="menu-clear-room">
                <div className="button-room cancel" onClick={cleanBodyCLasses}>
                    <span>{t('Home:Cancel')}</span>
                </div>
                <div
                    className="button-room clear"
                    onClick={async () => {
                        await handleClearRoom()
                    }}
                >
                    <span>{t('Home:ConfirmClear')}</span>
                </div>
            </div>

            <div className="menu-delete-room">
                <div className="button-room cancel" onClick={cleanBodyCLasses}>
                    <span>{t('Home:Cancel')}</span>
                </div>
                <div
                    className="button-room delete"
                    onClick={async () => {
                        await handleDeleteRoom()
                    }}
                >
                    <span>{t('Home:ConfirmDeletion')}</span>
                </div>
            </div>

            <div className="menu-leave-room">
                <div className="button-room cancel" onClick={cleanBodyCLasses}>
                    <span>{t('Home:Cancel')} </span>
                </div>
                <div
                    className="button-room leave"
                    onClick={async () => {
                        await handleLeaveRoom()
                    }}
                >
                    <span>{t('Home:ConfirmLeave')}</span>
                </div>
            </div>

            <div className="container-information">
                <div className="entry-information">
                    <h2>{t('RoomInformation')}</h2>
                    <p>
                        {t('Number')}: {data.roomAttendee?.room.num}
                    </p>
                    {data.roomAttendee?.room.isTemporary && (
                        <p>
                            {t('Expires')}:{' '}
                            {moment(data.roomAttendee?.room.createdAt)
                                .add(1, 'day')
                                .format('DD/MM/YYYY HH:mm')}
                        </p>
                    )}

                    {data.roomAttendee?.nickName && (
                        <p>
                            {t('Nickname')}: {data.roomAttendee?.nickName}
                        </p>
                    )}

                    <div className="statistics">
                        <p>{messageCount(allPosts)}</p>
                        <p>
                            {commentCount(
                                roomInfo.comments ? roomInfo.comments : 0
                            )}
                        </p>
                    </div>

                    <form className="entry-switch comments-allowed">
                        <div className="label">
                            <h4>{t('CommentsAllowed')}</h4>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={
                                        data.roomAttendee?.room
                                            .isCommentsAllowed
                                    }
                                    onChange={(e) => {
                                        handleRoomUpdate(
                                            !!data.roomAttendee?.room
                                                .isAutomaticFlow,
                                            e.target.checked
                                        )
                                    }}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {/* 
                        <div className="label">
                            <h4>{t('MessageAutomaticFlow')}</h4>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={settings.isAutomaticFlow}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        handleRoomUpdate(
                                            e.target.checked,
                                            !!data.roomAttendee?.room
                                                .isCommentsAllowed
                                        )
                                    }}
                                />

                                <span className="slider round"></span>
                            </label>
                        </div>
                        */}
                    </form>

                    <ul className="room-buttons">
                        <li
                            className="button button-code"
                            onClick={copyAdminCode}
                            id="copyAdminCode"
                            ref={refBtnAdminCode}
                        >
                            <p className={'admin-key'}>
                                {data.roomAttendee?.joinKey}
                            </p>

                            <CopyToClipboard text={data.roomAttendee!.joinKey}>
                                <p className="button-text">
                                    {t('GenerateAdminCode')}
                                </p>
                            </CopyToClipboard>

                            <div
                                className="admin-key-close"
                                onClick={hideAdminCode}
                            >
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <line
                                        x1="0"
                                        y1="0"
                                        x2="100"
                                        y2="100"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                    <line
                                        x1="0"
                                        y1="100"
                                        x2="100"
                                        y2="0"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                </svg>
                            </div>
                        </li>

                        <li
                            className="button button-code"
                            id="copyGuestCode"
                            onClick={copyGuestCode}
                            ref={refBtnGuestCode}
                        >
                            <p className="admin-key">{t('GuestUrlCopied')}</p>

                            <CopyToClipboard
                                text={`${window.location}/join/${data
                                    .roomAttendee?.room.num!}`}
                            >
                                <p className="button-text">
                                    {t('GenerateGuestCode')}
                                </p>
                            </CopyToClipboard>
                            <div
                                className="admin-key-close"
                                onClick={hideGuestCode}
                            >
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                >
                                    <line
                                        x1="0"
                                        y1="0"
                                        x2="100"
                                        y2="100"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                    <line
                                        x1="0"
                                        y1="100"
                                        x2="100"
                                        y2="0"
                                        vectorEffect="non-scaling-stroke"
                                    />
                                </svg>
                            </div>
                        </li>

                        <QRCode roomKey={data.roomAttendee?.room.num!} />
                        {!isMobile && (
                            <li
                                className="button button-export"
                                onClick={handleExportRoom}
                            >
                                <p>{t('ExportAsCSV')}</p>
                            </li>
                        )}

                        <li
                            className="button button-clear"
                            onClick={() => {
                                $('.menu-clear-room').show()
                                $('.menu-leave-room').hide()
                                $('.menu-delete-room').hide()
                                $('body').addClass('switch-clear-room-on')
                            }}
                        >
                            <p>{t('ClearTheRoom')}</p>
                        </li>
                        <li
                            className="button button-leave"
                            onClick={() => {
                                $('.menu-leave-room').show()
                                $('.menu-delete-room').hide()
                                $('.menu-clear-room').hide()
                                $('body').addClass('switch-leave-room-on')
                            }}
                        >
                            <p>{t('LeaveTheRoom')}</p>
                        </li>

                        <li
                            onClick={() => {
                                $('.menu-delete-room').show()
                                $('.menu-leave-room').hide()
                                $('.menu-clear-room').hide()
                                $('body').addClass('switch-remove-room-on')
                            }}
                            className="button button-delete"
                            data-delete="delete-room-template-message"
                        >
                            <p>{t('DeleteTheRoom')}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Settings
