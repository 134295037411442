import { alignment, defaultDataType } from 'export-xlsx'
// Export settings

export const getSettingsForExport = (fileName: string) => {
    return {
        // Table settings
        fileName,
        workSheets: [
            {
                sheetName: 'Room Summary',

                startingRowNumber: 2,
                gapBetweenTwoTables: 2,
                tableSettings: {
                    roomData: {
                        importable: true,
                        tableTitle: 'Room Summary',

                        headerGroups: [
                            {
                                name: 'Score',
                                key: 'score',
                                style: { alignment: alignment.middleCenter },
                            },
                        ],
                        headerDefinition: [
                            {
                                name: 'Id',
                                key: 'id',
                                width: 40,
                            },
                            {
                                name: 'Name',
                                key: 'name',
                                width: 20,
                                checkable: true,
                                style: { alignment: alignment.middleCenter },
                            },
                            {
                                name: 'Admin_key',
                                key: 'adminKey',
                                width: 15,
                                style: { alignment: alignment.middleCenter },
                            },
                            {
                                name: 'Key',
                                key: 'num',
                                width: 15,
                            },
                            {
                                name: 'Temporary',
                                style: { alignment: alignment.middleCenter },
                                key: 'isTemporary',
                                width: 15,
                            },
                            {
                                name: 'Nicknames',
                                key: 'isNicknames',
                                width: 15,
                            },
                            {
                                name: 'Moderated',
                                key: 'isModerated',
                                width: 15,
                            },
                            {
                                name: 'Created_by',
                                key: 'createdBy',
                                width: 40,
                            },
                            {
                                name: 'Created_at',
                                key: 'createdAt',
                                width: 25,
                            },
                            {
                                name: 'Users',
                                key: 'users',
                                width: 10,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Posts',
                                key: 'posts',
                                width: 10,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Polls',
                                key: 'polls',
                                width: 10,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                        ],
                    },
                },
            },

            {
                sheetName: 'Posts & Comments',

                startingRowNumber: 2,
                gapBetweenTwoTables: 2,
                tableSettings: {
                    postData: {
                        importable: true,
                        tableTitle: 'Posts & Comments',

                        headerGroups: [
                            {
                                name: 'Score',
                                key: 'score',
                                style: { alignment: alignment.middleCenter },
                            },
                        ],
                        headerDefinition: [
                            {
                                name: 'Id',
                                key: 'id',
                                width: 40,
                                hierarchy: true,
                                checkable: true,
                            },
                            {
                                name: 'Parent_id',
                                key: 'parentId',
                                width: 40,
                                hierarchy: true,
                                checkable: true,
                            },
                            {
                                name: 'Content',
                                key: 'content',
                                width: 40,
                                checkable: true,
                                style: { alignment: alignment.middleCenter },
                            },

                            {
                                name: 'Published',
                                style: { alignment: alignment.middleCenter },
                                key: 'isPublished',
                                width: 15,
                            },
                            {
                                name: 'Favorite',
                                key: 'isFavorite',
                                width: 15,
                            },

                            {
                                name: 'Created_by',
                                key: 'createdBy',
                                width: 30,
                            },
                            {
                                name: 'Created_at',
                                key: 'createdAt',
                                width: 25,
                            },
                            {
                                name: 'NickName',
                                key: 'nickName',
                                width: 25,
                            },
                            {
                                name: 'Comments',
                                key: 'comments',
                                width: 10,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Likes',
                                key: 'likes',
                                width: 10,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Dislikes',
                                key: 'dislikes',
                                width: 10,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                        ],
                    },
                },
            },

            {
                sheetName: 'Poll Summary',

                startingRowNumber: 2,
                gapBetweenTwoTables: 2,
                tableSettings: {
                    pollData: {
                        importable: true,
                        tableTitle: 'Poll Summary',

                        headerGroups: [
                            {
                                name: 'Score',
                                key: 'score',
                                style: { alignment: alignment.middleCenter },
                            },
                        ],
                        headerDefinition: [
                            {
                                name: 'Id',
                                key: 'id',
                                width: 40,
                            },
                            {
                                name: 'Content',
                                key: 'content',
                                width: 40,
                                checkable: true,
                                style: { alignment: alignment.middleCenter },
                            },

                            {
                                name: 'Multiple_selection',
                                style: { alignment: alignment.middleCenter },
                                key: 'isMultipleResponses',
                                width: 15,
                            },

                            {
                                name: 'Immediate_results',
                                key: 'isImmediateResults',
                                width: 15,
                            },

                            {
                                name: 'Opened',
                                key: 'isOpened',
                                width: 15,
                            },

                            {
                                name: 'Results',
                                key: 'isResults',
                                width: 15,
                            },

                            {
                                name: 'Created_by',
                                key: 'createdBy',
                                width: 40,
                            },
                            {
                                name: 'Created_at',
                                key: 'createdAt',
                                width: 25,
                            },
                            {
                                name: 'NickName',
                                key: 'nickName',
                                width: 25,
                            },
                            {
                                name: 'Options',
                                key: 'optionsLength',
                                width: 25,
                            },
                            {
                                name: 'Participants',
                                key: 'participants',
                                width: 25,
                            },

                            {
                                name: 'Solutions',
                                key: 'pollSolutions',
                                width: 25,
                            },

                            {
                                name: 'Option - Progress - Participants',
                                key: 'pollResults',
                                style: {
                                    alignment: alignment.middleCenter,
                                    innerHeight: 'auto',
                                },
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },

                            {
                                name: 'Option (A)',
                                key: 'optionA',
                                width: 40,
                            },
                            {
                                name: 'Option (B)',
                                key: 'optionB',
                                width: 40,
                            },
                            {
                                name: 'Option (C)',
                                key: 'optionC',
                                width: 40,
                            },
                            {
                                name: 'Option (D)',
                                key: 'optionD',
                                width: 40,
                            },
                            {
                                name: 'Option (E)',
                                key: 'optionE',
                                width: 40,
                            },
                            {
                                name: 'Option (F)',
                                key: 'optionF',
                                width: 40,
                            },
                            {
                                name: 'Option (G)',
                                key: 'optionG',
                                width: 40,
                            },
                            {
                                name: 'Option (H)',
                                key: 'optionH',
                                width: 40,
                            },
                            {
                                name: 'Option (I)',
                                key: 'optionI',
                                width: 40,
                            },
                            {
                                name: 'Option (J)',
                                key: 'optionJ',
                                width: 40,
                            },
                            {
                                name: 'Option (K)',
                                key: 'optionK',
                                width: 40,
                            },
                            {
                                name: 'Option (L)',
                                key: 'optionL',
                                width: 40,
                            },

                            {
                                name: 'Option (M)',
                                key: 'optionM',
                                width: 40,
                            },
                            {
                                name: 'Option (N)',
                                key: 'optionN',
                                width: 40,
                            },
                            {
                                name: 'Option (O)',
                                key: 'optionO',
                                width: 40,
                            },
                        ],
                    },
                },
            },

            {
                sheetName: 'Attendee Actions',
                startingRowNumber: 2,
                gapBetweenTwoTables: 2,
                tableSettings: {
                    userActionsData: {
                        importable: true,
                        tableTitle: 'Attendee Actions',

                        headerGroups: [
                            {
                                name: 'Score',
                                key: 'score',
                                style: { alignment: alignment.middleCenter },
                            },
                        ],
                        headerDefinition: [
                            {
                                name: 'User_id',
                                key: 'userId',
                                width: 40,
                                hierarchy: true,
                                checkable: true,
                            },
                            {
                                name: 'Role',
                                key: 'role',
                                width: 20,
                                hierarchy: true,
                                checkable: true,
                            },

                            {
                                name: 'NickName',
                                key: 'nickName',
                                width: 25,
                            },

                            {
                                name: 'Nbr. Created_posts',
                                key: 'nbCreatedPosts',
                                width: 20,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Created_posts',
                                key: 'createdPosts',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Nbr. Liked_posts',
                                key: 'nbLikedPosts',
                                width: 20,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Liked_posts',
                                key: 'likedPosts',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Timestamp',
                                key: 'likedPostsTimestamp',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Nbr. Disliked_posts',
                                key: 'nbDislikedPosts',
                                width: 20,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Disliked_posts',
                                key: 'dislikedPosts',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },

                            {
                                name: 'Timestamp',
                                key: 'dislikedPostsTimestamp',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Nbr. Created_comments',
                                key: 'nbCreatedComments',
                                width: 25,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Created_comments',
                                key: 'createdComments',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Nbr. Liked_comments',
                                key: 'nbLikedComments',
                                width: 25,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Liked_comments',
                                key: 'likedComments',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },

                            {
                                name: 'Timestamp',
                                key: 'likedCommentsTimestamp',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Nbr. Disliked_comments',
                                key: 'nbDislikedComments',
                                width: 25,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                            {
                                name: 'Disliked_comments',
                                key: 'dislikedComments',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },

                            {
                                name: 'Timestamp',
                                key: 'dislikedCommentsTimestamp',
                                width: 40,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },

                            {
                                name: 'Created/Answered Polls',
                                key: 'createdAnsweredPolls',
                                width: 30,
                                groupKey: 'score',
                                dataType: defaultDataType.string,
                            },
                        ],
                    },
                },
            },
        ],
    }
}
